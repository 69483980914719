import { History, Location } from 'history';
import qs from 'query-string';

import GFConstants from 'constants/GlobalFilters';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';

const defaultGlobalFilters = GFConstants.DefaultGlobalFilters;

/**
 * Parse and get currently applied global filters
 *
 * @param search Search query from URL
 * @returns {GlobalFilters} Global filters object
 */
const getGlobalFilters = (search: string): GlobalFilters => {
  const { gf = '{}' } = qs.parse(search);
  const parsedGf = JSON.parse(gf as string);

  const newFilters = { ...defaultGlobalFilters, ...parsedGf };
  return newFilters;
};

/**
 * Get URL with encoded global filters in query
 *
 * @param data Applied global filters
 * @param location Location object
 * @returns {string} Updated URL
 */
const getGlobalFiltersUrl = (
  data: GlobalFilters,
  location: Location
): string => {
  const { gf = '{}', ...others } = qs.parse(location.search);
  const parsedGf = JSON.parse(gf as string);

  const stringGf = JSON.stringify({
    ...defaultGlobalFilters,
    ...parsedGf,
    ...data,
  });

  const newQuery = { gf: stringGf, ...others };

  const url = qs.stringifyUrl(
    { url: location.pathname, query: newQuery },
    { encode: true, arrayFormat: 'comma' }
  );
  return url;
};

/**
 * Get search query with encoded global filters
 *
 * @param data Applied global filters
 * @param location Location object
 * @returns {string} Updated search query
 */
const getGlobalFiltersSearch = (
  data: GlobalFilters,
  location: Location
): string => {
  const { existingGf = '{}' } = qs.parse(location.search);
  const parsedGf = JSON.parse(existingGf as string);

  const gf = JSON.stringify({
    ...defaultGlobalFilters,
    ...parsedGf,
    ...data,
  });
  const newQuery = { gf };

  const search = qs.stringify(newQuery, { encode: true, arrayFormat: 'comma' });

  return search;
};

/**
 * Sets updated URL and navigates to it
 *
 * @param data Applied global filters
 * @param location Location object
 * @param history History object
 */
const setGlobalFiltersUrl = (
  data: GlobalFilters,
  location: Location,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  history: History<unknown>
): void => {
  history.push(getGlobalFiltersUrl(data, location));
};

/**
 * Extracts search query from current URL
 *
 * @param searchQuery Search query in current URL
 * @returns {string} Encoded search query
 */
const getGlobalFiltersQuery = (searchQuery: string): string => {
  const { gf = '{}' } = qs.parse(searchQuery);

  return qs.stringify({ gf });
};

export {
  defaultGlobalFilters,
  getGlobalFilters,
  getGlobalFiltersUrl,
  getGlobalFiltersSearch,
  setGlobalFiltersUrl,
  getGlobalFiltersQuery,
};
