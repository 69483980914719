import { useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';

import styles from './partnerModal.module.scss';
import PartnerModalProps from './PartnerModalProps';

const PartnerModal: React.FC<PartnerModalProps> = (props) => {
  const {
    monitoredPartners,
    monitoredOrgId,
    isOpen,
    onToggle,
    onPartnerSwitch,
  } = props;

  const MIN_PARTNER_SCROLL_COUNT = 3;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  //     status: Status.Success,
  //     data: [],
  //   };

  /**
   * Handle partner switch event
   *
   * @param orgId ID of the selected organization
   */
  const handlePartnerSelect = (orgId: string): void => {
    if (orgId === monitoredOrgId) {
      onToggle();
    } else {
      onToggle();
      onPartnerSwitch(orgId);
    }
  };

  return (
    <Modal
      size="md"
      isOpen={isOpen}
      className="no-rounded-corners no-border"
      centered
      returnFocusAfterClose={false}
      backdrop="static"
      toggle={onToggle}
    >
      <ModalHeader
        className="d-flex align-items-center increase-font"
        toggle={onToggle}
      >
        Switch Partners
      </ModalHeader>
      <ModalBody>
        <div className={styles.wrap}>
          {isEmpty(monitoredPartners.data) ? (
            <Row
              className={`${styles.item} ${styles.noHover} text-16-semibold text-uppercase`}
            >
              <Col xs="12" className="d-flex align-items-center">
                {intl.get('LBL_SWITCH_PARTNERS_EMPTY')}
              </Col>
            </Row>
          ) : (
            <>
              <div className={styles.scroll}>
                {monitoredPartners.data.map((partner) => (
                  <Row
                    key={partner.id}
                    onClick={() => handlePartnerSelect(partner.id)}
                    className={`${styles.item} ${
                      monitoredOrgId === partner.id ? styles.activePartner : ''
                    } text-16-semibold text-uppercase`}
                  >
                    <Col xs="10" className="d-flex align-items-center">
                      <i className="icon-partner pr-4" />
                      <EllipsisTooltip
                        tag="span"
                        data-place="top"
                        data-for="insTooltip"
                        data-tip={partner.name}
                        data-class="overflow-wrap bring-it-up"
                        className="truncate"
                      >
                        {partner.name}
                      </EllipsisTooltip>
                    </Col>
                    {monitoredOrgId === partner.id && (
                      <Col
                        xs="2"
                        className={`d-flex align-items-center justify-content-end ${styles.activeIcon}`}
                      >
                        <i className="icon-eye-on text-12-semibold" />
                      </Col>
                    )}
                  </Row>
                ))}
              </div>
              {monitoredPartners.data.length > MIN_PARTNER_SCROLL_COUNT ? (
                <span className={styles.track} />
              ) : (
                ''
              )}
            </>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PartnerModal;
