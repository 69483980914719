/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from 'react';

import axios from 'axios';
import { Location } from 'history';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import * as intl from 'react-intl-universal';
import { Col, Row, TabContent, TabPane } from 'reactstrap';

import ApiError from 'api/common/types/ApiError';
import SettingsApiInstance from 'api/settings/SettingsApi';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import StorageKeys from 'constants/StorageKeys';
import Tables from 'constants/Tables';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import PermissionUtil from 'helpers/PermissionUtil';
import setPageTitle from 'helpers/setPageTitle';
import InvitedUsersDataTable from 'modules/private/settings/components/invited-users-data-table/InvitedUsersDataTable';
import UsersDataTable from 'modules/private/settings/components/users-data-table/UsersDataTable';
import AuthStorageService from 'services/storage-services/AuthStorageService';
import InsLink from 'shared/components/ins-link/InsLink';
import InsNavLink from 'shared/components/ins-nav-link/InsNavLink';
import ScrollToTopOnMount from 'shared/components/scroll-to-top-on-mount/ScrollToTopOnMount';
import EventKey from 'shared/enums/EventKey';
import HTTP_STATUS from 'shared/enums/HttpStatus';
import Status from 'shared/enums/Status';
import { EventBus } from 'shared/events/EventBus';
import { CustomErrorArgs } from 'shared/types/eventTypes';

import TableConfig from './TableConfig';
import styles from './usersView.module.scss';
import UsersViewProps, { ModalType as UsersModalType } from './UsersViewProps';
import UsersViewState, { UserTabFilters } from './UsersViewState';
import {
  SliceName,
  TableQuery,
  UsersSearchValues,
} from './UsersViewTableFilters';

class UsersView extends Component<UsersViewProps, UsersViewState> {
  constructor(props: UsersViewProps) {
    super(props);
    const orgId = AuthStorageService.GetItem<string>(
      StorageKeys.OrganizationId
    );
    this.state = {
      inviteUsers: {
        isOpen: false,
        resendStatus: Status.Idle,
        createJobRoleStatus: Status.Idle,
      },
      users: {
        organizationId: orgId,
        data: [],
        status: Status.Loading,
        pageCount: 1,
        pagination: { total: 0, page: 1 },
        showDeactivated: false,
        hasDeactivatedUsers: false,
      },
    };
  }

  componentDidMount(): void {
    const { modal, location, appContext } = this.props;
    appContext.hideErrorToast();
    setPageTitle(intl.get('BTN_SETTINGS'));

    const { page, size, sortBy, filter } =
      this.getTablePreferencesFromSearch(location);

    // prettier-ignore
    this.setStateToSlice({ isOpen: modal === UsersModalType.InviteUsers },'inviteUsers');

    this.loadUsersListData({ size, page, sortBy, filter });
  }

  componentDidUpdate(prevProps, prevState): void {
    const { modal, location } = this.props;
    const { users } = this.state;

    if (
      location.search !== prevProps.location.search ||
      prevState.users.showDeactivated !== users.showDeactivated
    ) {
      const prevSearch = this.getTablePreferencesFromSearch(prevProps.location);
      const currentSearch = this.getTablePreferencesFromSearch(location);
      const { page, size, sortBy, filter } = currentSearch;
      if (
        prevSearch.size !== size ||
        prevSearch.page !== page ||
        (prevSearch.filter !== filter && page === 0) ||
        !isEqual(prevSearch.sortBy, sortBy)
      ) {
        this.loadUsersListData({ page, size, sortBy, filter });
      }
      /* When the deactivated user checkbox is toggled, 
        the current page needs to be reset if it's greater than 0 */
      if (prevState.users.showDeactivated !== users.showDeactivated) {
        if (page > 0) {
          this.updatePage(0);
        } else {
          this.loadUsersListData({ page, size, sortBy, filter });
        }
      }
    }

    if (prevProps.modal !== modal) {
      // prettier-ignore
      this.setStateToSlice({ isOpen: modal === UsersModalType.InviteUsers }, 'inviteUsers');
    }
  }

  componentWillUnmount(): void {
    this.source.cancel();
  }

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  /**
   * Extract and return table preferences from location.search string
   *
   * @param location History.Location from react-router props
   * @returns {UsersSearchValues} Formatted table preferences
   */
  getTablePreferencesFromSearch = (location: Location): UsersSearchValues => {
    const tabs = [UserTabFilters.INVITED, UserTabFilters.USERS];

    let { filter, size, page, by, desc } = queryString.parse(location.search);
    filter = filter && Array.isArray(filter) ? null : filter;
    size = size && Array.isArray(size) ? null : size;
    page = page && Array.isArray(page) ? null : page;
    by = by && Array.isArray(by) ? null : by;
    desc = desc && Array.isArray(desc) ? null : desc;

    const newFilter = (
      tabs.includes(filter as UserTabFilters) ? filter : UserTabFilters.USERS
    ) as UserTabFilters;

    const sizeNumber = size ? parseInt(size, 10) : -1;

    const newSize = Tables.PageSizes.includes(sizeNumber)
      ? sizeNumber
      : Tables.PageSizes[0];

    const descending = desc ? desc !== 'false' : false;

    const sortColumn =
      by && TableConfig[newFilter].sortCols.has(by)
        ? by
        : TableConfig[newFilter].defaultSort;

    const sortBy = [{ id: sortColumn, desc: descending }];

    const pageNumber = page ? parseInt(page, 10) : -1;

    const newPage =
      pageNumber !== undefined &&
      pageNumber !== null &&
      !Number.isNaN(pageNumber) &&
      pageNumber >= 0
        ? pageNumber
        : 0;

    return { filter: newFilter, size: newSize, sortBy, page: newPage };
  };

  /**
   * Fetch user list data and set to component state
   *
   * @param tableQuery table preferences { pageSize, pageIndex, sortBy}
   * @param resetPageIndex if true will reset the page index to 0
   */
  loadUsersListData = async (
    tableQuery: UsersSearchValues, // TableQuery,
    resetPageIndex?: boolean
  ): Promise<void> => {
    const { appContext } = this.props;
    const { size, page, sortBy, filter } = tableQuery;
    const Direction = Tables.SortDirection;

    this.setStateToSlice({ status: Status.Loading }, 'users');

    try {
      const { users } = this.state;
      const sortByAttribute = sortBy[0].id;
      // prettier-ignore
      const sortDirection = sortBy[0].desc ? Direction.Descending : Direction.Ascending;
      const pageIndexAltered = resetPageIndex ? 0 : page;

      const result = await SettingsApiInstance.GetOrganizationUsers(
        users.organizationId,
        filter,
        users.showDeactivated,
        {
          pageSize: size,
          page: pageIndexAltered,
          sortBy: sortByAttribute,
          sort: sortDirection,
        },
        this.source
      );

      const pageCount = Math.ceil(result.pagination.total / size);

      this.setStateToSlice(
        {
          data: result.items,
          pagination: result.pagination,
          pageCount,
          status: Status.Success,
          hasDeactivatedUsers: result?.meta?.deactivatedCount > 0,
        },
        'users'
      );
    } catch (error) {
      this.setStateToSlice(
        {
          data: [],
          pagination: { total: 0, page: 1 },
          pageCount: 0,
          status: Status.Error,
        },
        'users'
      );
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  };

  /**
   * Set state immutably to 1 level deep state attributes
   *
   * @param slice slice object or a function that returns the slice object;
   *  if a function is passed it will receive existing state as a parameter
   * @param sliceName optional name of the first level slice that needs to be set;
   * if left out slice will be set to the top level of the state
   */
  setStateToSlice = (
    slice: any | ((state: UsersViewState) => any),
    sliceName?: SliceName
  ): void => {
    this.setState((state: UsersViewState) => {
      const currentSlice = typeof slice === 'function' ? slice(state) : slice;
      return {
        ...(sliceName ? { ...state } : { ...state, ...currentSlice }),
        ...(sliceName
          ? { [sliceName]: { ...state[sliceName], ...currentSlice } }
          : { ...state }),
      };
    });
  };

  /**
   * Get full url with search parameters for given tab
   *
   * @param tab Tab name
   * @returns {string} URL
   */
  getTabNavigateUrl = (tab: string): string => {
    const { location } = this.props;
    const query = { ...queryString.parse(location.search), filter: tab };
    const url = queryString.stringifyUrl({
      url: `${ModulePaths.SettingsPath}${ModulePaths.SettingsUsersPath}`,
      query,
    });
    return url;
  };

  /**
   * Push sortBy update to url; additionally sets page to 0
   *
   * @param sortBy sortBy object of shape { id: string, desc: boolean }
   */
  updateSortBy = (sortBy: TableQuery['sortBy']): void => {
    const { history, location } = this.props;
    const searchParsed = queryString.parse(location.search);
    const { id: by, desc } = sortBy[0];
    if (by !== searchParsed.by || desc.toString() !== searchParsed.desc) {
      // prettier-ignore
      const query = {...searchParsed, by , desc, page: 0 };
      const url = queryString.stringifyUrl({
        url: `${ModulePaths.SettingsPath}${ModulePaths.SettingsUsersPath}`,
        query,
      });
      history.push(url);
    }
  };

  /**
   * Push size update to url
   *
   * @param size current page size
   */
  updatePageSize = (size: number): void => {
    const { history, location } = this.props;
    const searchParsed = queryString.parse(location.search);

    if (size.toString() !== searchParsed.size) {
      const query = { ...searchParsed, size, page: 0 };
      const url = queryString.stringifyUrl({
        url: `${ModulePaths.SettingsPath}${ModulePaths.SettingsUsersPath}`,
        query,
      });
      history.push(url);
    }
  };

  /**
   * Push page update to url
   *
   * @param page current page
   */
  updatePage = (page: number): void => {
    const { history, location } = this.props;
    const searchParsed = queryString.parse(location.search);

    if (page.toString() !== searchParsed.page) {
      const query = { ...searchParsed, page };
      const url = queryString.stringifyUrl({
        url: `${ModulePaths.SettingsPath}${ModulePaths.SettingsUsersPath}`,
        query,
      });
      history.push(url);
    }
  };

  /**
   * Toggle modal isOpen and update url
   */
  handleToggle = (): void => {
    const { inviteUsers } = this.state;
    const { history, location } = this.props;
    const isOpen = !inviteUsers.isOpen;
    if (isOpen) {
      this.setStateToSlice({ isOpen: !inviteUsers.isOpen }, 'inviteUsers');
    } else {
      history.replace({
        pathname: `${ModulePaths.SettingsPath}${ModulePaths.SettingsUsersPath}`,
        search: getGlobalFiltersQuery(location.search),
      });
    }
  };

  /**
   * Handles row click event
   *
   * @param row Row that was clicked on
   */
  handleRowClick = (row): void => {
    const { history, location } = this.props;
    history.push({
      pathname: `${ModulePaths.SettingsPath}${
        ModulePaths.SettingsUsersPath
      }/${String(row.id)}`,
      state: {
        from: location.pathname + location.search,
        userId: row.id,
      },
      search: getGlobalFiltersQuery(location.search),
    });
  };

  /**
   * Toggle show deactivated users state boolean
   */
  handleToggleDeactivated = (): void => {
    this.setStateToSlice(
      (state) => ({
        showDeactivated: !state.users.showDeactivated,
      }),
      'users'
    );
  };

  /**
   * Handle resending the user invite
   *
   * @param email The invitee's email
   */
  handleResendInviteFor = (email: string) => async (): Promise<void> => {
    const { appContext } = this.props;
    this.setStateToSlice({ resendStatus: Status.Loading }, 'inviteUsers');
    try {
      await SettingsApiInstance.ResendInvite(email, this.source);
      this.setStateToSlice(
        {
          resendStatus: Status.Success,
        },
        'inviteUsers'
      );
      appContext.setSuccessToastText(
        intl.get('LBL_SETTINGS_USERS_RESENT_INVITE_SUCCESS')
      );

      sendEventGA(
        CategoryKeysGA.SettingsUsersInvitedUsers,
        ActionKeysGA.ResendEmail
      );
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_RESEND_INVITE_ERRORS',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_RESEND_INVITE_ERRORS')
        );
      }

      this.setStateToSlice(
        {
          resendStatus: Status.Error,
        },
        'inviteUsers'
      );
    }
  };

  render(): JSX.Element {
    const { inviteUsers, users } = this.state;
    const { location, appContext } = this.props;
    const { page, size, sortBy, filter } =
      this.getTablePreferencesFromSearch(location);
    const canInviteUsers = PermissionUtil.Can(
      appContext.permissionsData.claims,
      ResourceKeys.SettingsUsersInviteUsers
    );
    const { isNationalLevelUser } = appContext;

    return (
      <div className="content-container">
        <Row className="justify-content-between">
          <Col xs="auto" className="pl-0">
            <div className="insight-tab">
              <ul>
                <li>
                  <InsNavLink
                    className={`btn btn-secondary bg-transparent text-18-semibold ${styles.tab}`}
                    to={this.getTabNavigateUrl(UserTabFilters.USERS)}
                    isActive={(): boolean => filter === UserTabFilters.USERS}
                    replace
                  >
                    {intl.get('LBL_SETTINGS_TAB_USERS')}
                  </InsNavLink>
                </li>
                {!isNationalLevelUser && (
                  <li>
                    <InsNavLink
                      className={`btn btn-secondary text-18-semibold bg-transparent ${styles.tab}`}
                      to={this.getTabNavigateUrl(UserTabFilters.INVITED)}
                      isActive={(): boolean =>
                        filter === UserTabFilters.INVITED
                      }
                      replace
                    >
                      {intl.get('LBL_SETTINGS_TAB_INVITED_USERS')}
                    </InsNavLink>
                  </li>
                )}
              </ul>
            </div>
          </Col>
          <Col xs="auto">
            {canInviteUsers && !isNationalLevelUser && (
              <InsLink
                className="btn btn-sm btn-secondary"
                to={`${ModulePaths.SettingsPath}${ModulePaths.SettingsUsersPath}${ModulePaths.SettingsUsersInvitePath}`}
              >
                {intl.get('BTN_SETTINGS_USERS_INVITE_USERS')}
              </InsLink>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <hr className="divider" />
          </Col>
        </Row>
        <TabContent activeTab={filter}>
          <TabPane tabId={UserTabFilters.USERS}>
            {filter === UserTabFilters.USERS && (
              <>
                <ScrollToTopOnMount />
                <UsersDataTable
                  data={users.data}
                  updateSortBy={this.updateSortBy}
                  updatePageSize={this.updatePageSize}
                  updatePage={this.updatePage}
                  pagination={users.pagination}
                  controlledPageCount={users.pageCount}
                  initialPageSize={size}
                  initialPageIndex={page}
                  initialSortBy={sortBy}
                  onRowClick={this.handleRowClick}
                  status={users.status}
                  showDeactivated={users.showDeactivated}
                  onToggleShowDeactivated={this.handleToggleDeactivated}
                  showDeactivatedCheckbox={users.hasDeactivatedUsers}
                />
              </>
            )}
          </TabPane>
          {!isNationalLevelUser && (
            <TabPane tabId={UserTabFilters.INVITED}>
              {filter === UserTabFilters.INVITED && (
                <>
                  <ScrollToTopOnMount />
                  <InvitedUsersDataTable
                    data={users.data}
                    updateSortBy={this.updateSortBy}
                    updatePageSize={this.updatePageSize}
                    updatePage={this.updatePage}
                    pagination={users.pagination}
                    controlledPageCount={users.pageCount}
                    initialPageSize={size}
                    initialPageIndex={page}
                    initialSortBy={sortBy}
                    onRowClick={this.handleRowClick}
                    status={users.status}
                    onResendInviteFor={this.handleResendInviteFor}
                    resendStatus={inviteUsers.resendStatus}
                    canInviteUsers={canInviteUsers}
                  />
                </>
              )}
            </TabPane>
          )}
        </TabContent>
      </div>
    );
  }
}

export default UsersView;
