const StorageKeys = {
  TokenKey: 'access_token',
  RefreshKey: 'refresh_token',
  LastNameKey: 'last_name',
  UserNameKey: 'user_name',
  FirstNameKey: 'first_name',
  Permissions: 'permissions',
  OrganizationId: 'organization_id',
  Features: 'features',
  DefaultCurrency: 'default_currency',
  SelectedCurrency: 'selected_currency',
  UnauthorizedErrorCode: 'unauthorized_error_code',
  UserId: 'user_id',
  MonitoredOrganizatonId: 'monitored_organization_id',
};

export default StorageKeys;
