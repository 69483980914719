import {
  ReactNode,
  useEffect,
  useState,
  FC as ReactFC,
  useContext,
} from 'react';

import * as intl from 'react-intl-universal';

import AppContext from 'context/AppContext';
import { formatDate } from 'helpers/DateFormat';
import { getFormattedNumber } from 'helpers/NumberFormat';
import BackupStatusTag from 'modules/private/group/components/backup-status-tag/BackupStatusTag';
import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';
import BackupStatus from 'shared/enums/BackupStatus';
import DateFormatType from 'shared/enums/DateFormatType';
import GroupSavingsMethod from 'shared/enums/GroupSavingsMethod';
import IntlNumberNotation from 'shared/enums/IntlNumberNotation';
import Status from 'shared/enums/Status';
import useCurrency from 'shared/hooks/use-currency/UseCurrency';

import AssignFacilitatorMenu from '../assign-facilitator-menu/AssignFacilitatorMenu';
import AssignGroupStatusMenu from '../assign-group-status-menu/AssignGroupStatusMenu';
import AssignProjectMenu from '../assign-project-menu/AssignProjectMenu';
import GroupDetailsLeftPanelProps from './GroupDetailsLeftPanelProps';

const GroupDetailsLeftPanel: ReactFC<GroupDetailsLeftPanelProps> = (
  props: GroupDetailsLeftPanelProps
) => {
  const {
    data,
    groupDetailsSetupInProgress,
    projectListData,
    getProjectList,
    assignToProjectStatus,
    assignToProjectError,
    assignFacilitatorsStatus,
    assignFacilitatorsError,
    assignGroupsStatusStatus,
    assignGroupsStatusError,
    facilitatorsStatus,
    facilitators,
    facilitatorsError,
    groupStatusData,
    getGroupStatuses,
    fetchFacilitators,
    setAssignGroupsStatusStatus,
    setAssignToProjectStatus,
    setAssignFacilitatorsStatus,
    postAssignGroupsToProject,
    postAssignGroupsFacilitators,
    postAssignGroupsStatus,
  } = props;

  const getCurrencyString = useCurrency();
  const appContext = useContext(AppContext);
  const [projectsOpen, setProjectsOpen] = useState(false);
  const [facilitatorsOpen, setFacilitatorsOpen] = useState<boolean>(false);
  const [groupStatusOpen, setGroupStatusOpen] = useState<boolean>(false);

  useEffect(() => {
    if (assignToProjectStatus === Status.Success) {
      setProjectsOpen(false);
    }
  }, [assignToProjectStatus]);

  useEffect(() => {
    if (assignFacilitatorsStatus === Status.Success) {
      setFacilitatorsOpen(false);
    }
  }, [assignFacilitatorsStatus]);

  useEffect(() => {
    if (assignGroupsStatusStatus === Status.Success) {
      setGroupStatusOpen(false);
    }
  }, [assignGroupsStatusStatus]);

  /**
   * Format dates
   *
   * @param date Date string to be formatted
   * @returns {string} Formatted date
   */
  const renderFormattedDate = (date: string): string =>
    date ? formatDate(date, DateFormatType.ShortYear) : intl.get('LBL_NA');

  /**
   * Returns cycle dates
   *
   * @returns {string} Cycle dates
   */
  const renderCycleDates = (): string =>
    data.cycleStartDate && data.cycleShareoutDate
      ? `${renderFormattedDate(data.cycleStartDate)} - ${renderFormattedDate(
          data.cycleShareoutDate
        )}`
      : intl.get('LBL_NA');

  /**
   * Returns country
   *
   * @returns {string} Country
   */
  const renderCountry = (): string =>
    data.country ? data.country : intl.get('LBL_NA');

  /**
   * Returns minimum shares/savings
   * @returns {string} Minimum shares/savings
   */
  const renderMinimumSharesOrSavings = (): string => {
    if (data.minimumSharesPerMeeting) {
      return `${getFormattedNumber(data.minimumSharesPerMeeting, false)}`;
    }
    if (data.minimumSavingsPerMeeting) {
      return `${getCurrencyString(
        Number(data.minimumSavingsPerMeeting),
        false,
        IntlNumberNotation.Standard
      )}`;
    }
    return intl.get('LBL_NA');
  };

  /**
   * Return group savings label
   *
   * @returns {string} Group savings label
   */
  const renderGroupSavingsLabel = (): string => {
    if (data.savingsMethod === GroupSavingsMethod.Shares) {
      return intl.get('LBL_GD_MIN_SHARES');
    }
    return intl.get('LBL_GD_MIN_SAVINGS');
  };

  /**
   * Returns meeting frequency
   *
   * @returns {string} Meeting frequency
   */
  const renderMeetingFreq = (): string =>
    data.meetingFrequency ? intl.get(`LBL_${data.meetingFrequency}`) : '—';

  /**
   * Render the project input field
   *
   * @returns {JSX.Element} JSX snippet containing the field
   */
  const renderProjectField = (): JSX.Element => (
    <>
      <label>{intl.get('LBL_GD_PROJECT')}</label>
      <EllipsisTooltip
        tag="input"
        data-place="bottom"
        data-for="insTooltip"
        data-class="overflow-wrap"
        data-tip={data.project?.name ?? ''}
        type="text"
        className="text-input"
        value={data.project ? data.project.name : intl.get('LBL_NA')}
        readOnly
      />
    </>
  );

  /**
   * Render the facilitator input field
   *
   * @returns {JSX.Element} JSX snippet containing the field
   */
  const renderFacilitatorField = (): JSX.Element => (
    <>
      <label>{intl.get('LBL_GD_FACILITATOR')}</label>
      <EllipsisTooltip
        tag="input"
        data-place="bottom"
        data-for="insTooltip"
        data-class="overflow-wrap"
        data-tip={data.facilitator?.name ?? ''}
        type="text"
        className="text-input"
        value={data.facilitator ? data.facilitator.name : intl.get('LBL_NA')}
        readOnly
      />
    </>
  );

  /**
   * Render the group status input field
   *
   * @returns {JSX.Element} JSX snippet containing the field
   */
  const renderGroupStatusField = (): JSX.Element => (
    <>
      <label>{intl.get('LBL_GD_GROUP_STATUS')}</label>
      <EllipsisTooltip
        tag="input"
        data-place="bottom"
        data-for="insTooltip"
        data-class="overflow-wrap"
        data-tip={data.groupStatus ? data.groupStatus.status : ''}
        type="text"
        className="text-input"
        value={data.groupStatus ? data.groupStatus.status : intl.get('LBL_NA')}
        readOnly
      />
    </>
  );

  const isAssignMenuDisabled =
    !groupDetailsSetupInProgress || appContext.isNationalLevelUser;

  return (
    <div className="group-item-info">
      <div className="item-info-inner">
        <EllipsisTooltip
          tag="h3"
          data-place="bottom"
          data-for="insTooltip"
          data-tip={data.name}
          data-class="overflow-wrap"
          className="item-title truncate"
        >
          {data.name}
        </EllipsisTooltip>
        <h4 className="item-sub-title">
          <BackupStatusTag type={data.backupStatus as BackupStatus} />
        </h4>
        <div className="input-group">
          <label>{intl.get('LBL_GD_COUNTRY')}</label>
          <EllipsisTooltip
            tag="input"
            data-place="bottom"
            data-for="insTooltip"
            data-class="overflow-wrap"
            data-tip={data.country}
            type="text"
            className="text-input"
            value={renderCountry()}
            readOnly
          />
        </div>
        <div className="input-group">
          <label>{intl.get('LBL_GD_GROUP_ID')}</label>
          <EllipsisTooltip
            tag="input"
            data-place="bottom"
            data-for="insTooltip"
            data-class="overflow-wrap"
            data-tip={data.number}
            type="text"
            className="text-input"
            value={data.number}
            readOnly
          />
        </div>
        {isAssignMenuDisabled ? (
          <div className="input-group">{renderProjectField()}</div>
        ) : (
          <AssignProjectMenu
            isProjectsOpen={projectsOpen}
            hideBackdrop
            assignToProjectStatus={assignToProjectStatus}
            projectListData={projectListData}
            assignToProjectError={assignToProjectError}
            onCloseProjects={(): void => setProjectsOpen(false)}
            onAssignToProject={postAssignGroupsToProject}
            getProjectList={getProjectList}
            setAssignToProjectStatus={setAssignToProjectStatus}
            currentProject={data.project?.id}
            placement={{
              anchor: 'BOTTOM_CENTER',
              possibleAnchors: ['BOTTOM_CENTER'],
              triggerOffset: 10,
            }}
          >
            {({ triggerRef }): ReactNode => (
              <button
                disabled={isAssignMenuDisabled}
                onClick={(): void => setProjectsOpen(true)}
                ref={triggerRef}
                className={`input-group ${'edit-mode'} ${
                  projectsOpen ? 'edit-active' : ''
                }`}
              >
                {renderProjectField()}
              </button>
            )}
          </AssignProjectMenu>
        )}
        {isAssignMenuDisabled ? (
          <div className="input-group">{renderFacilitatorField()}</div>
        ) : (
          <AssignFacilitatorMenu
            hideBackdrop
            isFacilitatorsOpen={facilitatorsOpen}
            facilitators={facilitators}
            facilitatorsStatus={facilitatorsStatus}
            facilitatorsError={facilitatorsError}
            assignFacilitatorsStatus={assignFacilitatorsStatus}
            assignFacilitatorsError={assignFacilitatorsError}
            currentFacilitator={data.facilitator?.id}
            fetchFacilitators={fetchFacilitators}
            onCloseFacilitators={(): void => setFacilitatorsOpen(false)}
            onAssignGroupsFacilitators={postAssignGroupsFacilitators}
            setAssignFacilitatorsStatus={setAssignFacilitatorsStatus}
            placement={{
              anchor: 'BOTTOM_CENTER',
              possibleAnchors: ['BOTTOM_CENTER'],
              triggerOffset: 10,
            }}
          >
            {({ triggerRef }): JSX.Element => (
              <button
                disabled={isAssignMenuDisabled}
                className={`input-group ${'edit-mode'} ${
                  facilitatorsOpen ? 'edit-active' : ''
                }`}
                ref={triggerRef}
                onClick={(): void => setFacilitatorsOpen(true)}
              >
                {renderFacilitatorField()}
              </button>
            )}
          </AssignFacilitatorMenu>
        )}
        {isAssignMenuDisabled ? (
          <div className="input-group">{renderGroupStatusField()}</div>
        ) : (
          <AssignGroupStatusMenu
            hideBackdrop
            groupStatusOpen={groupStatusOpen}
            assignGroupsStatusStatus={assignGroupsStatusStatus}
            assignGroupsStatusError={assignGroupsStatusError}
            groupStatusData={groupStatusData}
            currentGroupStatus={data.groupStatus?.id}
            getGroupStatuses={getGroupStatuses}
            onCloseGroupStatus={(): void => setGroupStatusOpen(false)}
            onAssignGroupsStatus={postAssignGroupsStatus}
            setAssignGroupsStatusStatus={setAssignGroupsStatusStatus}
            placement={{
              anchor: 'BOTTOM_CENTER',
              possibleAnchors: ['BOTTOM_CENTER'],
              triggerOffset: 10,
            }}
            tooltipPlacement={{
              anchor: 'RIGHT_CENTER',
              possibleAnchors: ['RIGHT_CENTER'],
              triggerOffset: 85,
              autoAdjust: true,
            }}
          >
            {({ triggerRef }): JSX.Element => (
              <button
                disabled={isAssignMenuDisabled}
                ref={triggerRef}
                className={`input-group ${'edit-mode'} ${
                  groupStatusOpen ? 'edit-active' : ''
                }`}
                onClick={(): void => setGroupStatusOpen(true)}
              >
                {renderGroupStatusField()}
              </button>
            )}
          </AssignGroupStatusMenu>
        )}
        <div className="input-group">
          <label>{intl.get('LBL_GD_LOCATION')}</label>
          <EllipsisTooltip
            tag="input"
            data-place="bottom"
            data-for="insTooltip"
            data-class="overflow-wrap"
            data-tip={data.address ? data.address : intl.get('LBL_NA')}
            type="text"
            className="text-input"
            value={data.address ? data.address : intl.get('LBL_NA')}
            readOnly
          />
        </div>
        <div className="input-group">
          <label>{intl.get('LBL_GD_GROUP_CONTACT')}</label>
          <EllipsisTooltip
            tag="input"
            data-place="bottom"
            data-for="insTooltip"
            data-class="overflow-wrap"
            data-tip={intl.get('LBL_NA')}
            type="text"
            className="text-input"
            value={intl.get('LBL_NA')}
            readOnly
          />
        </div>
        <div className="input-group">
          <label>{intl.get('LBL_GD_CYCLE')}</label>
          <EllipsisTooltip
            tag="input"
            data-place="bottom"
            data-for="insTooltip"
            data-class="overflow-wrap"
            data-tip={renderCycleDates()}
            type="text"
            className="text-input"
            value={renderCycleDates()}
            readOnly
          />
        </div>
        <div className="input-group">
          <label>{intl.get('LBL_GD_LAST_MEETING')}</label>
          <EllipsisTooltip
            tag="input"
            data-place="bottom"
            data-for="insTooltip"
            data-class="overflow-wrap"
            data-tip={renderFormattedDate(data.lastMeetingDate)}
            type="text"
            className="text-input"
            value={renderFormattedDate(data.lastMeetingDate)}
            readOnly
          />
        </div>
        <div className="input-group">
          <label>{intl.get('LBL_GD_NUM_OF_MEMBERS')}</label>
          <EllipsisTooltip
            tag="input"
            data-place="bottom"
            data-for="insTooltip"
            data-class="overflow-wrap"
            data-tip={
              data.numberOfMembers ? data.numberOfMembers : intl.get('LBL_NA')
            }
            type="text"
            className="text-input"
            value={
              data.numberOfMembers ? data.numberOfMembers : intl.get('LBL_NA')
            }
            readOnly
          />
        </div>
        <div className="input-group">
          <label>{intl.get('LBL_GD_PRICE_PER_SHARE')}</label>
          <EllipsisTooltip
            tag="input"
            data-place="bottom"
            data-for="insTooltip"
            data-class="overflow-wrap"
            data-tip={
              data.pricePerShare
                ? getCurrencyString(data.pricePerShare, false)
                : intl.get('LBL_NA')
            }
            type="text"
            className="text-input"
            value={
              data.pricePerShare
                ? getCurrencyString(data.pricePerShare, false)
                : intl.get('LBL_NA')
            }
            readOnly
          />
        </div>
        <div className="input-group">
          <label>{renderGroupSavingsLabel()}</label>
          <EllipsisTooltip
            tag="input"
            data-place="bottom"
            data-for="insTooltip"
            data-class="overflow-wrap"
            data-tip={renderMinimumSharesOrSavings()}
            type="text"
            className="text-input"
            value={renderMinimumSharesOrSavings()}
            readOnly
          />
        </div>
        <div className="input-group">
          <label>{intl.get('LBL_GD_MEETING_FREQ')}</label>
          <span data-for="insTooltip" data-tip={renderMeetingFreq()}>
            <EllipsisTooltip
              tag="input"
              data-place="bottom"
              data-for="insTooltip"
              data-class="overflow-wrap"
              data-tip={renderMeetingFreq()}
              type="text"
              className="text-input"
              value={renderMeetingFreq()}
              readOnly
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default GroupDetailsLeftPanel;
