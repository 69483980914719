import { useEffect, useState, FC as ReactFC, useContext } from 'react';

import { ResponsiveBar } from '@nivo/bar';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';

import DashboardApiInstance from 'api/dashboard/DashboardApi';
import GroupApiInstance from 'api/group/GroupApi';
import Misc from 'constants/misc/Misc';
import ModulePaths from 'constants/ModulePaths';
import NationalLevelContext from 'context/national-level-context/NationalLevelContext';
import { insightsColors } from 'helpers/ColorHelper';
import {
  formatUTCDateToLocal,
  formatNonStandardDateTimeString,
} from 'helpers/DateFormat';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';
import DateFormatType from 'shared/enums/DateFormatType';

import BackupSummaryChartData from '../BackupSummaryChartData';
import BackupStatusGraphProps from './BackupStatusGraphProps';
import CustomBarItem from './custom-bar-item/CustomBarItem';

const BackupStatusGraph: ReactFC<BackupStatusGraphProps> = (
  props: BackupStatusGraphProps
) => {
  const {
    navigationDisabled,
    history,
    globalFilters,
    location,
    limitedAccessUser,
  } = props;

  const { monitoredOrganizationId } = useContext(NationalLevelContext);
  const [, setLoading] = useState<boolean>(false);
  const [range, setRange] = useState<number>(100);
  const [refreshedDate, setRefreshedDate] = useState<string>(
    Misc.DefaultBackupStatusLastRefreshedDate
  );
  const [refreshTime, setRefreshTime] = useState<string>(
    Misc.DefaultBackupStatusRefreshTime
  );
  const [chartData, setChartData] = useState<BackupSummaryChartData>({
    group: 'G1',
    blue: 0,
    blueColor: insightsColors.insightBlue,
    yellow: 0,
    yellowColor: insightsColors.insightYellow,
    red: 0,
    redColor: insightsColors.insightOrange,
    green: 0,
    greenColor: insightsColors.insightSecondary,
  });

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  useEffect(() => {
    loadBackupSummary(globalFilters);
  }, [globalFilters, monitoredOrganizationId]);

  /**
   * Get current backup summary details
   *
   * @param filters Global filters
   */
  const loadBackupSummary = async (filters: GlobalFilters): Promise<void> => {
    try {
      setLoading(true);
      const response = await GroupApiInstance.GetBackupStatusSummaryData(
        filters
      );
      if (response.item) {
        const data = response.item;
        const valueRange =
          Number(data.blue) +
          Number(data.green) +
          Number(data.red) +
          Number(data.yellow);
        const summaryData = {
          group: 'G1',
          blue: data.blue,
          blueColor: insightsColors.insightBlue,
          yellow: data.yellow,
          yellowColor: insightsColors.insightYellow,
          red: data.red,
          redColor: insightsColors.insightOrange,
          green: data.green,
          greenColor: insightsColors.insightSecondary,
        };
        setRange(valueRange);
        setChartData(summaryData);
      }
      const info = await DashboardApiInstance.GetBackupStatusLastRefreshed();
      if (info.item) {
        const { lastRefreshed, schedulerTime } = info.item;
        if (lastRefreshed !== null) {
          setRefreshedDate(
            formatUTCDateToLocal(
              lastRefreshed,
              DateFormatType.BackupStatusLastRefreshedDate
            )
          );
        }
        if (schedulerTime !== null) {
          setRefreshTime(
            formatNonStandardDateTimeString(
              schedulerTime,
              DateFormatType.BackupStatusLastRefreshedPreFormatTime,
              DateFormatType.BackupStatusLastRefreshedTime,
              true
            )
          );
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getColor = (bar: { id: string }): string => chartData[`${bar.id}Color`];

  const goToGroups = (): void =>
    navigationDisabled
      ? noop()
      : history.push({
          pathname: ModulePaths.GroupsPath,
          search: getGlobalFiltersQuery(location.search),
        });

  const refreshTimeString = isEmpty(refreshTime)
    ? intl.get('ERR_BACKUP_STATUS_GRAPH_LAST_REFRESHED_FAILURE_HINT')
    : intl.get('LBL_BACKUP_STATUS_GRAPH_LAST_REFRESHED_HINT', {
        timeStamp: refreshTime,
      });

  return (
    <>
      <div
        className={`backup-status fixed-to-the-bottom ${
          limitedAccessUser ? 'raise-me-up' : ''
        }`}
      >
        <div className="text">
          <span>
            {intl.get('LBL_GROUP')} <br />
            {intl.get('LBL_TITLE_BACKUP_STATUS')}
          </span>
        </div>
        <div
          className={`backup-status-bar ${
            navigationDisabled ? 'disabled' : ''
          }`}
          style={{ width: '100%', height: 12 }}
        >
          <ResponsiveBar
            data={[chartData]}
            keys={['blue', 'green', 'yellow', 'red']}
            indexBy="group"
            maxValue={range}
            minValue={0}
            layout="horizontal"
            colors={getColor}
            axisTop={null}
            axisRight={null}
            axisBottom={null}
            axisLeft={null}
            enableGridY={false}
            enableLabel={false}
            animate={false}
            isInteractive={!navigationDisabled}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            barComponent={CustomBarItem}
            onClick={goToGroups}
          />
        </div>
        <div className="text backup-refresh">
          <span>
            {intl.get('LBL_BACKUP_STATUS_GRAPH_LAST_REFRESHED')} <br />
            <span className="help">
              {refreshedDate}
              <i
                className="icon-help"
                data-for={navigationDisabled ? '' : 'insTooltipBackupStatus'}
                data-class="overflow-wrap"
                data-place="right"
                data-tip={refreshTimeString}
              />
            </span>
          </span>
        </div>
      </div>
    </>
  );
};

export default BackupStatusGraph;
