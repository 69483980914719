/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext } from 'react';

import Status from 'shared/enums/Status';

import NationalLevelContextProps from './NationalLevelContextProps';

const NationalLevelContext = createContext<NationalLevelContextProps>({
  monitoredPartners: {
    status: Status.Loading,
    data: [],
  },
  monitoredOrganizationId: '',
  onPartnerSwitch: (): void => {},
});

export default NationalLevelContext;
