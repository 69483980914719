import {
  DashBoardApiTags,
  FinanceApiTags,
  GroupApiTags,
  ProjectsApiTags,
  SettingsApiTags,
  MessageApiTags,
  SurveysApiTags,
} from './RequestTags';

const OrgFilteredRequestTags = [
  DashBoardApiTags.GetDashboardExcel,
  DashBoardApiTags.GetDashboardFinancialMetricData,
  DashBoardApiTags.GetMembershipMetricData,
  DashBoardApiTags.GetGroupMetricsData,
  DashBoardApiTags.GetFacilitatorMetricData,
  DashBoardApiTags.GetWidgetData,
  DashBoardApiTags.GetFacilitators,
  DashBoardApiTags.GetBackupStatusLastRefreshed,
  FinanceApiTags.GetFinancesChartData,
  FinanceApiTags.GetKeyMetrics,
  GroupApiTags.GetBackupStatusSummaryData,
  GroupApiTags.GetGroupMetricData,
  GroupApiTags.GetGroupDetails,
  GroupApiTags.GetGroupDetailsBalanceSheet,
  GroupApiTags.GetGroupDetailsExcel,
  GroupApiTags.GetGroupWidgetData,
  GroupApiTags.GetGroupFinancialMetrics,
  GroupApiTags.GetGroupsExcel,
  GroupApiTags.GetGroupListData,
  GroupApiTags.GetGroupsAllIds,
  GroupApiTags.GetGroupMapData,
  GroupApiTags.GetGroupNotifications,
  GroupApiTags.GetGroupOverviewStats,
  MessageApiTags.GetCountries,
  SurveysApiTags.GetCountries,
  ProjectsApiTags.GetProjectsListData,
  ProjectsApiTags.GetAllProjects,
  ProjectsApiTags.GetProjectOrgCountries,
  ProjectsApiTags.GetProject,
  ProjectsApiTags.GetProjectUsers,
  ProjectsApiTags.GetProjectUsersExcel,
  ProjectsApiTags.GetAllProjectUsers,
  ProjectsApiTags.GetProjectFacilitators,
  ProjectsApiTags.GetProjectFacilitatorsExcel,
  ProjectsApiTags.GetUserProjectStats,
  SettingsApiTags.LookupOrganizationUsers,
  SettingsApiTags.GetOrganizationGroupStatuses,
  SettingsApiTags.LookupOrganizationFacilitators,
];

export default OrgFilteredRequestTags;
