/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-props-no-spreading */
import { Component, ReactNode, createRef } from 'react';

import axios from 'axios';
import { FieldArray, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { Location } from 'history';
import { isEmpty } from 'lodash';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import { ObjectSchema } from 'yup';

import AuthApiInstance from 'api/auth/AuthApi';
import ApiError from 'api/common/types/ApiError';
import SettingsApiInstance from 'api/settings/SettingsApi';
import ErrorCodes from 'constants/ErrorCodes';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import StorageKeys from 'constants/StorageKeys';
import { findErrorCode } from 'helpers/ErrorFormat';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import PermissionUtil from 'helpers/PermissionUtil';
import history from 'router-history';
import AuthStorageService from 'services/storage-services/AuthStorageService';
import { Option as OptionType } from 'shared/components/ins-form-fields/formik-select/FormikSelectOption';
import RouteChangeGuard from 'shared/components/route-change-guard/RouteChangeGuard';
import ScrollToTopOnMount from 'shared/components/scroll-to-top-on-mount/ScrollToTopOnMount';
import DefaultPermissionLevel from 'shared/enums/DefaultPermissionLevel';
import HTTP_STATUS from 'shared/enums/HttpStatus';
import PermissionsViewMode from 'shared/enums/PermissionsViewMode';
import Status from 'shared/enums/Status';
import InviteUsersFieldArray from 'shared/modules/users/components/invite-users-field-array/InviteUsersFieldArray';

import styles from './inviteUsers.module.scss';
import InviteUsersErrorStatus from './InviteUsersErrorStatus';
import InviteUsersProps from './InviteUsersProps';
import InviteUsersState from './InviteUsersState';
import InviteUsersValidations from './InviteUsersValidations';
import InviteUsersViewModel from './InviteUsersViewModel';
import SupervisorsViewModel from './SupervisorsViewModel';
import UserFormValue, { InviteUsersFormValues } from './UserFormValue';

class InviteUsers extends Component<InviteUsersProps, InviteUsersState> {
  constructor(props: InviteUsersProps) {
    super(props);
    const orgId = AuthStorageService.GetItem<string>(
      StorageKeys.OrganizationId
    );

    const { appContext } = this.props;
    const { permissionsData, gettingStartedStates } = appContext;
    const { claims } = permissionsData;

    const canInviteUsers = PermissionUtil.Can(
      claims,
      ResourceKeys.SettingsUsersInviteUsers
    );
    const canAddPermissions = PermissionUtil.Can(
      claims,
      ResourceKeys.SettingsPermissionsAddNewLevel
    );
    const canEditPermissions = PermissionUtil.Can(
      claims,
      ResourceKeys.SettingsPermissionsItemEdit
    );
    const permissionsFirstTime = gettingStartedStates.data
      ? !gettingStartedStates.data.permissionCompleted
      : false;

    this.state = {
      organizationId: orgId,
      createJobRoleStatus: Status.Idle,
      createJobRoleError: null,
      jobRoles: [],
      jobRolesStatus: Status.Idle,
      supervisors: [],
      supervisorsFiltered: [],
      supervisorsStatus: Status.Idle,
      countries: [],
      countriesStatus: Status.Idle,
      permissionLevels: [],
      permissionLevelsStatus: Status.Idle,
      createUsersStatus: Status.Idle,
      canInviteUsers,
      canAddPermissions,
      canEditPermissions,
      permissionsFirstTime,
    };

    this.formikRef = createRef<React.RefObject<FormikValues>>();
  }

  componentDidMount(): void {
    const { appContext } = this.props;
    appContext.hideErrorToast();
    ReactTooltip.rebuild();
    this.fetchJobRoles();
    this.fetchPermissionLevels();
    this.fetchSupervisors();
    this.fetchOrganizationCountries();
  }

  componentDidUpdate(prevProps: InviteUsersProps): void {
    const { appContext } = this.props;
    const { gettingStartedStates } = appContext;
    const claims = get(this.props, 'permissionsData.claims');

    if (
      prevProps.appContext.inviteUsersSubmitting !==
      appContext.inviteUsersSubmitting
    ) {
      if (appContext.inviteUsersSubmitting && this.formikRef.current) {
        this.formikRef.current.submitForm();
      }
    }

    /** update {permissionsFirstTime} when getting started state updates */
    if (
      gettingStartedStates.status !==
      prevProps.appContext.gettingStartedStates.status
    ) {
      const permissionsFirstTime = gettingStartedStates.data
        ? !gettingStartedStates.data.permissionCompleted
        : false;

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ permissionsFirstTime });
    }

    if (!isEqual(get(prevProps, 'permissionsData.claims'), claims)) {
      const canInviteUsers = PermissionUtil.Can(
        claims,
        ResourceKeys.SettingsUsersInviteUsers
      );
      const canAddPermissions = PermissionUtil.Can(
        claims,
        ResourceKeys.SettingsPermissionsAddNewLevel
      );
      const canEditPermissions = PermissionUtil.Can(
        claims,
        ResourceKeys.SettingsPermissionsItemEdit
      );
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ canInviteUsers, canAddPermissions, canEditPermissions });
    }
    ReactTooltip.rebuild();
  }

  componentWillUnmount(): void {
    const { appContext } = this.props;
    appContext.setInviteUsersSubmitting(false);

    this.source.cancel();
  }

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  formikRef: React.RefObject<FormikValues>;

  initialValue: InviteUsersFormValues = {
    users: [new UserFormValue()],
  };

  getInitialValues = (): InviteUsersFormValues => ({
    users: [new UserFormValue()],
  });

  /**
   * Create a job role and update status on component state
   *
   * @param jobRole Job Role name
   */
  createJobRole = async (jobRole: string): Promise<void> => {
    const { appContext } = this.props;
    if (jobRole && jobRole.trim().length > 0) {
      this.setState({ createJobRoleStatus: Status.Loading });
      try {
        const response = await SettingsApiInstance.CreateJobRole(
          jobRole,
          this.source
        );
        if (response.item) {
          const result = response.item;
          this.setState({
            createJobRoleStatus: Status.Success,
            createJobRoleError: null,
          });

          sendEventGA(
            CategoryKeysGA.SettingsUsersInvite,
            ActionKeysGA.CreateJobRole
          );

          this.fetchJobRoles(result.id);
        } else {
          throw new Error();
        }
      } catch (error) {
        const newState: Partial<InviteUsersState> = {
          createJobRoleStatus: Status.Error,
        };
        if (error instanceof ApiError) {
          if (error.status === HTTP_STATUS.BAD_REQUEST) {
            const duplicate = findErrorCode(error, ErrorCodes.JobRoleExists);
            if (duplicate) {
              newState.createJobRoleError = intl.get(
                'ERR_INVITE_USERS_JOB_ROLE_ALREADY_EXISTS'
              );
            }
          } else {
            appContext.setErrorToastText(
              intl.get('ERR_TOAST_INVITE_USER_GENERIC_ERRORS')
            );
          }
        } else {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_INVITE_USER_GENERIC_ERRORS')
          );
        }
        this.setState(newState as InviteUsersState);
      }
    }
  };

  /**
   * Reset jobRoles state
   */
  clearFocusStatus = (): void => {
    this.setState({ jobRolesStatus: Status.Loading });
    const { jobRoles } = this.state;
    const newJobRoles = jobRoles.map(({ value, label }) => ({ value, label }));
    this.setState({
      jobRoles: newJobRoles,
      jobRolesStatus: Status.Success,
    });
  };

  /**
   * Get job roles and set to component state
   *
   * @param createdRoleId ID of newly created job role
   */
  fetchJobRoles = async (createdRoleId?: string): Promise<void> => {
    this.setState({ jobRolesStatus: Status.Loading });
    try {
      const response = await SettingsApiInstance.GetJobRoles(this.source);
      const jobRoles = response.items.map(({ role, id }) => ({
        value: id,
        label: role,
        ...(createdRoleId && createdRoleId === id ? { focused: true } : {}),
      }));
      this.setState(
        {
          jobRoles,
          jobRolesStatus: Status.Success,
          createJobRoleStatus: Status.Idle,
          createJobRoleError: null,
        },
        this.clearFocusStatus
      );
    } catch (error) {
      this.setState({
        jobRolesStatus: Status.Error,
        createJobRoleStatus: Status.Idle,
        createJobRoleError: null,
      });
    }
  };

  /**
   * Get permission levels for current organization
   */
  fetchPermissionLevels = async (): Promise<void> => {
    const { organizationId } = this.state;
    this.setState({ permissionLevelsStatus: Status.Loading });

    try {
      let orgId = organizationId;
      if (!organizationId) {
        const userInfo = await AuthApiInstance.GetUserInfo();
        orgId = userInfo.organizationId;
      }

      const response = await SettingsApiInstance.GetPermissionLevels(
        orgId,
        PermissionsViewMode.EDIT,
        this.source
      );

      const permissionLevels = response.items
        .filter(({ key }) => key !== DefaultPermissionLevel.NATIONAL_LEVEL)
        .map(({ key, title, description }) => ({
          label: title,
          value: key,
          description,
        }));
      this.setState({
        permissionLevels,
        permissionLevelsStatus: Status.Success,
      });
    } catch (error) {
      this.setState({ permissionLevelsStatus: Status.Error });
    }
  };

  /**
   * Fetch supervisors for search query
   *
   * @param search Search string query
   */
  fetchSupervisors = async (search?: string): Promise<void> => {
    this.setState({ supervisorsStatus: Status.Loading });
    const noneOption = new SupervisorsViewModel();
    let supervisors: OptionType[] = [
      { label: noneOption.name, value: noneOption.id },
    ];

    try {
      const orgUsersResponse =
        await SettingsApiInstance.LookupOrganizationUsers(this.source, search);

      const supervisorsFormatted = orgUsersResponse.items.map(
        ({ userId, name }) => ({
          label: name,
          value: userId,
        })
      );

      supervisors = [...supervisors, ...supervisorsFormatted];

      if (search === undefined) {
        this.setState({
          supervisors,
          supervisorsFiltered: supervisors,
          supervisorsStatus: Status.Success,
        });
      } else {
        this.setState({
          supervisorsFiltered: supervisors,
          supervisorsStatus: Status.Success,
        });
      }
    } catch (error) {
      this.setState({ supervisorsStatus: Status.Error });
    }
  };

  /**
   * Fetch organization countries
   */
  fetchOrganizationCountries = async (): Promise<void> => {
    this.setState({ countriesStatus: Status.Loading });
    try {
      const countriesResponse =
        await SettingsApiInstance.GetOrganizationCountries(this.source);
      const countries = countriesResponse.items.map((role) => ({
        value: role.code,
        label: role.name,
        isFixed: false,
      }));
      this.setState({ countries, countriesStatus: Status.Success });
    } catch (error) {
      this.setState({ countriesStatus: Status.Error });
    }
  };

  /**
   * Create users by bulk
   *
   * @param values List of users
   */
  createUsers = async (values: InviteUsersViewModel[]): Promise<void> => {
    this.setState({ createUsersStatus: Status.Loading });
    try {
      await SettingsApiInstance.CreateUsersByBulk(values, this.source);
      this.setState({ createUsersStatus: Status.Success });
      sendEventGA(
        CategoryKeysGA.SettingsUsersInvite,
        ActionKeysGA.InviteNewUsers,
        `Count: ${values.length}`
      );
    } catch (error) {
      this.setState({ createUsersStatus: Status.Error });
      throw error;
    }
  };

  /**
   * clears the job role error status and error
   */
  clearJobRoleError = (): void => {
    this.setState({
      createJobRoleError: null,
      createJobRoleStatus: Status.Idle,
    });
  };

  /**
   * Handles form submission to invite (a) new user/s
   *
   * @param values Values entered into the invite users form
   * @param helpers Formik helpers for the invite users form
   */
  handleSubmit = async (
    values: InviteUsersFormValues,
    helpers: FormikHelpers<InviteUsersFormValues>
  ): Promise<void> => {
    const { location, appContext } = this.props;
    const formatted = values.users.map(
      (user) =>
        new InviteUsersViewModel(
          user.id,
          user.email,
          user.jobRole,
          user.isImplementingPartner,
          user.permission,
          user.countryDataAccess,
          user.supervisorId
        )
    );
    try {
      await this.createUsers(formatted);
      helpers.setSubmitting(false);

      const initialValues: InviteUsersFormValues = this.getInitialValues();
      helpers.resetForm({ values: initialValues });

      this.setState({
        createUsersStatus: Status.Idle,
      });
      history.push({
        pathname: `${ModulePaths.SettingsPath}${ModulePaths.SettingsUsersPath}`,
        search: getGlobalFiltersQuery(location.search),
      });
    } catch (error) {
      if (error instanceof ApiError) {
        if (!isEmpty(error.response)) {
          helpers.setStatus({
            errorCode: error.response?.errorCode,
            errorMessage: error.response?.errorMessage,
            errors: error.response?.errors,
          } as InviteUsersErrorStatus);
        }

        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_INVITE_USER_FORM_ERRORS')
          );
        }
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_INVITE_USER_FORM_ERRORS')
        );
      }

      helpers.setSubmitting(false);
    }
  };

  /**
   * handles addition of new form item
   */
  handleAddNewItem = (): UserFormValue => new UserFormValue();

  /**
   * Handles blocking navigation out of the form
   *
   * @param isDirty Form dirty status
   * @param location Location to navigate to
   * @returns {boolean} Form navigation blocking status
   */
  handleBlockingNavigation = (
    isDirty: boolean,
    location: Location
  ): boolean => {
    const { location: currentLocation } = this.props;
    const formikData = this.formikRef.current;
    if (formikData && isDirty) {
      /**
       * Here we explicitly compare initial users array and most recent users array
       * specifically without {id} property; because id is a dynamic property and
       * not a piece of actual form data it will also be counted as a change making
       * dirty check not enough to determine this; this issue occurs specially when
       * a users add multiple form items and removes the top form item; leaving only
       * one form item, making it practically identical top initial values; except
       * for the changed {id} property which the user knows nothing about
       */
      // prettier-ignore
      const initial = formikData.initialValues?.users?.map(({ id, ...rest }) => rest) ?? [];
      // prettier-ignore
      const current = formikData.values?.users?.map(({ id, ...rest }) => rest) ?? [];

      if (
        !isEqual(initial, current) &&
        location.pathname !== currentLocation.pathname
      ) {
        return true;
      }
    }
    return false;
  };

  /**
   * Renders the invite users form
   *
   * @returns {ReactNode} JSX snippet containing the form component to invite users
   */
  render(): ReactNode {
    const { appContext, location } = this.props;
    const {
      jobRoles,
      jobRolesStatus,
      countries,
      countriesStatus,
      permissionLevels,
      permissionLevelsStatus,
      createJobRoleStatus,
      supervisors,
      supervisorsFiltered,
      supervisorsStatus,
      canInviteUsers,
      canAddPermissions,
      canEditPermissions,
      permissionsFirstTime,
      createJobRoleError,
    } = this.state;

    const validationSchema =
      InviteUsersValidations.GetValidationSchema() as ObjectSchema;
    return (
      <div className="content-container">
        <ScrollToTopOnMount />
        <div className={`${styles.card} insight-card`}>
          <Formik
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            innerRef={this.formikRef}
            initialValues={this.initialValue}
            validationSchema={validationSchema}
            onSubmit={this.handleSubmit}
          >
            {({ isSubmitting, dirty, errors }): JSX.Element => (
              <Form>
                <RouteChangeGuard
                  when={dirty}
                  navigate={(nextLocation): void =>
                    history.push({
                      pathname: nextLocation.pathname,
                      search: getGlobalFiltersQuery(location.search),
                    })
                  }
                  shouldBlockNavigation={(loc: Location): boolean =>
                    this.handleBlockingNavigation(dirty, loc)
                  }
                />
                <FieldArray
                  name="users"
                  render={(props): JSX.Element => (
                    <InviteUsersFieldArray
                      jobRoles={jobRoles}
                      jobRolesStatus={jobRolesStatus}
                      supervisors={supervisors}
                      supervisorsFiltered={supervisorsFiltered}
                      supervisorsStatus={supervisorsStatus}
                      onFetchSupervisors={this.fetchSupervisors}
                      countries={countries}
                      countriesStatus={countriesStatus}
                      permissionLevels={permissionLevels}
                      permissionLevelsStatus={permissionLevelsStatus}
                      canInviteUsers={canInviteUsers}
                      canAddPermissions={canAddPermissions}
                      canEditPermissions={canEditPermissions}
                      permissionsFirstTime={permissionsFirstTime}
                      onCreateJobRole={this.createJobRole}
                      createJobRoleStatus={createJobRoleStatus}
                      createJobRoleError={createJobRoleError}
                      onSubmitEnd={appContext.setInviteUsersSubmitting}
                      isSubmitting={isSubmitting}
                      validationSchema={validationSchema}
                      errors={errors}
                      setCommonError={appContext.setErrorToastText}
                      fetchPermissionLevels={this.fetchPermissionLevels}
                      getGettingStartedState={appContext.getGettingStartedState}
                      onAddNewItem={this.handleAddNewItem}
                      clearJobRoleError={this.clearJobRoleError}
                      {...props}
                    />
                  )}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default InviteUsers;
