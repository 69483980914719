/* eslint-disable react/jsx-props-no-spreading */
import { createRef, Component } from 'react';

import axios from 'axios';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';

import AuthApiInstance from 'api/auth/AuthApi';
import ListResponse from 'api/common/responses/ListResponse';
import ObjectResponse from 'api/common/responses/ObjectResponse';
import SuccessResponse from 'api/common/responses/SuccessResponse';
import ApiError from 'api/common/types/ApiError';
import ProjectsApiInstance from 'api/projects/ProjectsApi';
import ProjectCreated from 'api/projects/types/ProjectCreated';
import ProjectDetail from 'api/projects/types/ProjectDetail';
import ProjectItem from 'api/projects/types/ProjectItem';
import ProjectsFileItem from 'api/projects/types/ProjectsFileItem';
import ProjectsPagination from 'api/projects/types/ProjectsPagination';
import SettingsApiInstance from 'api/settings/SettingsApi';
import OrganizationFacilitator from 'api/settings/types/facilitators/OrganizationFacilitator';
import OrganizationUser from 'api/settings/types/users/OrganizationUser';
import ErrorCodes from 'constants/ErrorCodes';
import Constraints from 'constants/forms/Constraints';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import ModulePaths from 'constants/ModulePaths';
import StorageKeys from 'constants/StorageKeys';
import Tables from 'constants/Tables';
import {
  ErrorStatus,
  findErrorCode,
  getErrorStatus,
} from 'helpers/ErrorFormat';
import {
  getGlobalFilters,
  getGlobalFiltersQuery,
  getGlobalFiltersSearch,
} from 'helpers/GlobalFilterUtils';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import setPageTitle from 'helpers/setPageTitle';
import AddFacilitatorsModal from 'modules/private/projects/components/add-facilitators-modal/AddFacilitatorsModal';
import AddUsersModal from 'modules/private/projects/components/add-users-modal/AddUsersModal';
import ProjectInfo from 'modules/private/projects/components/project-info/ProjectInfo';
import ProjectInfoFormValues from 'modules/private/projects/components/project-info/ProjectInfoFormValues';
import ProjectList from 'modules/private/projects/components/project-list/ProjectList';
import ProjectListType from 'modules/private/projects/components/project-list/ProjectListHandle';
import AuthStorageService from 'services/storage-services/AuthStorageService';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';
import { Option as OptionType } from 'shared/components/ins-form-fields/formik-select/FormikSelectOption';
import DefaultPermissionLevel from 'shared/enums/DefaultPermissionLevel';
import EventKey from 'shared/enums/EventKey';
import HTTP_STATUS from 'shared/enums/HttpStatus';
import PermissionsViewMode from 'shared/enums/PermissionsViewMode';
import ProjectViewMode from 'shared/enums/ProjectViewMode';
import Status from 'shared/enums/Status';
import TooltipType from 'shared/enums/TooltipType';
import { EventBus } from 'shared/events/EventBus';
import { CustomErrorArgs } from 'shared/types/eventTypes';

import InviteProjectUsersViewModel from '../../components/add-users-modal/invite-project-users/InviteProjectUsersViewModel';
import InviteProjectFacilitatorsViewModel from './InviteProjectFacilitatorsViewModel';
import ProjectFacilitatorViewModel from './ProjectFacilitatorViewModel';
import ProjectStatsViewModel from './ProjectStatsViewModel';
import ProjectsViewProps from './ProjectsViewProps';
import ProjectsViewState, { FileItem } from './ProjectsViewState';
import ProjectUsersViewModel from './ProjectUsersViewModel';
import SupervisorsViewModel from './SupervisorsViewModel';

class ProjectsView extends Component<ProjectsViewProps, ProjectsViewState> {
  constructor(props: ProjectsViewProps) {
    super(props);
    const orgId = AuthStorageService.GetItem<string>(
      StorageKeys.OrganizationId
    );

    this.state = {
      organizationId: orgId,
      status: Status.Idle,
      projectInfo: {
        newCode: '',
        data: new ProjectInfoFormValues(),
        showModal: false,
        codeError: {
          error: null,
          message: '',
        },
        loadError: null,
      },
      users: {
        showModal: false,
        add: {
          data: Array<ProjectUsersViewModel>(),
          addUsersStatus: Status.Idle,
          canAddUserToList: false,
          displayCDAWarningPrompt: false,
          compatibilityStatus: Status.Idle,
          selectedUser: null,
          userUnassignedCountries: [],
          error: null,
        },
        invite: {
          createJobRoleStatus: Status.Idle,
          createJobRoleError: null,
          jobRoles: [],
          jobRolesStatus: Status.Idle,
          supervisors: [],
          supervisorsFiltered: [],
          supervisorsStatus: Status.Idle,
          countries: [],
          countriesStatus: Status.Idle,
          permissionLevels: [],
          permissionLevelsStatus: Status.Idle,
          createUsersStatus: Status.Idle,
        },
      },
      facilitators: {
        showModal: false,
        add: {
          data: Array<ProjectFacilitatorViewModel>(),
          addFacilitatorsStatus: Status.Idle,
          error: null,
        },
        invite: {
          imageStatus: Status.Idle,
          createFacilitatorsStatus: Status.Idle,
        },
      },
      projectList: {
        list: Array<ProjectItem>(),
        projectUpdateSilent: false,
        projectsStatus: Status.Idle,
        projectStats: new ProjectStatsViewModel(false, false, false),
        projectStatsStatus: Status.Idle,
        scrolling: false,
        firstWarningItem: null,
        showGroupsGuide: true,
        pageCount: 0,
        pageSize: Constraints.LazyLoadingPageSize,
        pageIndex: 0,
        total: 0,
      },
    };
  }

  componentDidMount(): void {
    const { appContext } = this.props;
    appContext.hideErrorToast();
    setPageTitle(intl.get('BTN_PROJECTS'));

    this.loadProjectsData();
    this.handleRouteChange();
    this.fetchJobRoles();
    this.fetchPermissionLevels();
    this.fetchSupervisors();
  }

  componentDidUpdate(
    prevProps: ProjectsViewProps
    /* prevState: ProjectsViewState */
  ): void {
    const { type } = this.props;
    ReactTooltip.rebuild();
    if (prevProps.type !== type) {
      this.handleRouteChange();
    }
  }

  componentWillUnmount(): void {
    this.source.cancel();
  }

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  listRef = createRef<ProjectListType>();

  /**
   * Toggle project info modal
   */
  toggleProjectInfoModal = (): void => {
    const { history, location } = this.props;
    const { projectInfo } = this.state;
    const { showModal } = projectInfo;
    if (showModal) {
      this.setProjectInfoStateAttribute({ newCode: '' });
      history.replace({
        pathname: ModulePaths.ProjectsPath,
        search: getGlobalFiltersQuery(location.search),
      });
    }
    this.setProjectInfoStateAttribute({ showModal: !showModal });
  };

  /**
   * Toggle add users modal
   */
  toggleAddUsersModal = (): void => {
    const { history, location } = this.props;
    const { users } = this.state;
    const { showModal } = users;
    if (showModal) {
      history.replace({
        pathname: ModulePaths.ProjectsPath,
        search: getGlobalFiltersQuery(location.search),
      });
    }
    this.setState((state) => ({
      ...state,
      users: { ...state.users, showModal: !showModal },
    }));
  };

  /**
   * Toggle add facilitators modal
   */
  toggleAddFacilitatorsModal = (): void => {
    const { history, location } = this.props;
    const { facilitators } = this.state;
    const { showModal } = facilitators;
    if (showModal) {
      history.replace({
        pathname: ModulePaths.ProjectsPath,
        search: getGlobalFiltersQuery(location.search),
      });
    }
    this.setState((state) => ({
      ...state,
      facilitators: { ...state.facilitators, showModal: !showModal },
    }));
  };

  /**
   * Sets updated state to users slice of state
   *
   * @param updateState Updated state
   */
  setUsersStateAttribute = (
    updateState: Partial<ProjectsViewState['users']>
  ): void =>
    this.setState((state) => ({
      ...state,
      users: { ...state.users, ...updateState },
    }));

  /**
   * Sets updated state to facilitators slice of state
   *
   * @param updateState Updated state
   */
  setFacilitatorsStateAttribute = (
    updateState: Partial<ProjectsViewState['facilitators']>
  ): void =>
    this.setState((state) => ({
      ...state,
      facilitators: { ...state.facilitators, ...updateState },
    }));

  /**
   * Sets updated state to projectInfo slice of state
   *
   * @param updateState Updated state
   */
  setProjectInfoStateAttribute = (
    updateState: Partial<ProjectsViewState['projectInfo']>
  ): void =>
    this.setState((state) => ({
      projectInfo: { ...state.projectInfo, ...updateState },
    }));

  /**
   * Sets updated state to facilitators-add slice of state
   *
   * @param updateState Updated state
   */
  setAddFacilitatorsStateAttribute = (
    updateState: Partial<ProjectsViewState['facilitators']['add']>,
    callback?
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        facilitators: {
          ...state.facilitators,
          add: { ...state.facilitators.add, ...updateState },
        },
      }),
      callback
    );

  /**
   * Sets updated state to facilitators-invite slice of state
   *
   * @param updateState Updated state
   */
  setInviteFacilitatorsStateAttribute = (
    updateState: Partial<ProjectsViewState['facilitators']['invite']>,
    callback?
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        facilitators: {
          ...state.facilitators,
          invite: { ...state.facilitators.invite, ...updateState },
        },
      }),
      callback
    );

  /**
   * Sets updated state to users-add slice of state
   *
   * @param updateState Updated state
   */
  setAddUsersStateAttribute = (
    updateState: Partial<ProjectsViewState['users']['add']>,
    callback?
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        users: { ...state.users, add: { ...state.users.add, ...updateState } },
      }),
      callback
    );

  /**
   * Sets updated state to users-invite slice of state
   *
   * @param updateState Updated state
   */
  setInviteUsersStateAttribute = (
    updateState: Partial<ProjectsViewState['users']['invite']>,
    callback?
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        users: {
          ...state.users,
          invite: { ...state.users.invite, ...updateState },
        },
      }),
      callback
    );

  /**
   * Sets updated state to projectList slice of state
   *
   * @param updateState Updated state
   */
  setProjectListStateAttribute = (
    updateState: Partial<ProjectsViewState['projectList']>
  ): void =>
    this.setState((state) => ({
      ...state,
      projectList: { ...state.projectList, ...updateState },
    }));

  /**
   * Fetch a new project ID
   */
  getProjectId = async (): Promise<void> => {
    try {
      this.setState({ status: Status.Loading });
      const response = await ProjectsApiInstance.GetNewProjectId(this.source);
      if (response.item) {
        this.setProjectInfoStateAttribute({ newCode: response.item.code });
        this.setState({ status: Status.Success });
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setState({ status: Status.Error });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          if (error.response) {
            const errorMessage = error.response.message;
            this.setProjectInfoStateAttribute({
              codeError: {
                error,
                message:
                  errorMessage || intl.get('ERR_PROJECTS_NEW_CODE_GENERATION'),
              },
            });
          } else {
            this.setGenericProjectIdError();
          }
        }
      } else {
        this.setGenericProjectIdError();
      }
    }
  };

  /**
   * Sets generic project ID error and triggers error toast
   */
  setGenericProjectIdError = (): void => {
    const { appContext } = this.props;
    this.setProjectInfoStateAttribute({
      codeError: {
        error: new Error(intl.get('ERR_TOAST_GENERIC_ERROR')),
        message: intl.get('ERR_PROJECTS_NEW_CODE_GENERATION'),
      },
    });
    appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
  };

  /**
   * Fetch a selected project's existing users
   *
   * @param projectId Project ID
   */
  loadSelectedProjectExistingUsers = async (
    projectId: string
  ): Promise<void> => {
    const { appContext } = this.props;
    try {
      this.setAddUsersStateAttribute({ addUsersStatus: Status.Loading });
      const result = await ProjectsApiInstance.GetAllProjectUsers(
        projectId,
        this.source
      );
      if (result) {
        this.setAddUsersStateAttribute({
          data: result.items,
          addUsersStatus: Status.Success,
        });
      }
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          const formattedError = this.handleUserErrors(error);
          this.setAddUsersStateAttribute({
            addUsersStatus: Status.Error,
            error: formattedError,
          });
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  };

  /**
   * Fetch a selected project's existing facilitators
   *
   * @param projectId Project ID
   */
  loadSelectedProjectExistingFacilitators = async (
    projectId: string
  ): Promise<void> => {
    const { appContext } = this.props;
    const Direction = Tables.SortDirection;

    try {
      this.setAddFacilitatorsStateAttribute({
        addFacilitatorsStatus: Status.Loading,
      });
      const queryParams = {
        sortBy: [{ id: 'name', desc: false }],
        page: 0,
        pageSize: -1,
      } as {
        sortBy: { id: string; desc: boolean }[];
        page: number;
        pageSize: number;
      };

      const sortByAttribute = queryParams.sortBy[0].id;
      const sortDirection = queryParams.sortBy[0].desc
        ? Direction.Descending
        : Direction.Ascending;
      const pageIndexAltered = queryParams.page;

      const result = await ProjectsApiInstance.GetProjectFacilitators(
        projectId,
        this.source,
        {
          pageSize: queryParams.pageSize,
          page: pageIndexAltered,
          sortBy: sortByAttribute,
          sort: sortDirection,
        }
      );

      if (result) {
        this.setAddFacilitatorsStateAttribute({
          data: result.items,
          addFacilitatorsStatus: Status.Success,
        });
      }
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          const formattedError = this.handleFacilitatorErrors(error);
          this.setAddFacilitatorsStateAttribute({
            addFacilitatorsStatus: Status.Error,
            error: formattedError,
          });
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  };

  /**
   * Fetch a selected project's data
   *
   * @param projectId Project ID
   */
  loadSelectedProjectData = async (projectId: string): Promise<void> => {
    const { appContext, history, location } = this.props;
    try {
      this.setState({ status: Status.Loading });
      const response = await ProjectsApiInstance.GetProject(
        projectId,
        this.source
      );
      if (response.item) {
        const result = response.item;
        const formValues = new ProjectInfoFormValues(
          result.code,
          result.name,
          result.startDate ? new Date(result.startDate).toString() : '',
          result.endDate ? new Date(result.endDate).toString() : '',
          result.isTestProject.toString(),
          result.projectId
        );
        this.setProjectInfoStateAttribute({ data: formValues });
        this.setState({ status: Status.Success });
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setState({ status: Status.Error });
      if (error instanceof ApiError) {
        this.setProjectInfoStateAttribute({ loadError: error });
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
          if (error.status === HTTP_STATUS.NOT_FOUND) {
            /* If the project has been deleted concurrently, redirect to 
            the projects page and re-load the projects list */
            history.replace({
              pathname: ModulePaths.ProjectsPath,
              search: getGlobalFiltersQuery(location.search),
            });
            await this.loadProjectsData(false, true);
          }
        }
      } else {
        this.setProjectInfoStateAttribute({
          loadError: new Error(intl.get('ERR_PROJECTS_FETCH_DETAILS_FAILURE')),
        });
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  };

  /**
   * Fetch paginated projects data
   *
   * @param pageConfig Pagination configuration
   * @param updateSilent Whether to update projects silently
   * @param resetScroll Whether to reset scroll to initial position
   */
  loadProjectsDataPaginated = async (
    pageConfig: Pick<ProjectsPagination, 'page' | 'pageSize'>,
    updateSilent = false,
    resetScroll = false
  ): Promise<void> => {
    const { appContext } = this.props;
    const { projectList } = this.state;
    try {
      this.setProjectListStateAttribute({
        projectsStatus: Status.Loading,
        projectUpdateSilent: updateSilent,
        scrolling: resetScroll,
      });
      const result = await ProjectsApiInstance.GetProjectsListData(
        pageConfig,
        this.source
      );
      if (result) {
        const { items, pagination } = result;
        const newList = [...projectList.list, ...items];
        const projectsNormalized = newList.reduce(
          (accumulator, current) => ({
            ...accumulator,
            [current.projectId]: current,
          }),
          {}
        ) as { [key: string]: ProjectItem };

        const list = resetScroll ? items : Object.values(projectsNormalized);

        /** sets the first project id which has a warning
         * to show the guide tooltip on that project;
         */
        const warningItems = list.filter((item) =>
          Object.values(item.notifications).some((count) => count > 0)
        );
        let firstWarning = projectList.firstWarningItem;
        if (warningItems.length > 0) {
          firstWarning = warningItems[0].projectId;
        } else {
          firstWarning = null;
        }

        this.setProjectListStateAttribute({
          list,
          projectsStatus: Status.Success,
          projectUpdateSilent: false,
          scrolling: false,
          pageIndex: pagination.page,
          pageSize: pagination.pageSize,
          total: pagination.total,
          pageCount: Math.ceil(pagination.total / pagination.pageSize),
          firstWarningItem: firstWarning,
        });

        if (resetScroll) {
          if (this.listRef.current) {
            this.listRef.current.resetloadMoreItemsCache();
            this.listRef.current.scrollToItem(0);
          }
        }
      }
    } catch (error) {
      this.setProjectListStateAttribute({
        projectsStatus: Status.Error,
        projectUpdateSilent: false,
        scrolling: false,
        pageIndex: 0,
        pageSize: Constraints.LazyLoadingPageSize,
        total: 0,
        pageCount: 0,
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  };

  /**
   * Fetch projects data
   *
   * @param updateSilent Whether to update projects silently
   * @param resetScroll Whether to reset scroll to initial position
   */
  loadProjectsData = (
    updateSilent = false,
    resetScroll = false
  ): Promise<void> => {
    const { pageSize, pageIndex } = this.state.projectList;
    let filters;
    if (resetScroll) {
      filters = { page: 0, pageSize: Constraints.LazyLoadingPageSize };
    } else {
      filters = { page: pageIndex, pageSize };
    }
    return this.loadProjectsDataPaginated(filters, updateSilent, resetScroll);
  };

  /**
   * Fetch projects data for the next page
   *
   * @param _startIndex First index for page from overall projects list
   * @param _stopIndex Last index for page from overall projects list
   */
  // eslint-disable-next-line no-unused-vars
  loadNextPage = (_startIndex: number, _stopIndex: number): Promise<void> => {
    const { pageSize, pageIndex } = this.state.projectList;
    const filters = { page: pageIndex + 1, pageSize };
    return this.loadProjectsDataPaginated(filters);
  };

  /**
   * Fetch users from current organization
   *
   * @param query Search value
   * @returns {Promise<ListResponse<OrganizationUser>>} A promise typed as a list of organization users
   */
  fetchOrgUsers = async (
    query?: string
  ): Promise<ListResponse<OrganizationUser>> =>
    SettingsApiInstance.LookupOrganizationUsers(this.source, query);

  /**
   * Fetch facilitators from current organization
   *
   * @param query Search value
   * @returns {Promise<ListResponse<OrganizationFacilitator>>} A promise typed as a list of organization facilitators
   */
  fetchOrgFacilitators = async (
    query?: string
  ): Promise<ListResponse<OrganizationFacilitator>> =>
    SettingsApiInstance.LookupOrganizationFacilitators(this.source, query);

  /**
   * Create a job role and update status on component state
   *
   * @param jobRole Job Role name
   */
  createJobRole = async (jobRole: string): Promise<void> => {
    const { appContext } = this.props;
    if (jobRole && jobRole.trim().length > 0) {
      this.setInviteUsersStateAttribute({
        createJobRoleStatus: Status.Loading,
      });
      try {
        const response = await SettingsApiInstance.CreateJobRole(
          jobRole,
          this.source
        );
        if (response.item) {
          const result = response.item;
          this.setInviteUsersStateAttribute({
            createJobRoleStatus: Status.Success,
            createJobRoleError: null,
          });

          sendEventGA(
            CategoryKeysGA.ProjectsUsersAdd,
            ActionKeysGA.CreateJobRole
          );
          this.fetchJobRoles(result.id);
        } else {
          throw new Error();
        }
      } catch (error) {
        const newState: Partial<ProjectsViewState['users']['invite']> = {
          createJobRoleStatus: Status.Error,
        };

        if (error instanceof ApiError) {
          if (error.status === HTTP_STATUS.BAD_REQUEST) {
            const duplicate = findErrorCode(error, ErrorCodes.JobRoleExists);

            if (duplicate) {
              newState.createJobRoleError = intl.get(
                'ERR_INVITE_USERS_JOB_ROLE_ALREADY_EXISTS'
              );
            }
          } else if (error.status !== HTTP_STATUS.FORBIDDEN) {
            appContext.setErrorToastText(
              intl.get('ERR_TOAST_PROJECT_USERS_LIST_ERRORS')
            );
          }
        } else {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_PROJECT_USERS_LIST_ERRORS')
          );
        }
        this.setInviteUsersStateAttribute(newState);
      }
    }
  };

  /**
   * Formats job roles response and sets to component state
   */
  clearFocusStatus = (): void => {
    this.setInviteUsersStateAttribute({ jobRolesStatus: Status.Loading });
    const { jobRoles } = this.state.users.invite;
    const newJobRoles = jobRoles.map(({ value, label }) => ({ value, label }));
    this.setInviteUsersStateAttribute({
      jobRoles: newJobRoles,
      jobRolesStatus: Status.Success,
    });
  };

  /**
   * Get job roles and set to component state
   */
  fetchJobRoles = async (createdRoleId?: string): Promise<void> => {
    const { appContext } = this.props;
    this.setInviteUsersStateAttribute({ jobRolesStatus: Status.Loading });
    try {
      const response = await SettingsApiInstance.GetJobRoles(this.source);
      const jobRoles = response.items.map(({ role, id }) => ({
        value: id,
        label: role,
        ...(createdRoleId && createdRoleId === id ? { focused: true } : {}),
      }));
      this.setInviteUsersStateAttribute(
        {
          jobRoles,
          jobRolesStatus: Status.Success,
          createJobRoleStatus: Status.Idle,
        },
        this.clearFocusStatus
      );
    } catch (error) {
      this.setInviteUsersStateAttribute({
        jobRolesStatus: Status.Error,
        createJobRoleStatus: Status.Idle,
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  };

  /**
   * Get permission levels for current organization
   */
  fetchPermissionLevels = async (): Promise<void> => {
    const { appContext } = this.props;
    const { organizationId } = this.state;
    this.setInviteUsersStateAttribute({
      permissionLevelsStatus: Status.Loading,
    });

    try {
      let orgId = organizationId;
      if (!organizationId) {
        const userInfo = await AuthApiInstance.GetUserInfo();
        orgId = userInfo.organizationId;
      }

      const response = await SettingsApiInstance.GetPermissionLevels(
        orgId,
        PermissionsViewMode.EDIT,
        this.source
      );

      const permissionLevels = response.items
        .filter(({ key }) => key !== DefaultPermissionLevel.NATIONAL_LEVEL)
        .map(({ key, title, description }) => ({
          label: title,
          value: key,
          description,
        }));
      this.setInviteUsersStateAttribute({
        permissionLevels,
        permissionLevelsStatus: Status.Success,
      });
    } catch (error) {
      this.setInviteUsersStateAttribute({
        permissionLevelsStatus: Status.Error,
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  };

  /**
   * Fetch supervisors for search query
   *
   * @param search Search string query
   */
  fetchSupervisors = async (search?: string): Promise<void> => {
    const { appContext } = this.props;
    this.setInviteUsersStateAttribute({ supervisorsStatus: Status.Loading });
    const noneOption = new SupervisorsViewModel();
    let supervisors: OptionType[] = [
      { label: noneOption.name, value: noneOption.id },
    ];

    try {
      const orgUsersResponse =
        await SettingsApiInstance.LookupOrganizationUsers(this.source, search);

      const supervisorsFormatted = orgUsersResponse.items.map(
        ({ userId, name }) => ({
          label: name,
          value: userId,
        })
      );

      supervisors = [...supervisors, ...supervisorsFormatted];

      if (search === undefined) {
        this.setInviteUsersStateAttribute({
          supervisors,
          supervisorsFiltered: supervisors,
          supervisorsStatus: Status.Success,
        });
      } else {
        this.setInviteUsersStateAttribute({
          supervisorsFiltered: supervisors,
          supervisorsStatus: Status.Success,
        });
      }
    } catch (error) {
      this.setInviteUsersStateAttribute({ supervisorsStatus: Status.Error });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  };

  /**
   * Fetch organization countries
   *
   * @param projectID Project ID
   */
  fetchOrganizationCountries = async (projectId: string): Promise<void> => {
    const { appContext, history, location } = this.props;
    this.setInviteUsersStateAttribute({
      countriesStatus: Status.Loading,
    });
    try {
      const countriesResponse =
        await ProjectsApiInstance.GetProjectOrgCountries(
          projectId,
          this.source
        );
      const countries = countriesResponse.items.map((role) => ({
        value: role.code,
        label: role.name,
        isFixed: role.isDefault ?? false,
      }));
      this.setInviteUsersStateAttribute({
        countries,
        countriesStatus: Status.Success,
      });
    } catch (error) {
      this.setInviteUsersStateAttribute({ countriesStatus: Status.Error });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          if (error.status === HTTP_STATUS.NOT_FOUND) {
            EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
              error,
              genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
              genericCallback: () => undefined,
              customCallback: () => undefined,
            } as CustomErrorArgs);
            /* If the project has been deleted concurrently, redirect to 
            the projects page and re-load the projects list */
            history.replace({
              pathname: ModulePaths.ProjectsPath,
              search: getGlobalFiltersQuery(location.search),
            });
            await this.loadProjectsData(false, true);
          } else {
            appContext.setErrorToastText(
              intl.get('ERR_PROJECTS_FETCH_COUNTRIES_FAILURE')
            );
          }
        }
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_PROJECTS_FETCH_COUNTRIES_FAILURE')
        );
      }
    }
  };

  /**
   * Create users by bulk
   *
   * @param values List of users
   */
  createUsers = async (
    values: InviteProjectUsersViewModel[]
  ): Promise<void> => {
    this.setInviteUsersStateAttribute({ createUsersStatus: Status.Loading });
    try {
      await SettingsApiInstance.CreateUsersByBulk(values, this.source);
      this.setInviteUsersStateAttribute({ createUsersStatus: Status.Success });
      sendEventGA(
        CategoryKeysGA.ProjectsUsersAdd,
        ActionKeysGA.InviteNewUsers,
        `Count: ${values.length}`
      );
    } catch (error) {
      this.setInviteUsersStateAttribute({ createUsersStatus: Status.Error });
      throw error;
    }
  };

  /**
   * Create facilitators by bulk
   *
   * @param values list of facilitators
   */
  createFacilitators = async (
    values: InviteProjectFacilitatorsViewModel[]
  ): Promise<void> => {
    this.setInviteFacilitatorsStateAttribute({
      createFacilitatorsStatus: Status.Loading,
    });
    try {
      await SettingsApiInstance.CreateFacilitatorsByBulk(values, this.source);
      this.setInviteFacilitatorsStateAttribute({
        createFacilitatorsStatus: Status.Success,
      });
      sendEventGA(
        CategoryKeysGA.ProjectsFacilitatorsAdd,
        ActionKeysGA.InviteNewFacilitators,
        `Count: ${values.length}`
      );
    } catch (error) {
      this.setInviteFacilitatorsStateAttribute({
        createFacilitatorsStatus: Status.Error,
      });
      throw error;
    }
  };

  /**
   * Fetch project user statistics
   */
  fetchUserProjectStats = async (): Promise<void> => {
    this.setProjectListStateAttribute({ projectStatsStatus: Status.Loading });
    try {
      const result = await ProjectsApiInstance.GetUserProjectStats(this.source);
      if (result.item) {
        const tooltipStatus = result.item.tooltipStatus.reduce(
          (accumulator, current) => ({
            ...accumulator,
            [current.tooltip]: current.status,
          }),
          {}
        );

        if (result) {
          this.setProjectListStateAttribute({
            projectStats: new ProjectStatsViewModel(
              tooltipStatus[TooltipType.AssignUser],
              tooltipStatus[TooltipType.AssignFacilitator],
              tooltipStatus[TooltipType.AssignGroup],
              result.item.userId,
              result.item.projectCount
            ),
          });
        }
        this.setProjectListStateAttribute({
          projectStatsStatus: Status.Success,
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setProjectListStateAttribute({ projectStatsStatus: Status.Error });
    }
  };

  /**
   * Get upload url and upload image to that url; if success returns
   * filename; else throws error
   *
   * @param image Image file
   * @returns {Promise<string>} Filename
   */
  uploadImage = async (items: Array<FileItem>): Promise<string> => {
    this.setInviteFacilitatorsStateAttribute({ imageStatus: Status.Loading });
    const getUrlBody: Array<ProjectsFileItem> = items.map(({ id, file }) => ({
      id,
      fileName: file.name,
    }));
    try {
      const { items: presignedItems } =
        await ProjectsApiInstance.GetFacilitatorImageUploadUrl(
          getUrlBody,
          this.source
        );

      const formatted = presignedItems.map((element) => ({
        url: element.url,
        headers: element.requiredHeaders,
        file: items.find((value) => value.id === element.id)?.file,
      }));

      await Promise.all(
        formatted.map(({ url, headers, file }) => {
          if (file)
            return ProjectsApiInstance.UploadFacilitatorImage(
              url,
              headers,
              file
            );
          return null;
        })
      );

      this.setInviteFacilitatorsStateAttribute({ imageStatus: Status.Success });
      return '';
    } catch (error) {
      this.setInviteFacilitatorsStateAttribute({ imageStatus: Status.Error });
      throw error;
    }
  };

  /**
   * clears the job role error status and error
   */
  clearJobRoleError = (): void => {
    this.setInviteUsersStateAttribute({
      createJobRoleError: null,
      createJobRoleStatus: Status.Idle,
    });
  };

  /**
   * Handles displaying groups guide
   *
   * @param open Whether to display groups guide
   */
  setShowGroupsGuide = (open: boolean): void =>
    this.setProjectListStateAttribute({ showGroupsGuide: open });

  /**
   * Handles removing a project from the global filters
   *
   * @param projectCode Project Code
   * @returns {GlobalFilters} Updated global filters object
   */
  handleRemoveProjectFromGlobalFilters = (
    projectCode: string
  ): GlobalFilters => {
    const { location } = this.props;
    // Get a copy of the currently set global filters
    const existingGlobalFilters = getGlobalFilters(
      getGlobalFiltersQuery(location.search)
    );
    // Filter out the deleted project if it is set as a global filter
    const updatedProjectFilters = existingGlobalFilters.projects.filter(
      (project: string) => projectCode !== project
    );
    // Update the current global filter object
    return {
      ...existingGlobalFilters,
      projects: updatedProjectFilters,
    };
  };

  /**
   * Handles creating a new project
   *
   * @param details Project data
   */
  handleCreateProject = (
    details: Partial<ProjectDetail>
  ): Promise<ObjectResponse<ProjectCreated>> =>
    ProjectsApiInstance.CreateProject(details, this.source);

  /**
   * Handles editing a project
   *
   * @param details Project data
   */
  handleEditProject = async (
    details: Partial<ProjectDetail>
  ): Promise<SuccessResponse> =>
    ProjectsApiInstance.UpdateProject(details, this.source);

  /**
   * Handles deleting a project
   *
   * @param projectCode Project Code
   * @param projectId Project ID
   */
  handleDeleteProject = async (
    projectCode: string,
    projectId?: string
  ): Promise<void> => {
    const { history, location, appContext } = this.props;
    const updatedGlobalFilters =
      this.handleRemoveProjectFromGlobalFilters(projectCode);
    try {
      if (projectId) {
        this.setState({ status: Status.Loading });
        await ProjectsApiInstance.DeleteProject(projectId, this.source);
        this.setState({ status: Status.Success });
        appContext.setSuccessToastText(
          intl.get('LBL_PROJECTS_EDIT_DELETE_SUCCESS')
        );
      } else {
        throw new Error(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
      this.setState({ status: Status.Error });
    } finally {
      // Navigate to main projects page and set updated global filters
      history.replace({
        pathname: ModulePaths.ProjectsPath,
        search: getGlobalFiltersSearch(updatedGlobalFilters, location),
      });
      await this.loadProjectsData(false, true);
    }
  };

  /**
   * Validate if a user can be assigned to a project based on country access
   *
   * @param user User object
   * @param projectId Project ID
   */
  handleFetchUserProjectCompatibility = async (
    user: ProjectUsersViewModel,
    projectId: string
  ): Promise<void> => {
    const { appContext } = this.props;
    this.setAddUsersStateAttribute({
      compatibilityStatus: Status.Loading,
      selectedUser: user,
    });
    try {
      const response = await ProjectsApiInstance.GetUserProjectCompatibility(
        user.userId,
        [projectId],
        this.source
      );
      if (response.item) {
        const result = response.item;
        if (result.missingCountries.length > 0) {
          const countries = result.missingCountries.map(({ name }) => name);
          this.setAddUsersStateAttribute({
            userUnassignedCountries: countries,
            displayCDAWarningPrompt: true,
          });
        } else {
          this.setAddUsersStateAttribute({ canAddUserToList: true });
        }
      }
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    } finally {
      this.setAddUsersStateAttribute({ compatibilityStatus: Status.Idle });
    }
  };

  /**
   * Add organization users to project
   *
   * @param users List of users
   */
  handleAddExistingUsers = async (
    users: Array<ProjectUsersViewModel>
  ): Promise<void> => {
    const { appContext, history, location } = this.props;
    this.setAddUsersStateAttribute({ addUsersStatus: Status.Loading });
    const {
      projectInfo: { data },
    } = this.state;
    const userIds = users.map(({ userId }) => userId);
    try {
      const projectId = data.projectId ?? '';
      await ProjectsApiInstance.AddProjectUsers(
        projectId,
        userIds,
        this.source
      );
      this.setAddUsersStateAttribute({ addUsersStatus: Status.Success });

      sendEventGA(
        CategoryKeysGA.ProjectsUsersAdd,
        ActionKeysGA.AddExistingUsers
      );
      this.toggleAddUsersModal();
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          const formattedError = this.handleUserErrors(error);
          this.setAddUsersStateAttribute({
            addUsersStatus: Status.Error,
            error: formattedError,
          });
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
          if (error.status === HTTP_STATUS.NOT_FOUND) {
            /* If the project has been deleted concurrently, redirect to 
            the projects page and re-load the projects list */
            history.replace({
              pathname: ModulePaths.ProjectsPath,
              search: getGlobalFiltersQuery(location.search),
            });
            await this.loadProjectsData(false, true);
          }
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  };

  /**
   * Add organization facilitators to project
   *
   * @param users List of facilitators
   */
  handleAddExistingFacilitators = async (
    users: Array<ProjectFacilitatorViewModel>
  ): Promise<void> => {
    const { appContext, history, location } = this.props;
    this.setAddFacilitatorsStateAttribute({
      addFacilitatorsStatus: Status.Loading,
    });
    const {
      projectInfo: { data },
    } = this.state;
    const facilitatorIds = users.map(({ id }) => id);
    try {
      const projectId = data.projectId ?? '';
      await ProjectsApiInstance.AddProjectFacilitators(
        projectId,
        facilitatorIds,
        this.source
      );
      this.setAddFacilitatorsStateAttribute({
        addFacilitatorsStatus: Status.Success,
      });
      sendEventGA(
        CategoryKeysGA.ProjectsFacilitatorsAdd,
        ActionKeysGA.AddExistingFacilitators
      );
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          const formattedError = this.handleFacilitatorErrors(error);
          this.setAddFacilitatorsStateAttribute({
            addFacilitatorsStatus: Status.Error,
            error: formattedError,
          });
          EventBus.getInstance().dispatch(EventKey.HandleCustomError, {
            error,
            genericErrorString: 'ERR_TOAST_GENERIC_ERROR',
            genericCallback: () => undefined,
            customCallback: () => undefined,
          } as CustomErrorArgs);
          if (error.status === HTTP_STATUS.NOT_FOUND) {
            /* If the project has been deleted concurrently, redirect to 
            the projects page and re-load the projects list */
            history.replace({
              pathname: ModulePaths.ProjectsPath,
              search: getGlobalFiltersQuery(location.search),
            });
            await this.loadProjectsData(false, true);
          }
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
      throw error;
    }
  };

  /**
   * Handles click interactions on the country data access warning prompt
   *
   * @param shouldAddUser Boolean flag specifying user add status
   */
  handleCDAPromptAction = (shouldAddUser: boolean): void => {
    if (shouldAddUser) {
      this.setAddUsersStateAttribute({ canAddUserToList: true });
    }
    this.setAddUsersStateAttribute({
      displayCDAWarningPrompt: false,
      userUnassignedCountries: [],
    });
  };

  /**
   * Handles formatting API errors for adding users
   *
   * @param error The API error object
   * @returns {ErrorStatus} Formatted error object
   */
  handleUserErrors = (error): ErrorStatus =>
    getErrorStatus(error, intl.get('ERR_PROJECT_USERS_ADD_FAILURE'));

  /**
   * Handles formatting API errors for adding facilitators
   *
   * @param error The API error object
   * @returns {ErrorStatus} Formatted error object
   */
  handleFacilitatorErrors = (error): ErrorStatus =>
    getErrorStatus(error, intl.get('ERR_PROJECT_FACILITATORS_ADD_FAILURE'));

  /**
   * Refresh projects list data
   *
   * @param silent Whether to update list silently
   * @param resetScroll Whether to reset scroll to initial position
   */
  handleRefreshProjects = async (
    silent?: boolean,
    resetScroll?: boolean
  ): Promise<void> => {
    await this.fetchUserProjectStats();
    await this.loadProjectsData(silent, resetScroll);
  };

  /**
   * Handles route change events
   */
  handleRouteChange = (): void => {
    const { match, type } = this.props;
    this.setProjectInfoStateAttribute({
      codeError: { error: null, message: '' },
      loadError: null,
    });
    this.setAddUsersStateAttribute({
      error: null,
      addUsersStatus: Status.Idle,
    });
    this.setAddFacilitatorsStateAttribute({
      error: null,
      addFacilitatorsStatus: Status.Idle,
    });

    if (type && type !== ProjectViewMode.NONE) {
      const projectId = match?.params.projectId ?? '';
      if (type === ProjectViewMode.EDIT_PROJECT) {
        this.setProjectInfoStateAttribute({
          data: new ProjectInfoFormValues(),
          showModal: true,
        });
        this.loadSelectedProjectData(projectId);
      }
      if (type === ProjectViewMode.CREATE_PROJECT) {
        this.setProjectInfoStateAttribute({
          data: new ProjectInfoFormValues(),
          showModal: true,
        });
        this.getProjectId();
      }
      if (type === ProjectViewMode.ADD_USERS) {
        this.setAddUsersStateAttribute({
          data: Array<ProjectUsersViewModel>(),
        });
        this.loadSelectedProjectData(projectId);
        this.loadSelectedProjectExistingUsers(projectId);
        this.fetchOrganizationCountries(projectId);
        this.setUsersStateAttribute({ showModal: true });
      }
      if (type === ProjectViewMode.ADD_FACILITATORS) {
        this.setAddFacilitatorsStateAttribute({
          data: Array<ProjectFacilitatorViewModel>(),
        });
        this.loadSelectedProjectData(projectId);
        this.loadSelectedProjectExistingFacilitators(projectId);
        this.setFacilitatorsStateAttribute({ showModal: true });
      }
    } else {
      this.setProjectInfoStateAttribute({ showModal: false });
      this.setUsersStateAttribute({ showModal: false });
      this.setFacilitatorsStateAttribute({ showModal: false });
    }
  };

  /**
   * Resets state flag for adding a user to the existing user list
   */
  onAddUserToListSuccess = (): void =>
    this.setAddUsersStateAttribute({
      canAddUserToList: false,
      selectedUser: null,
    });

  render(): JSX.Element {
    const { status, projectInfo, users, projectList, facilitators } =
      this.state;
    const { type, appContext } = this.props;

    return (
      <div className="content-container pt-0 pb-0">
        <ProjectList
          ref={this.listRef}
          list={projectList.list}
          page={projectList.pageIndex}
          pageSize={projectList.pageSize}
          pageCount={projectList.pageCount}
          projectsStatus={projectList.projectsStatus}
          projectStats={projectList.projectStats}
          projectStatsStatus={projectList.projectStatsStatus}
          scrolling={projectList.scrolling}
          guideProjectId={projectList.firstWarningItem}
          showGroupsGuide={projectList.showGroupsGuide}
          appContext={appContext}
          setShowGroupsGuide={this.setShowGroupsGuide}
          loadNextPage={this.loadNextPage}
        />
        <ProjectInfo
          type={type}
          newProjectCode={projectInfo.newCode}
          data={projectInfo.data}
          createEditStatus={status}
          idError={projectInfo.codeError}
          loadError={projectInfo.loadError}
          showModal={projectInfo.showModal}
          toggleModal={this.toggleProjectInfoModal}
          handleCreateProject={this.handleCreateProject}
          handleEditProject={this.handleEditProject}
          handleDeleteProject={this.handleDeleteProject}
          onCreateSuccess={this.handleRefreshProjects}
          onEdit={this.loadProjectsData}
          {...this.props}
        />
        <AddUsersModal
          projectId={projectInfo.data.projectId ?? ''}
          projectName={projectInfo.data.name}
          existingUsers={users.add.data}
          addExistingStatus={users.add.addUsersStatus}
          addExistingError={users.add.error}
          selectedUser={users.add.selectedUser}
          userUnassignedCountries={users.add.userUnassignedCountries}
          displayCDAWarningPrompt={users.add.displayCDAWarningPrompt}
          canAddUserToList={users.add.canAddUserToList}
          userCompatibilityStatus={users.add.compatibilityStatus}
          isOpen={users.showModal}
          createUsersStatus={users.invite.createUsersStatus}
          jobRoles={users.invite.jobRoles}
          jobRolesStatus={users.invite.jobRolesStatus}
          supervisors={users.invite.supervisors}
          supervisorsFiltered={users.invite.supervisorsFiltered}
          supervisorsStatus={users.invite.supervisorsStatus}
          countries={users.invite.countries}
          countriesStatus={users.invite.countriesStatus}
          permissionLevels={users.invite.permissionLevels}
          permissionLevelsStatus={users.invite.permissionLevelsStatus}
          createJobRoleStatus={users.invite.createJobRoleStatus}
          createJobRoleError={users.invite.createJobRoleError}
          appContext={appContext}
          // prettier-ignore
          onFetchUserProjectCompatibility={this.handleFetchUserProjectCompatibility}
          onCDAWarningPromptAction={this.handleCDAPromptAction}
          onAddUserToListSuccess={this.onAddUserToListSuccess}
          fetchOrgUsers={this.fetchOrgUsers}
          onFetchSupervisors={this.fetchSupervisors}
          onFetchPermissionLevels={this.fetchPermissionLevels}
          onCreateJobRole={this.createJobRole}
          onToggle={this.toggleAddUsersModal}
          onAddExistingUsers={this.handleAddExistingUsers}
          onAddInviteSuccess={this.loadProjectsData}
          onCreateUsers={this.createUsers}
          onSuccess={this.handleRefreshProjects}
          clearJobRoleError={this.clearJobRoleError}
        />
        <AddFacilitatorsModal
          projectId={projectInfo.data.projectId ?? ''}
          projectName={projectInfo.data.name}
          existingFacilitators={facilitators.add.data}
          addExistingStatus={facilitators.add.addFacilitatorsStatus}
          addExistingError={facilitators.add.error}
          isOpen={facilitators.showModal}
          createFacilitatorStatus={facilitators.invite.createFacilitatorsStatus}
          imageStatus={facilitators.invite.imageStatus}
          supervisors={users.invite.supervisors}
          supervisorsFiltered={users.invite.supervisorsFiltered}
          supervisorsStatus={users.invite.supervisorsStatus}
          appContext={appContext}
          onUploadImage={this.uploadImage}
          fetchOrgFacilitators={this.fetchOrgFacilitators}
          onFetchSupervisors={this.fetchSupervisors}
          onFetchPermissionLevels={this.fetchPermissionLevels}
          onToggle={this.toggleAddFacilitatorsModal}
          onAddExistingFacilitators={this.handleAddExistingFacilitators}
          onCreateFacilitators={this.createFacilitators}
          onSuccess={this.handleRefreshProjects}
        />
      </div>
    );
  }
}

export default ProjectsView;
