class MonitoredPartner {
  id: string;

  name: string;

  partnerId: string;

  totalGroupCount: number;

  constructor() {
    this.id = '';
    this.name = '';
    this.partnerId = '';
    this.totalGroupCount = 0;
  }
}

export default MonitoredPartner;
