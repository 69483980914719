/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import {
  Align,
  ListOnItemsRenderedProps,
  ListOnScrollProps,
  ListProps,
  VariableSizeList as List,
} from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Col, Row } from 'reactstrap';

import mergeRefs from 'helpers/mergeRefs';
import ImageComponent from 'shared/components/image/ImageComponent';
import Status from 'shared/enums/Status';
import EmptyStateIllustration from 'shared/static/img/supervisees-empty-state.svg';
import EmptyStateThumb from 'shared/static/img/thumbs/supervisees-empty-state.png';

import UserProfilePartnerItem from './user-profile-partner-item/UserProfilePartnerItem';
import styles from './userProfilePartners.module.scss';
import UserProfilePartnersHandle from './UserProfilePartnersHandle';
import UserProfilePartnersProps from './UserProfilePartnersProps';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line react/display-name
const innerElementType = forwardRef(({ style, ...rest }, ref) => (
  <div
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height)}px`,
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
));

const MIN_CURRENT_PARTNERS_COUNT = 3;
const LIST_HEIGHT = 300;
const ELEMENT_HEIGHT = 100;

const UserProfilePartners: React.ForwardRefRenderFunction<
  UserProfilePartnersHandle,
  UserProfilePartnersProps
> = (props: UserProfilePartnersProps, ref) => {
  const {
    data: currentPartnersData,
    status,
    currentPartnersStatus,
    page,
    pageCount,
    pageSize,
    loadNextPage,
    scrolling,
    onViewData,
  } = props;

  const infiniteLoaderRef = useRef<InfiniteLoader>();
  const listRef = useRef<List>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const listOuterRef = useRef<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const listInnerRef = useRef<any>(null);

  const [listScrollOffset, setListScrollOffset] = useState<number>(0);

  const showEmpty =
    currentPartnersStatus === Status.Success && currentPartnersData.length <= 0;

  const maxHeight =
    (listInnerRef.current &&
      listInnerRef.current.style.height.replace('px', '')) ||
    900;
  const minHeight = 0;
  const pageOffset = 30;

  // pageCount - 1 is max page number; since pages start from 0
  const hasNextPage = page < pageCount - 1;

  const firstTimeLoading =
    currentPartnersStatus === Status.Loading && currentPartnersData.length <= 0;

  const itemCount = hasNextPage
    ? Number(currentPartnersData.length) + 1
    : currentPartnersData.length;

  const [pageUp, pageDown, arrowUp, arrowDown] = [33, 34, 38, 40];

  const scrollKeys = {
    [pageUp]: Math.max(minHeight, listScrollOffset - pageOffset),
    [pageDown]: Math.min(listScrollOffset + pageOffset, maxHeight),
    [arrowUp]: Math.max(minHeight, listScrollOffset - pageOffset),
    [arrowDown]: Math.min(listScrollOffset + pageOffset, maxHeight),
  };

  const isItemLoaded = useCallback(
    (index: number): boolean =>
      !hasNextPage || index < currentPartnersData.length,
    [hasNextPage, currentPartnersData.length]
  );

  const handleItemsRendered =
    (onItemsRenderedIL: ListProps['onItemsRendered']) =>
    (listItemProps: ListOnItemsRenderedProps): void => {
      if (onItemsRenderedIL) {
        onItemsRenderedIL(listItemProps);
      }
    };

  const loadMoreItems = (
    startIndex: number,
    stopIndex: number
  ): Promise<void> => {
    if (currentPartnersStatus === Status.Loading) {
      return Promise.resolve();
    }
    return loadNextPage(startIndex, stopIndex);
  };

  /**
   * Handles onScroll event for list
   *
   * @param {ListOnScrollProps}
   */
  const handleListScroll = ({ scrollOffset }: ListOnScrollProps): void =>
    setListScrollOffset(scrollOffset);

  useLayoutEffect(() => {
    if (listOuterRef && listOuterRef.current) {
      listOuterRef.current.scrollTo({
        left: 0,
        top: listScrollOffset,
        behavior: 'auto',
      });
    }
  });

  useImperativeHandle(ref, () => ({
    resetloadMoreItemsCache(autoReload?: boolean): void {
      if (infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache(autoReload);
      }
    },
    scrollToItem(index: number, align?: Align): void {
      if (listRef.current) {
        listRef.current.scrollToItem(index, align);
      }
    },
  }));

  /**
   * Handles key down events on the list wrapper for scrolling purposes
   *
   * @param event Key down event
   */
  const handleKeyDown = ({ keyCode }: React.KeyboardEvent): void => {
    if (scrollKeys[keyCode]) {
      setListScrollOffset(scrollKeys[keyCode]);
    }
  };

  /**
   * Renders the empty state for the current partners list
   *
   * @returns {ReactNode} ReactNode containing the empty state icon and message
   */
  const renderEmptyState = (): ReactNode =>
    status === Status.Loading ? (
      <div />
    ) : (
      <div className={`d-flex justify-content-center ${styles.empty}`}>
        <Row>
          <Col xs="12" className="text-center p-4">
            <div className="text-16-semibold">
              {intl.get('LBL_USER_PROFILE_CURRENT_PARTNERS_EMPTY_TITLE')}
            </div>
          </Col>
          <Col xs="12" className="text-center">
            <ImageComponent
              loading="eager"
              src={EmptyStateIllustration}
              alt="No Current Partners"
              thumb={EmptyStateThumb}
            />
          </Col>
          <Col xs="12" className="text-center p-4">
            <p className="text-12-medium text-gray">
              {intl.getHTML('LBL_USER_PROFILE_CURRENT_PARTNERS_EMPTY_BODY')}
            </p>
          </Col>
        </Row>
      </div>
    );

  return (
    <BlockUi
      tag="div"
      blocking={status === Status.Loading || firstTimeLoading || scrolling}
      className="row dashboard-row"
    >
      <Col>
        <div className="content-box lower-margins">
          <div className="content-box-title pb-4 pt-2">
            <h3 className="main-title text-uppercase">
              {intl.get('LBL_USER_PROFILE_CURRENT_PARTNERS_HEADER')}
            </h3>
          </div>
          {showEmpty ? (
            renderEmptyState()
          ) : (
            <Col xs="12" className="mt-2 p-0">
              <div className={styles.scroll}>
                <InfiniteLoader
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  ref={infiniteLoaderRef}
                  threshold={pageSize}
                  minimumBatchSize={pageSize}
                  isItemLoaded={isItemLoaded}
                  loadMoreItems={loadMoreItems}
                  itemCount={itemCount}
                >
                  {({ onItemsRendered, ref: innerRef }): JSX.Element => (
                    <div role="list" tabIndex={0} onKeyDown={handleKeyDown}>
                      <List
                        ref={mergeRefs([innerRef, listRef])}
                        innerRef={listInnerRef}
                        outerRef={listOuterRef}
                        className="List"
                        height={LIST_HEIGHT}
                        width="100%"
                        itemCount={itemCount}
                        innerElementType={innerElementType}
                        useIsScrolling
                        itemSize={(): number => ELEMENT_HEIGHT}
                        itemData={{
                          list: currentPartnersData,
                          isItemLoaded,
                          onViewData,
                        }}
                        itemKey={(index, data): string =>
                          data?.[index]?.id ?? index
                        }
                        onItemsRendered={handleItemsRendered(onItemsRendered)}
                        onScroll={handleListScroll}
                      >
                        {UserProfilePartnerItem}
                      </List>
                    </div>
                  )}
                </InfiniteLoader>
                {currentPartnersData.length > MIN_CURRENT_PARTNERS_COUNT ? (
                  <span className={styles.track} />
                ) : (
                  ''
                )}
              </div>
            </Col>
          )}
        </div>
      </Col>
    </BlockUi>
  );
};

export default forwardRef(UserProfilePartners);
