/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
import { Component, createRef } from 'react';

import axios from 'axios';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { ObjectSchema } from 'yup';

import AuthApiInstance from 'api/auth/AuthApi';
import ApiError from 'api/common/types/ApiError';
import ProjectsApiInstance from 'api/projects/ProjectsApi';
import ProjectsPagination from 'api/projects/types/ProjectsPagination';
import SettingsApiInstance from 'api/settings/SettingsApi';
import UserProfileCurrentPartnersPagination from 'api/settings/types/users/UserProfileCurrentPartnersPagination';
import ErrorCodes from 'constants/ErrorCodes';
import Constraints from 'constants/forms/Constraints';
import ActionKeysGA from 'constants/ga/ActionKeysGA';
import CategoryKeysGA from 'constants/ga/CategoryKeysGA';
import ModulePaths from 'constants/ModulePaths';
import ResourceKeys from 'constants/permissions/ResourceKeys';
import StorageKeys from 'constants/StorageKeys';
import {
  findErrorCode,
  getFirstError,
  getLocalizedErrorString,
} from 'helpers/ErrorFormat';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import { sendEventGA } from 'helpers/GoogleAnalyticsHelper';
import PermissionUtil from 'helpers/PermissionUtil';
import setPageTitle from 'helpers/setPageTitle';
import AuthStorageService from 'services/storage-services/AuthStorageService';
import ScrollToTopOnMount from 'shared/components/scroll-to-top-on-mount/ScrollToTopOnMount';
import DefaultPermissionLevel from 'shared/enums/DefaultPermissionLevel';
import EventKey from 'shared/enums/EventKey';
import HTTP_STATUS from 'shared/enums/HttpStatus';
import PermissionsViewMode from 'shared/enums/PermissionsViewMode';
import Status from 'shared/enums/Status';
import UserStatus from 'shared/enums/UserStatus';
import { EventBus } from 'shared/events/EventBus';
import PermissionModal from 'shared/modules/permissions/components/permissions-modal/PermissionModal';
import UserCurrentProject from 'shared/modules/users/components/user-projects-pane/UserCurrentProject';

import UserProfileSuperviseesPagination from '../../../../../api/settings/types/users/UserProfileSuperviseesPagination';
import UserProfileUpdateRequestBody from '../../../../../api/settings/types/users/UserProfileUpdateRequestBody';
import { UserProfilePermissionArgs } from '../../../../types/eventTypes';
import UserProfileDetails, {
  ImageFile,
} from '../../components/user-profile-left-panel/UserProfileDetails';
import UserProfileLeftPanel from '../../components/user-profile-left-panel/UserProfileLeftPanel';
import {
  UserProfileLeftPanelFormValues,
  UserProfileFields,
} from '../../components/user-profile-left-panel/UserProfileLeftPanelProps';
import UserProfileValidations from '../../components/user-profile-left-panel/UserProfileValidations';
import MonitoredPartner from '../../components/user-profile-partners/MonitoredPartner';
import UserProfilePartners from '../../components/user-profile-partners/UserProfilePartners';
import UserProfileSupervisee from '../../components/user-profile-supervisees/UserProfileSupervisee';
import UserProfileSupervisees from '../../components/user-profile-supervisees/UserProfileSupervisees';
import UserProfileSuperviseesHandle from '../../components/user-profile-supervisees/UserProfileSuperviseesHandle';
import UsersProjectsPane from '../../components/user-projects-pane/UsersProjectsPane';
import UserProfileViewProps from './UserProfileViewProps';
import UserProfileViewState, {
  UserProfileRestrictions,
} from './UserProfileViewState';

export class UserProfileView extends Component<
  UserProfileViewProps,
  UserProfileViewState
> {
  constructor(props: UserProfileViewProps) {
    super(props);
    const orgId = AuthStorageService.GetItem<string>(
      StorageKeys.OrganizationId
    );

    this.formikRef = createRef<FormikProps<UserProfileLeftPanelFormValues>>();
    this.deactivateOkayButtonRef = createRef();
    this.unassignOkayButtonRef = createRef();
    this.initialValues = {
      [UserProfileFields.NAME]: '',
      [UserProfileFields.IMAGE]: null,
      [UserProfileFields.JOB_ROLE]: '',
      [UserProfileFields.PERMISSION_LEVEL]: '',
      [UserProfileFields.COUNTRY_DATA_ACCESS]: [],
      [UserProfileFields.EMAIL]: '',
      [UserProfileFields.PHONE_NUMBER]: '',
    };

    const { appContext } = this.props;
    const { permissionsData, gettingStartedStates } = appContext;
    const { claims } = permissionsData;

    const canAddPermissions = PermissionUtil.Can(
      claims,
      ResourceKeys.SettingsPermissionsAddNewLevel
    );
    const canEditPermissions = PermissionUtil.Can(
      claims,
      ResourceKeys.SettingsPermissionsItemEdit
    );
    const canDeactivateUsers = PermissionUtil.Can(
      claims,
      ResourceKeys.UserProfileDeactivate
    );
    const permissionsFirstTime = gettingStartedStates.data
      ? !gettingStartedStates.data.permissionCompleted
      : false;

    this.state = {
      organizationId: orgId,
      status: Status.Idle,
      leftPanelData: {
        id: '',
        firstName: '',
        lastName: '',
        number: '',
        image: null,
        status: UserStatus.Active,
        organization: '',
        jobRole: {
          id: '',
          role: '',
        },
        permissionLevel: '',
        countryDataAccess: [],
        email: '',
        phoneNumber: '',
      },
      supervisees: {
        data: [],
        status: Status.Idle,
        superviseesUpdateSilent: false,
        scrolling: false,
        pageCount: 0,
        pageSize: Constraints.LazyLoadingPageSize,
        pageIndex: 0,
        total: 0,
        showUnassignModal: false,
        toBeUnassignedId: undefined,
      },
      currentProjects: {
        data: [],
        status: Status.Idle,
        projectsUpdateSilent: false,
        scrolling: false,
        pageCount: 0,
        pageSize: Constraints.LazyLoadingPageSize,
        pageIndex: 0,
        total: 0,
        removingProjectId: '',
        showRemoveProjectModal: false,
        showAddProjectModal: false,
      },
      currentPartners: {
        data: [],
        status: Status.Idle,
        partnersUpdateSilent: false,
        scrolling: false,
        pageCount: 0,
        pageSize: Constraints.LazyLoadingPageSize,
        pageIndex: 0,
        total: 0,
      },
      restrictions: {
        canEditProfile: false,
        isOwnProfile: false,
        isOwner: false,
      },
      createJobRoleStatus: Status.Idle,
      createJobRoleError: null,
      jobRoles: [],
      jobRolesStatus: Status.Idle,
      countries: [],
      countriesStatus: Status.Idle,
      permissionLevels: [],
      permissionLevelsStatus: Status.Idle,
      isPermissionsModalOpen: false,
      canAddPermissions,
      canEditPermissions,
      canDeactivateUsers,
      permissionsFirstTime,
      showDeactivateModal: false,
      toBeDeactivatedId: undefined,
      projectAddCompatibility: {
        compatibilityStatus: Status.Idle,
        userUnassignedCountries: [],
        displayCDAWarningPrompt: false,
        projectIds: [],
      },
    };
  }

  componentDidMount(): void {
    setPageTitle('User Profile');

    const { appContext } = this.props;
    appContext.hideErrorToast();
    ReactTooltip.rebuild();
    this.fetchUserData().then(() => {
      if (appContext.isNationalLevelUser) {
        this.loadCurrentPartnersData();
      } else {
        this.loadProjectsData();
        this.loadSuperviseesData();
      }
    });
    this.fetchJobRoles();
    this.fetchPermissionLevels();
    this.fetchOrganizationCountries();
  }

  componentDidUpdate(
    prevProps: UserProfileViewProps,
    prevState: UserProfileViewState
  ): void {
    const { appContext, match } = this.props;
    const { setUserProfileSetupCallback } = appContext;
    const claims = get(this.props, 'permissionsData.claims');
    setUserProfileSetupCallback(this.saveCallback);

    if (prevProps.match && prevProps.match.params && match && match.params) {
      if (prevProps.match.params.userId !== match.params.userId) {
        this.refreshSession();
      }
    }
    if (!isEqual(get(prevProps, 'permissionsData.claims'), claims)) {
      const canAddPermissions = PermissionUtil.Can(
        claims,
        ResourceKeys.SettingsPermissionsAddNewLevel
      );
      const canEditPermissions = PermissionUtil.Can(
        claims,
        ResourceKeys.SettingsPermissionsItemEdit
      );
      this.setStateAttribute({ canAddPermissions, canEditPermissions });
    }
    ReactTooltip.rebuild();
  }

  componentWillUnmount(): void {
    const { appContext } = this.props;
    appContext.setUserProfileSetupInProgress(false);
    this.source.cancel();
  }

  CancelToken = axios.CancelToken;

  source = this.CancelToken.source();

  formikRef: React.RefObject<FormikProps<UserProfileLeftPanelFormValues>>;

  deactivateOkayButtonRef: React.RefObject<HTMLButtonElement>;

  unassignOkayButtonRef: React.RefObject<HTMLButtonElement>;

  listRef = createRef<UserProfileSuperviseesHandle>();

  initialValues: UserProfileLeftPanelFormValues;

  /**
   * Set/Update component state in the root slice
   *
   * @param updateState Updated state as an object
   */
  setStateAttribute = (
    updateState: Partial<UserProfileViewState>,
    callback?: () => void
  ): void =>
    this.setState(
      (state) => ({
        ...state,
        ...updateState,
      }),
      callback
    );

  /**
   * Set/Update component state in the left panel data slice
   *
   * @param updateState Updated state as an object
   */
  setLeftPanelDataStateAttribute = (
    updateState: Partial<UserProfileViewState['leftPanelData']>
  ): void =>
    this.setState((state) => ({
      ...state,
      leftPanelData: {
        ...state.leftPanelData,
        ...updateState,
      },
    }));

  /**
   * Set/Update component state in the supervisees slice
   *
   * @param updateState Updated state as an object
   */
  setSuperviseesStateAttribute = (
    updateState: Partial<UserProfileViewState['supervisees']>
  ): void =>
    this.setState((state) => ({
      ...state,
      supervisees: {
        ...state.supervisees,
        ...updateState,
      },
    }));

  /**
   * Set/Update component state in the currentProjects slice
   *
   * @param updateState Updated state as an object
   */
  setCurrentProjectsStateAttribute = (
    updateState: Partial<UserProfileViewState['currentProjects']>
  ): void =>
    this.setState((state) => ({
      ...state,
      currentProjects: {
        ...state.currentProjects,
        ...updateState,
      },
    }));

  /**
   * Set/Update component state in the currentPartners slice
   *
   * @param updateState Updated state as an object
   */
  setCurrentPartnersStateAttribute = (
    updateState: Partial<UserProfileViewState['currentPartners']>
  ): void =>
    this.setState((state) => ({
      ...state,
      currentPartners: {
        ...state.currentPartners,
        ...updateState,
      },
    }));

  /**
   * Set/Update component state in the project add compatibility slice
   *
   * @param updateState Updated state as an object
   */
  setProjectAddCompatibilityStateAttribute = (
    updateState: Partial<UserProfileViewState['projectAddCompatibility']>
  ): void =>
    this.setState((state) => ({
      ...state,
      projectAddCompatibility: {
        ...state.projectAddCompatibility,
        ...updateState,
      },
    }));

  /**
   * Set/Update component state in the restrictions slice
   *
   * @param updateState Updated state as an object
   */
  setRestrictionsStateAttribute = (
    updateState: Partial<UserProfileViewState['restrictions']>
  ): void =>
    this.setState((state) => ({
      ...state,
      restrictions: {
        ...state.restrictions,
        ...updateState,
      },
    }));

  /**
   * Fetch user profile data
   */
  fetchUserData = async (updateStatusOnly?: boolean): Promise<void> => {
    const { appContext, location, history, match } = this.props;
    const { setErrorToastText } = appContext;
    this.setStateAttribute({ status: Status.Loading });
    try {
      if (match && match.params) {
        const { userId } = match.params;
        const response = await SettingsApiInstance.GetUserProfileDetails(
          userId,
          this.source
        );
        if (response.item) {
          const user = response.item;
          if (updateStatusOnly) {
            this.setLeftPanelDataStateAttribute({
              status: user.status,
            });
          } else {
            const userData: UserProfileDetails = {
              id: user.userId,
              number: user.userNumber,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.userName,
              image: user.imageURL,
              status: user.status,
              organization: user.organizationName,
              phoneNumber: user.phoneNumber,
              jobRole: user.jobRole,
              permissionLevel: user.permission,
              countryDataAccess: user.countryAccess.map((cda) => ({
                value: cda.code,
                label: cda.name,
                isDisabled: false,
                isFixed: false,
              })),
            };
            this.setLeftPanelDataStateAttribute(userData);
            this.setInitialLeftPanelFormValues(userData);
          }
          const restrictions: UserProfileRestrictions = {
            canEditProfile: user.validations.canEdit,
            isOwnProfile: user.validations.isOwnProfile,
            isOwner: user.validations.isOwner,
          };
          this.setRestrictionsStateAttribute(restrictions);

          EventBus.getInstance().dispatch(
            EventKey.ShareUserProfilePermissions,
            restrictions as UserProfilePermissionArgs
          );

          this.setStateAttribute({ status: Status.Success });
        } else {
          throw new Error();
        }
      } else {
        throw new Error(intl.get('ERR_TOAST_USER_PROFILE_ID_ERROR'));
      }
    } catch (error) {
      this.setStateAttribute({ status: Status.Error });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          const errorCode = getFirstError(error);
          if (errorCode === ErrorCodes.Generic) {
            setErrorToastText(
              intl.get('ERR_TOAST_USER_PROFILE_DATA_FETCH_ERROR')
            );
          } else {
            if (errorCode === ErrorCodes.UnauthorizedUserProfile) {
              history.replace({
                pathname: `${ModulePaths.SettingsPath}${ModulePaths.SettingsUsersPath}`,
                search: getGlobalFiltersQuery(location.search),
              });
            }
            setErrorToastText(intl.get(getLocalizedErrorString(errorCode)));
          }
        }
      } else {
        setErrorToastText(intl.get('ERR_TOAST_USER_PROFILE_DATA_FETCH_ERROR'));
      }
    }
  };

  /**
   * Sets the initial values for the left panel form
   *
   * @param user User data
   */
  setInitialLeftPanelFormValues = (user: UserProfileDetails): void => {
    let imageFile: ImageFile | null;
    if (user.image === null) {
      imageFile = null;
    } else {
      const image = new Image();
      image.src = user.image ?? '';
      imageFile = {
        image,
        ...new File([''], ''),
        size: Constraints.SampleImageSize,
        type: Constraints.SampleImageFormat,
      };
    }

    let fullName = user.firstName;
    if (user.firstName && user.lastName) {
      fullName = `${String(user.firstName)} ${String(user.lastName)}`;
    } else if (user.lastName) {
      fullName = user.lastName;
    }

    this.initialValues = {
      [UserProfileFields.NAME]: fullName,
      [UserProfileFields.IMAGE]: imageFile,
      [UserProfileFields.JOB_ROLE]: user.jobRole.id,
      [UserProfileFields.PERMISSION_LEVEL]: user.permissionLevel,
      [UserProfileFields.COUNTRY_DATA_ACCESS]: user.countryDataAccess.map(
        (cda) => cda.value
      ),
      [UserProfileFields.EMAIL]: user.email,
      [UserProfileFields.PHONE_NUMBER]: user.phoneNumber,
    };
  };

  /**
   * Create a job role and update status on component state
   *
   * @param jobRole Job Role name
   */
  createJobRole = async (jobRole: string): Promise<void> => {
    const { appContext } = this.props;
    if (jobRole && jobRole.trim().length > 0) {
      this.setStateAttribute({ createJobRoleStatus: Status.Loading });
      try {
        const response = await SettingsApiInstance.CreateJobRole(
          jobRole,
          this.source
        );
        if (response.item) {
          const result = response.item;
          this.setStateAttribute({
            createJobRoleStatus: Status.Success,
            createJobRoleError: null,
          });

          sendEventGA(
            CategoryKeysGA.SettingsUsersInvite,
            ActionKeysGA.CreateJobRole
          );

          this.fetchJobRoles(result.id);
        } else {
          throw new Error();
        }
      } catch (error) {
        const errorState: Partial<UserProfileViewState> = {
          createJobRoleStatus: Status.Error,
        };
        if (error instanceof ApiError) {
          if (error.status === HTTP_STATUS.BAD_REQUEST) {
            const duplicate = findErrorCode(error, ErrorCodes.JobRoleExists);
            if (duplicate) {
              errorState.createJobRoleError = intl.get(
                'ERR_INVITE_USERS_JOB_ROLE_ALREADY_EXISTS'
              );
            }
          } else {
            appContext.setErrorToastText(
              intl.get('ERR_TOAST_INVITE_USER_GENERIC_ERRORS')
            );
          }
        } else {
          appContext.setErrorToastText(
            intl.get('ERR_TOAST_INVITE_USER_GENERIC_ERRORS')
          );
        }
        this.setStateAttribute(errorState);
      }
    }
  };

  /**
   * Reset jobRoles state
   */
  clearFocusStatus = (): void => {
    this.setStateAttribute({ jobRolesStatus: Status.Loading });
    const { jobRoles } = this.state;
    const newJobRoles = jobRoles.map(({ value, label }) => ({ value, label }));
    this.setStateAttribute({
      jobRoles: newJobRoles,
      jobRolesStatus: Status.Success,
    });
  };

  /**
   * Get job roles and set to component state
   *
   * @param createdRoleId ID of newly created job role
   */
  fetchJobRoles = async (createdRoleId?: string): Promise<void> => {
    this.setStateAttribute({ jobRolesStatus: Status.Loading });
    try {
      const response = await SettingsApiInstance.GetJobRoles(this.source);
      const jobRoles = response.items.map(({ role, id }) => ({
        value: id,
        label: role,
        ...(createdRoleId && createdRoleId === id ? { focused: true } : {}),
      }));
      this.setStateAttribute(
        {
          jobRoles,
          jobRolesStatus: Status.Success,
          createJobRoleStatus: Status.Idle,
          createJobRoleError: null,
        },
        this.clearFocusStatus
      );
    } catch (error) {
      this.setStateAttribute({
        jobRolesStatus: Status.Error,
        createJobRoleStatus: Status.Idle,
        createJobRoleError: null,
      });
    }
  };

  /**
   * Get permission levels for current organization
   */
  fetchPermissionLevels = async (): Promise<void> => {
    const { organizationId } = this.state;
    const { appContext } = this.props;
    this.setStateAttribute({ permissionLevelsStatus: Status.Loading });

    try {
      let orgId = organizationId;
      if (!organizationId) {
        const userInfo = await AuthApiInstance.GetUserInfo();
        orgId = userInfo.organizationId;
      }

      const response = await SettingsApiInstance.GetPermissionLevels(
        orgId,
        PermissionsViewMode.EDIT,
        this.source
      );

      const permissionLevels = appContext.isNationalLevelUser
        ? response.items.map(({ key, title, description }) => ({
            label: title,
            value: key,
            description,
          }))
        : response.items
            .filter(({ key }) => key !== DefaultPermissionLevel.NATIONAL_LEVEL)
            .map(({ key, title, description }) => ({
              label: title,
              value: key,
              description,
            }));
      this.setStateAttribute({
        permissionLevels,
        permissionLevelsStatus: Status.Success,
      });
    } catch (error) {
      this.setStateAttribute({ permissionLevelsStatus: Status.Error });
    }
  };

  /**
   * Fetch organization countries
   */
  fetchOrganizationCountries = async (): Promise<void> => {
    this.setStateAttribute({ countriesStatus: Status.Loading });
    try {
      const countriesResponse =
        await SettingsApiInstance.GetOrganizationCountries(this.source);
      const countries = countriesResponse.items.map((role) => ({
        value: role.code,
        label: role.name,
        isDisabled: false,
        isFixed: false,
      }));
      this.setStateAttribute({ countries, countriesStatus: Status.Success });
    } catch (error) {
      this.setStateAttribute({ countriesStatus: Status.Error });
    }
  };

  /**
   * clears the job role error status and error
   */
  clearJobRoleError = (): void => {
    this.setStateAttribute({
      createJobRoleError: null,
      createJobRoleStatus: Status.Idle,
    });
  };

  /**
   * Callback for saving the user profile edit changes
   *
   * @param cancel Whether to cancel the user profile edit mode
   */
  saveCallback = async (cancel: boolean): Promise<void> => {
    const { appContext } = this.props;
    const { setUserProfileSetupInProgress, setErrorToastText } = appContext;
    if (this.formikRef && this.formikRef.current) {
      const { submitForm, resetForm, isValid, errors } = this.formikRef.current;
      if (cancel) {
        resetForm();
        setUserProfileSetupInProgress(false);
      } else if (isValid && isEmpty(errors)) {
        await submitForm();
      } else {
        setErrorToastText(intl.get('ERR_TOAST_USER_PROFILE_VALIDATION_ERROR'));
      }
    }
  };

  /**
   * Toggle permissions modal
   */
  togglePermissionsModal = (): void => {
    const { isPermissionsModalOpen } = this.state;
    this.setStateAttribute({ isPermissionsModalOpen: !isPermissionsModalOpen });
  };

  /**
   * Toggles the deactivate modal
   */
  toggleDeactivateModal = (id?: string): void => {
    const { showDeactivateModal } = this.state;
    this.setStateAttribute({
      showDeactivateModal: !showDeactivateModal,
      toBeDeactivatedId: id,
    });
  };

  /**
   * Toggles the unassign modal
   */
  toggleUnassignModal = (id?: string): void => {
    const { supervisees } = this.state;
    this.setSuperviseesStateAttribute({
      showUnassignModal: !supervisees.showUnassignModal,
      toBeUnassignedId: id,
    });
  };

  /**
   * Toggles the project remove modal
   */
  toggleProjectRemoveModal = (): void => {
    const { currentProjects } = this.state;
    this.setCurrentProjectsStateAttribute({
      showRemoveProjectModal: !currentProjects.showRemoveProjectModal,
    });
  };

  /**
   * Toggles the deactivate modal
   */
  toggleAddProjectModal = (): void => {
    const { currentProjects } = this.state;
    this.setCurrentProjectsStateAttribute({
      showAddProjectModal: !currentProjects.showAddProjectModal,
    });
  };

  /**
   * Fetch paginated projects data
   *
   * @param pageConfig Pagination configuration
   * @param updateSilent Whether to update projects silently
   * @param resetScroll Whether to reset scroll to initial position
   */
  loadProjectsDataPaginated = async (
    pageConfig: Pick<ProjectsPagination, 'page' | 'pageSize'>,
    updateSilent = false,
    resetScroll = false
  ): Promise<void> => {
    const { appContext, history, location, match } = this.props;
    const { currentProjects } = this.state;
    try {
      this.setCurrentProjectsStateAttribute({
        status: Status.Loading,
        projectsUpdateSilent: updateSilent,
        scrolling: resetScroll,
      });
      if (match && match.params) {
        const { userId } = match.params;
        const result = await SettingsApiInstance.GetProjectsListData(
          pageConfig,
          this.source,
          userId
        );
        if (result) {
          const { items, pagination } = result;
          const newList = [...currentProjects.data, ...items];
          const projectsNormalized = newList.reduce(
            (accumulator, current) => ({
              ...accumulator,
              [current.projectId]: current,
            }),
            {}
          ) as { [key: string]: UserCurrentProject };

          const data = resetScroll ? items : Object.values(projectsNormalized);

          this.setCurrentProjectsStateAttribute({
            data,
            status: Status.Success,
            scrolling: false,
            pageIndex: pagination.page,
            pageSize: pagination.pageSize,
            total: pagination.total,
            pageCount: Math.ceil(pagination.total / pagination.pageSize),
          });

          if (resetScroll) {
            if (this.listRef.current) {
              this.listRef.current.resetloadMoreItemsCache();
              this.listRef.current.scrollToItem(0);
            }
          }
        } else {
          throw new Error();
        }
      }
    } catch (error) {
      this.setCurrentProjectsStateAttribute({
        status: Status.Error,
        projectsUpdateSilent: false,
        scrolling: false,
        pageIndex: 0,
        pageSize: Constraints.LazyLoadingPageSize,
        total: 0,
        pageCount: 0,
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          const errorCode = getFirstError(error);
          if (errorCode === ErrorCodes.Generic) {
            appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
          } else {
            if (errorCode === ErrorCodes.UnauthorizedUserProfile) {
              history.replace({
                pathname: `${ModulePaths.SettingsPath}${ModulePaths.SettingsUsersPath}`,
                search: getGlobalFiltersQuery(location.search),
              });
            }
            appContext.setErrorToastText(
              intl.get(getLocalizedErrorString(errorCode))
            );
          }
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    }
  };

  /**
   * Fetch paginated supervisees data
   *
   * @param pageConfig Pagination configuration
   * @param updateSilent Whether to update supervisees silently
   * @param resetScroll Whether to reset scroll to initial position
   */
  loadSuperviseesDataPaginated = async (
    pageConfig: Pick<UserProfileSuperviseesPagination, 'page' | 'pageSize'>,
    updateSilent = false,
    resetScroll = false
  ): Promise<void> => {
    const { appContext, history, location, match } = this.props;
    const { supervisees } = this.state;
    try {
      this.setSuperviseesStateAttribute({
        status: Status.Loading,
        superviseesUpdateSilent: updateSilent,
        scrolling: resetScroll,
      });
      if (match && match.params) {
        const { userId } = match.params;
        const result = await SettingsApiInstance.GetUserProfileSuperviseesData(
          pageConfig,
          userId,
          this.source
        );
        if (result) {
          const { items, pagination } = result;
          const newList = [...supervisees.data, ...items];
          const superviseesNormalized = newList.reduce(
            (accumulator, current) => ({
              ...accumulator,
              [current.id]: current,
            }),
            {}
          ) as { [key: string]: UserProfileSupervisee };

          const data = resetScroll
            ? items
            : Object.values(superviseesNormalized);

          this.setSuperviseesStateAttribute({
            data,
            status: Status.Success,
            showUnassignModal: false,
            scrolling: false,
            pageIndex: pagination.page,
            pageSize: pagination.pageSize,
            total: pagination.total,
            pageCount: Math.ceil(pagination.total / pagination.pageSize),
          });

          if (resetScroll) {
            if (this.listRef.current) {
              this.listRef.current.resetloadMoreItemsCache();
              this.listRef.current.scrollToItem(0);
            }
          }
        } else {
          throw new Error();
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setSuperviseesStateAttribute({
        status: Status.Error,
        superviseesUpdateSilent: false,
        scrolling: false,
        pageIndex: 0,
        pageSize: Constraints.LazyLoadingPageSize,
        total: 0,
        pageCount: 0,
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          const errorCode = getFirstError(error);
          if (errorCode === ErrorCodes.Generic) {
            appContext.setErrorToastText(
              intl.get('ERR_TOAST_USER_PROFILE_SUPERVISEES_FETCH_ERROR')
            );
          } else {
            if (errorCode === ErrorCodes.UnauthorizedUserProfile) {
              history.replace({
                pathname: `${ModulePaths.SettingsPath}${ModulePaths.SettingsUsersPath}`,
                search: getGlobalFiltersQuery(location.search),
              });
            }
            appContext.setErrorToastText(
              intl.get(getLocalizedErrorString(errorCode))
            );
          }
        }
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_USER_PROFILE_SUPERVISEES_FETCH_ERROR')
        );
      }
    }
  };

  /**
   * Fetch paginated current partners data
   *
   * @param pageConfig Pagination configuration
   * @param updateSilent Whether to update current partners silently
   * @param resetScroll Whether to reset scroll to initial position
   */
  loadCurrentPartnersDataPaginated = async (
    pageConfig: Pick<UserProfileCurrentPartnersPagination, 'page' | 'pageSize'>,
    updateSilent = false,
    resetScroll = false
  ): Promise<void> => {
    const { appContext, history, location, match } = this.props;
    const { currentPartners } = this.state;
    try {
      this.setCurrentPartnersStateAttribute({
        status: Status.Loading,
        partnersUpdateSilent: updateSilent,
        scrolling: resetScroll,
      });
      if (match && match.params) {
        const { userId } = match.params;
        const result =
          await SettingsApiInstance.GetUserProfileCurrentPartnersData(
            pageConfig,
            userId,
            this.source
          );
        if (result) {
          const { items, pagination } = result;
          const newList = [...currentPartners.data, ...items];
          const currentPartnersNormalized = newList.reduce(
            (accumulator, current) => ({
              ...accumulator,
              [current.id]: current,
            }),
            {}
          ) as { [key: string]: MonitoredPartner };

          const data = resetScroll
            ? items
            : Object.values(currentPartnersNormalized);

          this.setCurrentPartnersStateAttribute({
            data,
            status: Status.Success,
            scrolling: false,
            pageIndex: pagination.page,
            pageSize: pagination.pageSize,
            total: pagination.total,
            pageCount: Math.ceil(pagination.total / pagination.pageSize),
          });

          if (resetScroll) {
            if (this.listRef.current) {
              this.listRef.current.resetloadMoreItemsCache();
              this.listRef.current.scrollToItem(0);
            }
          }
        } else {
          throw new Error();
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setCurrentPartnersStateAttribute({
        status: Status.Error,
        partnersUpdateSilent: false,
        scrolling: false,
        pageIndex: 0,
        pageSize: Constraints.LazyLoadingPageSize,
        total: 0,
        pageCount: 0,
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          const errorCode = getFirstError(error);
          if (errorCode === ErrorCodes.Generic) {
            appContext.setErrorToastText(
              intl.get('ERR_TOAST_USER_PROFILE_CURRENT_PARTNERS_FETCH_ERROR')
            );
          } else {
            if (errorCode === ErrorCodes.UnauthorizedUserProfile) {
              history.replace({
                pathname: `${ModulePaths.SettingsPath}${ModulePaths.SettingsUsersPath}`,
                search: getGlobalFiltersQuery(location.search),
              });
            }
            appContext.setErrorToastText(
              intl.get(getLocalizedErrorString(errorCode))
            );
          }
        }
      } else {
        appContext.setErrorToastText(
          intl.get('ERR_TOAST_USER_PROFILE_CURRENT_PARTNERS_FETCH_ERROR')
        );
      }
    }
  };

  /**
   * Fetch projects data
   *
   * @param updateSilent Whether to update projects silently
   * @param resetScroll Whether to reset scroll to initial position
   */
  loadProjectsData = (
    updateSilent = false,
    resetScroll = false
  ): Promise<void> => {
    const { pageSize, pageIndex } = this.state.currentProjects;
    let filters;
    if (resetScroll) {
      filters = { page: 0, pageSize: Constraints.LazyLoadingPageSize };
    } else {
      filters = { page: pageIndex, pageSize };
    }
    return this.loadProjectsDataPaginated(filters, updateSilent, resetScroll);
  };

  /**
   * Fetch supervisees data
   *
   * @param updateSilent Whether to update supervisees silently
   * @param resetScroll Whether to reset scroll to initial position
   */
  loadSuperviseesData = (
    updateSilent = false,
    resetScroll = false
  ): Promise<void> => {
    const { pageSize, pageIndex } = this.state.supervisees;
    let filters;
    if (resetScroll) {
      filters = { page: 0, pageSize: Constraints.LazyLoadingPageSize };
    } else {
      filters = { page: pageIndex, pageSize };
    }
    return this.loadSuperviseesDataPaginated(
      filters,
      updateSilent,
      resetScroll
    );
  };

  /**
   * Fetch current partners data
   *
   * @param updateSilent Whether to update current partners silently
   * @param resetScroll Whether to reset scroll to initial position
   */
  loadCurrentPartnersData = (
    updateSilent = false,
    resetScroll = false
  ): Promise<void> => {
    const { pageSize, pageIndex } = this.state.currentPartners;
    let filters;
    if (resetScroll) {
      filters = { page: 0, pageSize: Constraints.LazyLoadingPageSize };
    } else {
      filters = { page: pageIndex, pageSize };
    }
    return this.loadCurrentPartnersDataPaginated(
      filters,
      updateSilent,
      resetScroll
    );
  };

  /**
   * Fetch projects data for the next page
   *
   * @param _startIndex First index for page from overall projects list
   * @param _stopIndex Last index for page from overall projects list
   */
  // eslint-disable-next-line no-unused-vars
  loadNextProjectsPage = (
    _startIndex: number,
    _stopIndex: number
  ): Promise<void> => {
    const { pageSize, pageIndex } = this.state.currentProjects;
    const filters = { page: pageIndex + 1, pageSize };
    return this.loadProjectsDataPaginated(filters);
  };

  /**
   * Fetch supervisees data for the next page
   *
   * @param _startIndex First index for page from overall supervisees list
   * @param _stopIndex Last index for page from overall supervisees list
   */
  // eslint-disable-next-line no-unused-vars
  loadNextSuperviseesPage = (
    _startIndex: number,
    _stopIndex: number
  ): Promise<void> => {
    const { pageSize, pageIndex } = this.state.supervisees;
    const filters = { page: pageIndex + 1, pageSize };
    return this.loadSuperviseesDataPaginated(filters);
  };

  /**
   * Fetch current partners data for the next page
   *
   * @param _startIndex First index for page from overall current partners list
   * @param _stopIndex Last index for page from overall current partners list
   */
  // eslint-disable-next-line no-unused-vars
  loadNextPartnersPage = (
    _startIndex: number,
    _stopIndex: number
  ): Promise<void> => {
    const { pageSize, pageIndex } = this.state.currentPartners;
    const filters = { page: Number(pageIndex) + 1, pageSize };
    return this.loadCurrentPartnersDataPaginated(filters);
  };

  /**
   * Get upload url and upload image to that url; if success returns
   * filename; else throws error
   *
   * @param image Image file
   * @returns {Promise<string>} Filename
   */
  uploadImage = async (image: File): Promise<string> => {
    const { match } = this.props;
    this.setStateAttribute({ status: Status.Loading });
    try {
      if (match && match.params) {
        const { userId } = match.params;
        const { item } = await AuthApiInstance.GetCodelessImageUploadUrl(
          image.name,
          userId,
          this.source
        );
        if (item && item.file) {
          await AuthApiInstance.UploadProfileImage(
            item.file,
            item.requiredHeaders,
            image,
            this.source
          );
          this.setStateAttribute({ status: Status.Success });
          return image.name;
          // eslint-disable-next-line no-else-return
        } else {
          throw new Error();
        }
      } else {
        throw new Error(intl.get('ERR_TOAST_USER_PROFILE_ID_ERROR'));
      }
    } catch (error) {
      this.setStateAttribute({ status: Status.Error });
      throw error;
    }
  };

  /**
   * Build the payload for the user profile update action
   *
   * @param values Form values from the left panel
   * @returns {Promise<Partial<UserProfileUpdateRequestBody>>} Object containing the updated form fields
   */
  buildProfileUpdatePayload = async (
    values: UserProfileLeftPanelFormValues
  ): Promise<Partial<UserProfileUpdateRequestBody>> => {
    const { leftPanelData } = this.state;
    const payload: Partial<UserProfileUpdateRequestBody> = {};

    let existingImage;
    /* If the user never had a profile image in the first place, let the 
    image be undefined so that it does not get added to the payload */
    if (leftPanelData.image !== '' && leftPanelData.image !== null) {
      existingImage = leftPanelData.image;
    }

    if (!isEqual(values.image?.image?.src, existingImage)) {
      if (values.image?.image?.src) {
        const imageName = await this.uploadImage(values.image);
        if (imageName) {
          payload.imageURL = imageName;
        }
      } else {
        payload.imageURL = '';
      }
    }
    const fullName = `${String(leftPanelData.firstName)} ${String(
      leftPanelData.lastName
    )}`;
    if (!isEqual(values.name, fullName)) {
      /* Split first word in name to be first name and all 
        the rest to be the last name */
      const nameParts = values.name.split(' ');
      const firstName = nameParts.shift() ?? values.name;
      const lastName = nameParts.join(' ');
      payload.firstName = firstName;
      payload.lastName = lastName;
    }
    if (!isEqual(values.jobRole, leftPanelData.jobRole.id)) {
      payload.jobRoleId = values.jobRole;
    }
    if (!isEqual(values.permissionLevel, leftPanelData.permissionLevel)) {
      payload.permission = values.permissionLevel;
    }
    if (
      !isEqual(
        values.countryDataAccess,
        leftPanelData.countryDataAccess.map((cda) => cda.value)
      )
    ) {
      payload.countryAccess = values.countryDataAccess;
    }
    if (!isEqual(values.email, leftPanelData.email)) {
      payload.userName = values.email;
    }
    if (!isEqual(values.phoneNumber, leftPanelData.phoneNumber)) {
      payload.phoneNumber = values.phoneNumber;
    }

    return payload;
  };

  /**
   * Refreshes the user session by fetching user data and remounting the current container
   */
  refreshSession = async (): Promise<void> => {
    const {
      appContext: { getUserInfo },
    } = this.props;
    await getUserInfo();
    EventBus.getInstance().dispatch(EventKey.RemountComponentTree);
  };

  /**
   * Disables edit mode and refreshes session
   */
  disableEditAndRefresh = async (): Promise<void> => {
    const {
      appContext: { setUserProfileSetupInProgress },
    } = this.props;
    setUserProfileSetupInProgress(false);
    await this.refreshSession();
  };

  /**
   * Handles remove project
   *
   * @param projectId User removed project id
   */
  handleRemoveUserFromProject = (projectId: string): void => {
    this.setCurrentProjectsStateAttribute({
      removingProjectId: projectId,
    });
    this.toggleProjectRemoveModal();
  };

  /**
   * Handles form submission for left panel changes
   *
   * @param values Values entered
   * @param helpers Formik helpers
   */
  handleSubmit = async (
    values: UserProfileLeftPanelFormValues,
    helpers: FormikHelpers<UserProfileLeftPanelFormValues>
  ): Promise<void> => {
    const { appContext, match } = this.props;
    const { setErrorToastText, setSuccessToastText, userInfoData } = appContext;
    const {
      leftPanelData: { email },
    } = this.state;

    this.setStateAttribute({ status: Status.Loading });
    try {
      if (this.formikRef && this.formikRef.current) {
        const { dirty } = this.formikRef.current;
        if (dirty) {
          if (match && match.params) {
            const { userId } = match.params;
            const payload = await this.buildProfileUpdatePayload(values);
            await SettingsApiInstance.UpdateUserProfileDetails(
              payload,
              userId,
              this.source
            );

            const loggedInUserEmail = userInfoData.data?.userName;

            if (
              !isEqual(values.email, email) &&
              isEqual(email, loggedInUserEmail)
            ) {
              /* Verify if the email has been updated and if the user 
                updated his own email (only Owner can do this), and display 
                the log out message in the success toast */
              setSuccessToastText(
                intl.get('LBL_USER_PROFILE_SAVE_WITH_EMAIL_CHANGE_SUCCESS')
              );
            } else {
              setSuccessToastText(intl.get('LBL_USER_PROFILE_SAVE_SUCCESS'));
            }
            await this.disableEditAndRefresh();
            this.setStateAttribute({ status: Status.Success });
          } else {
            throw new Error(intl.get('ERR_TOAST_USER_PROFILE_ID_ERROR'));
          }
        } else {
          await this.disableEditAndRefresh();
          setSuccessToastText(
            intl.get('LBL_USER_PROFILE_NO_CHANGES_SAVE_SUCCESS')
          );
          this.setStateAttribute({ status: Status.Success });
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      this.setStateAttribute({ status: Status.Error });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          const errorCode = getFirstError(error);
          if (errorCode === ErrorCodes.Generic) {
            setErrorToastText(intl.get('ERR_TOAST_USER_PROFILE_SAVE_ERROR'));
          } else {
            setErrorToastText(intl.get(getLocalizedErrorString(errorCode)));
          }
        }
      } else {
        setErrorToastText(intl.get('ERR_TOAST_USER_PROFILE_SAVE_ERROR'));
      }
    }
  };

  /**
   * Handle project add compatibility
   *
   * @param projectIds Project IDs array
   */
  handleFetchUserProjectCompatibility = async (
    projectIds: string[]
  ): Promise<void> => {
    const { appContext, match } = this.props;
    this.setProjectAddCompatibilityStateAttribute({
      compatibilityStatus: Status.Loading,
      projectIds,
    });
    try {
      if (match && match.params) {
        const { userId } = match.params;
        const response = await ProjectsApiInstance.GetUserProjectCompatibility(
          userId,
          projectIds,
          this.source
        );

        if (response.item) {
          const result = response.item;
          if (result.missingCountries.length > 0) {
            const countries = result.missingCountries.map(({ name }) => name);
            this.setProjectAddCompatibilityStateAttribute({
              userUnassignedCountries: countries,
              displayCDAWarningPrompt: true,
              compatibilityStatus: Status.Success,
            });
          } else {
            this.onAddUserToProjects(projectIds);
          }
        }
      }
    } catch (error) {
      this.setProjectAddCompatibilityStateAttribute({
        userUnassignedCountries: [],
        displayCDAWarningPrompt: false,
        compatibilityStatus: Status.Error,
      });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
        }
      } else {
        appContext.setErrorToastText(intl.get('ERR_TOAST_GENERIC_ERROR'));
      }
    } finally {
      this.setProjectAddCompatibilityStateAttribute({
        compatibilityStatus: Status.Idle,
      });
    }
  };

  /**
   * Handles click interactions on the country data access warning prompt
   *
   * @param canAddWithoutPrompt Boolean flag specifying projects add status
   */
  handleCDAPromptAction = (canAddWithoutPrompt: boolean): void => {
    const {
      projectAddCompatibility: { projectIds },
    } = this.state;
    if (canAddWithoutPrompt) {
      this.onAddUserToProjects(projectIds);
    }
    this.setProjectAddCompatibilityStateAttribute({
      displayCDAWarningPrompt: false,
      userUnassignedCountries: [],
    });
  };

  /**
   * Add users to multiple projects
   *
   * @param projectIds Ids of added projects
   */
  onAddUserToProjects = async (projectIds: Array<string>): Promise<void> => {
    const {
      match,
      appContext: { setSuccessToastText, setErrorToastText },
    } = this.props;

    if (match && match.params) {
      const { userId } = match.params;
      this.setStateAttribute({ status: Status.Loading });

      try {
        await SettingsApiInstance.AddProjectsToUser(
          userId,
          projectIds,
          this.source
        );
        setSuccessToastText(
          intl.get('LBL_TOAST_USER_PROFILE_ADD_PROJECT_SUCCESS')
        );
        this.onAddProjectsToUserSuccess();
        this.setStateAttribute({ status: Status.Success });
      } catch (error) {
        this.setStateAttribute({ status: Status.Error });
        this.onAddProjectsToUserSuccess();
        if (error instanceof ApiError) {
          if (error.status !== HTTP_STATUS.FORBIDDEN) {
            const errorCode = getFirstError(error);
            if (errorCode === ErrorCodes.Generic) {
              setErrorToastText(
                intl.get('ERR_TOAST_USER_PROFILE_ADD_PROJECT_ERROR')
              );
            } else {
              setErrorToastText(intl.get(getLocalizedErrorString(errorCode)));
            }
          }
        } else {
          setErrorToastText(
            intl.get('ERR_TOAST_USER_PROFILE_ADD_PROJECT_ERROR')
          );
        }
      } finally {
        this.toggleAddProjectModal();
        await this.loadProjectsData(false, true);
        await this.fetchUserData();
      }
    }
  };

  /**
   * Remove user from project
   *
   */
  onRemoveUserFromProjects = async (): Promise<void> => {
    const {
      match,
      appContext: { setSuccessToastText, setErrorToastText },
    } = this.props;
    const {
      currentProjects: { removingProjectId },
    } = this.state;

    if (match && match.params) {
      const { userId } = match.params;
      this.setStateAttribute({ status: Status.Loading });

      try {
        await ProjectsApiInstance.RemoveUserFromProject(
          removingProjectId,
          userId,
          this.source
        );

        setSuccessToastText(
          intl.get('LBL_TOAST_USER_PROFILE_REMOVE_PROJECT_SUCCESS')
        );
        this.setStateAttribute({ status: Status.Success });
      } catch (error) {
        this.setStateAttribute({ status: Status.Error });
        if (error instanceof ApiError) {
          if (error.status !== HTTP_STATUS.FORBIDDEN) {
            const errorCode = getFirstError(error);
            if (errorCode === ErrorCodes.Generic) {
              setErrorToastText(
                intl.get('ERR_TOAST_USER_PROFILE_REMOVE_PROJECT_ERROR')
              );
            } else {
              setErrorToastText(intl.get(getLocalizedErrorString(errorCode)));
            }
          }
        } else {
          setErrorToastText(
            intl.get('ERR_TOAST_USER_PROFILE_REMOVE_PROJECT_ERROR')
          );
        }
      } finally {
        this.toggleProjectRemoveModal();
        await this.loadProjectsData(false, true);
        await this.fetchUserData();
      }
    }
  };

  /**
   * Handles deactivating user
   */
  onDeactivateUser = async (): Promise<void> => {
    const { toBeDeactivatedId } = this.state;
    const { appContext, match } = this.props;
    const { setErrorToastText, setSuccessToastText } = appContext;
    this.setStateAttribute({ status: Status.Loading });
    try {
      if (match && match.params && toBeDeactivatedId) {
        const { userId } = match.params;
        await SettingsApiInstance.UpdateUserStatus(
          {
            status: UserStatus.Deactivated,
          },
          userId,
          this.source
        );
        setSuccessToastText(
          intl.get('LBL_TOAST_USER_PROFILE_DEACTIVATE_SUCCESS')
        );
        this.setStateAttribute({ status: Status.Success });
      } else {
        throw new Error(intl.get('ERR_TOAST_USER_PROFILE_ID_ERROR'));
      }
    } catch (error) {
      this.setStateAttribute({ status: Status.Error });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          const errorCode = getFirstError(error);
          if (errorCode === ErrorCodes.Generic) {
            setErrorToastText(
              intl.get('ERR_TOAST_USER_PROFILE_DEACTIVATE_ERROR')
            );
          } else {
            setErrorToastText(intl.get(getLocalizedErrorString(errorCode)));
          }
        }
      } else {
        setErrorToastText(intl.get('ERR_TOAST_USER_PROFILE_DEACTIVATE_ERROR'));
      }
    } finally {
      if (
        this.deactivateOkayButtonRef &&
        this.deactivateOkayButtonRef.current
      ) {
        this.deactivateOkayButtonRef.current.blur();
      }
      this.toggleDeactivateModal();
      await this.fetchUserData(true);
    }
  };

  /**
   * Handles un-assigning supervisees
   */
  onUnassignSupervisee = async (): Promise<void> => {
    const {
      supervisees: { toBeUnassignedId },
    } = this.state;
    const { appContext, match } = this.props;
    const { setErrorToastText, setSuccessToastText } = appContext;
    this.setStateAttribute({ status: Status.Loading });
    try {
      if (match && match.params && toBeUnassignedId) {
        const { userId } = match.params;
        await SettingsApiInstance.RemoveUserSupervisee(
          toBeUnassignedId,
          userId,
          this.source
        );
        setSuccessToastText(
          intl.get('LBL_TOAST_USER_PROFILE_UNASSIGN_SUCCESS')
        );
        this.setStateAttribute({ status: Status.Success });
      } else {
        throw new Error(intl.get('ERR_TOAST_USER_PROFILE_ID_ERROR'));
      }
    } catch (error) {
      this.setStateAttribute({ status: Status.Error });
      if (error instanceof ApiError) {
        if (error.status !== HTTP_STATUS.FORBIDDEN) {
          const errorCode = getFirstError(error);
          if (errorCode === ErrorCodes.Generic) {
            setErrorToastText(
              intl.get('ERR_TOAST_USER_PROFILE_UNASSIGN_ERROR')
            );
          } else {
            setErrorToastText(intl.get(getLocalizedErrorString(errorCode)));
          }
        }
      } else {
        setErrorToastText(intl.get('ERR_TOAST_USER_PROFILE_UNASSIGN_ERROR'));
      }
    } finally {
      if (this.unassignOkayButtonRef && this.unassignOkayButtonRef.current) {
        this.unassignOkayButtonRef.current.blur();
      }
      this.toggleUnassignModal();
      await this.loadSuperviseesData(false, true);
    }
  };

  /**
   * Resets state flag for adding a user to the existing user list
   */
  onAddProjectsToUserSuccess = (): void =>
    this.setProjectAddCompatibilityStateAttribute({
      displayCDAWarningPrompt: false,
      projectIds: [],
      userUnassignedCountries: [],
    });

  /**
   * Renders the deactivate modal
   *
   * @returns {JSX.Element} JSX snippet containing the modal component
   */
  renderDeactivateModal = (): JSX.Element => {
    const { status, showDeactivateModal } = this.state;
    return (
      <Modal
        size="lg"
        isOpen={showDeactivateModal}
        toggle={() => this.toggleDeactivateModal()}
        backdrop="static"
        centered
        keyboard={false}
        id="deactivateUserTarget"
      >
        <BlockUi tag="div" blocking={status === Status.Loading}>
          <ModalHeader className="increase-font truncate text-center">
            {intl.get('LBL_USER_PROFILE_DEACTIVATE_MODAL_TITLE')}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="6" className="btn-col-right">
                <button
                  className="btn btn-warning"
                  type="button"
                  onClick={() => this.toggleDeactivateModal()}
                >
                  {intl.get('BTN_PROJECT_USERS_CANCEL')}
                </button>
              </Col>
              <Col xs="6">
                <button
                  ref={this.deactivateOkayButtonRef}
                  className="btn btn-primary"
                  type="button"
                  onClick={this.onDeactivateUser}
                >
                  {intl.get('BTN_PROJECT_USERS_OKAY')}
                </button>
              </Col>
            </Row>
          </ModalBody>
        </BlockUi>
      </Modal>
    );
  };

  /**
   * Renders the unassign modal
   *
   * @returns {JSX.Element} JSX snippet containing the modal component
   */
  renderUnassignModal = (): JSX.Element => {
    const {
      status,
      supervisees: { showUnassignModal },
    } = this.state;
    return (
      <Modal
        size="lg"
        isOpen={showUnassignModal}
        toggle={() => this.toggleUnassignModal()}
        backdrop="static"
        centered
        keyboard={false}
        id="deactivateUserTarget"
      >
        <BlockUi tag="div" blocking={status === Status.Loading}>
          <ModalHeader className="increase-font truncate text-center">
            {intl.getHTML('LBL_USER_PROFILE_UNASSIGN_MODAL_TITLE')}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="6" className="btn-col-right">
                <button
                  className="btn btn-warning"
                  type="button"
                  onClick={() => this.toggleUnassignModal()}
                >
                  {intl.get('BTN_PROJECT_USERS_CANCEL')}
                </button>
              </Col>
              <Col xs="6">
                <button
                  ref={this.unassignOkayButtonRef}
                  className="btn btn-primary"
                  type="button"
                  onClick={this.onUnassignSupervisee}
                >
                  {intl.get('BTN_PROJECT_USERS_OKAY')}
                </button>
              </Col>
            </Row>
          </ModalBody>
        </BlockUi>
      </Modal>
    );
  };

  /**
   * Renders the remove project modal
   *
   * @returns {JSX.Element} JSX snippet containing the modal component
   */
  renderRemoveFromProjectModal = (): JSX.Element => {
    const {
      status,
      currentProjects: { showRemoveProjectModal },
    } = this.state;
    return (
      <Modal
        size="lg"
        isOpen={showRemoveProjectModal}
        toggle={() => this.toggleProjectRemoveModal()}
        backdrop="static"
        centered
        keyboard={false}
        id="removeProjectTarget"
      >
        <BlockUi tag="div" blocking={status === Status.Loading}>
          <ModalHeader className="increase-font truncate text-center">
            {intl.getHTML('LBL_USER_PROFILE_REMOVE_PROJECT_MODAL_TITLE')}
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="6" className="btn-col-right">
                <button
                  className="btn btn-warning"
                  type="button"
                  onClick={() => this.toggleProjectRemoveModal()}
                >
                  {intl.get('BTN_PROJECT_USERS_CANCEL')}
                </button>
              </Col>
              <Col xs="6">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={this.onRemoveUserFromProjects}
                >
                  {intl.get('BTN_PROJECT_USERS_OKAY')}
                </button>
              </Col>
            </Row>
          </ModalBody>
        </BlockUi>
      </Modal>
    );
  };

  render() {
    const {
      leftPanelData,
      supervisees,
      currentProjects,
      currentPartners,
      status,
      restrictions,
      createJobRoleError,
      createJobRoleStatus,
      jobRoles,
      jobRolesStatus,
      countries,
      countriesStatus,
      permissionLevels,
      permissionLevelsStatus,
      isPermissionsModalOpen,
      canAddPermissions,
      canEditPermissions,
      canDeactivateUsers,
      permissionsFirstTime,
      projectAddCompatibility: {
        displayCDAWarningPrompt,
        userUnassignedCountries,
        compatibilityStatus,
      },
    } = this.state;
    const {
      appContext: { getGettingStartedState, isNationalLevelUser },
      nationalLevelContext: { onPartnerSwitch },
    } = this.props;
    const validationSchema =
      UserProfileValidations.GetValidationSchema() as ObjectSchema;

    return (
      <>
        <ScrollToTopOnMount />
        <div
          className="content-container user-profile-item"
          style={{ padding: '0px' }}
        >
          <BlockUi
            tag="div"
            blocking={status === Status.Loading}
            className="user-profile-left-outer"
          >
            <Formik
              innerRef={this.formikRef}
              initialValues={this.initialValues}
              validationSchema={validationSchema}
              onSubmit={this.handleSubmit}
              enableReinitialize
            >
              {(props) => (
                <UserProfileLeftPanel
                  data={leftPanelData}
                  jobRoles={jobRoles}
                  jobRolesStatus={jobRolesStatus}
                  createJobRoleStatus={createJobRoleStatus}
                  createJobRoleError={createJobRoleError}
                  onCreateJobRole={this.createJobRole}
                  clearJobRoleError={this.clearJobRoleError}
                  countries={countries}
                  countriesStatus={countriesStatus}
                  permissionLevels={permissionLevels}
                  permissionLevelsStatus={permissionLevelsStatus}
                  canDeactivateUsers={canDeactivateUsers}
                  restrictions={restrictions}
                  togglePermissionsModal={this.togglePermissionsModal}
                  toggleDeactivateModal={this.toggleDeactivateModal}
                  {...props}
                />
              )}
            </Formik>
          </BlockUi>

          <div className="user-profile-item-content">
            {isNationalLevelUser ? (
              <UserProfilePartners
                data={currentPartners.data}
                status={status}
                currentPartnersStatus={currentPartners.status}
                scrolling={currentPartners.scrolling}
                page={currentPartners.pageIndex}
                pageCount={currentPartners.pageCount}
                pageSize={currentPartners.pageSize}
                loadNextPage={this.loadNextPartnersPage}
                onViewData={onPartnerSwitch}
              />
            ) : (
              <>
                <UsersProjectsPane
                  data={currentProjects.data}
                  restrictions={restrictions}
                  status={status}
                  projectsStatus={currentProjects.status}
                  scrolling={currentProjects.scrolling}
                  page={currentProjects.pageIndex}
                  pageCount={currentProjects.pageCount}
                  pageSize={currentProjects.pageSize}
                  countries={countries}
                  userCountryData={leftPanelData.countryDataAccess}
                  showAddProjectModal={currentProjects.showAddProjectModal}
                  projectCompatibilityStatus={compatibilityStatus}
                  displayCDAWarningPrompt={displayCDAWarningPrompt}
                  userUnassignedCountries={userUnassignedCountries}
                  onCDAWarningPromptAction={this.handleCDAPromptAction}
                  loadNextPage={this.loadNextProjectsPage}
                  onAddNewProjects={this.handleFetchUserProjectCompatibility}
                  toggleAddUserToProjectsModal={this.toggleAddProjectModal}
                  onRemoveFromProject={this.handleRemoveUserFromProject}
                />
                <UserProfileSupervisees
                  data={supervisees.data}
                  restrictions={restrictions}
                  status={status}
                  superviseesStatus={supervisees.status}
                  scrolling={supervisees.scrolling}
                  page={supervisees.pageIndex}
                  pageCount={supervisees.pageCount}
                  pageSize={supervisees.pageSize}
                  loadNextPage={this.loadNextSuperviseesPage}
                  toggleUnassignModal={this.toggleUnassignModal}
                />
              </>
            )}
          </div>
          <PermissionModal
            isOpen={isPermissionsModalOpen}
            onToggle={this.togglePermissionsModal}
            fetchPermissionLevels={this.fetchPermissionLevels}
            canAddPermissions={canAddPermissions}
            canEditPermissions={canEditPermissions}
            permissionsFirstTime={permissionsFirstTime}
            getGettingStartedState={getGettingStartedState}
          />
          {this.renderDeactivateModal()}
          {this.renderUnassignModal()}
          {this.renderRemoveFromProjectModal()}
        </div>
      </>
    );
  }
}

export default UserProfileView;
