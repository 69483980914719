/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext } from 'react';

import AppContextProps from 'context/AppContextProps';
import { defaultGlobalFilters } from 'helpers/GlobalFilterUtils';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';
import ExchangeRate from 'shared/enums/ExchangeRate';
import Status from 'shared/enums/Status';

/**
 * The createContext can be declared with Generics using Partials
 * to avoid default values and empty function. But then it will be
 * required to do a null check everywhere when using these values
 */
const AppContext = createContext<AppContextProps>({
  globalFilters: defaultGlobalFilters,
  setGlobalFilters: () => {},
  setGlobalFiltersWithValidations: () => {},

  dashboardSetupInProgress: false,
  startDashboardSetup: (): void => {},
  endDashboardSetup: (): void => {},

  groupDetailsSetupInProgress: false,
  startGroupDetailsSetup: (): void => {},
  endGroupDetailsSetup: (): void => {},

  userProfileSetupInProgress: false,
  startUserProfileSetup: (): void => {},
  endUserProfileSetup: (): void => {},
  setUserProfileSetupInProgress: (): void => {},

  setDashboardSetupCallback: (): void => {},
  setGroupDetailsSetupCallback: (): void => {},
  setUserProfileSetupCallback: (): void => {},

  setExcelReportDownloadCallback: (): void => {},
  startExcelReportDownload: (): void => {},

  getPermissions: (): Promise<void> => Promise.resolve(),
  permissionsData: {
    status: Status.Loading,
    silent: false,
    claims: [],
    permissionLevel: null,
  },

  isNationalLevelUser: false,
  prevSelectedOrganization: null,

  userInfoData: {
    status: Status.Loading,
    data: null,
    features: null,
  },
  getUserInfo: () => Promise.resolve(),

  fetchedCurrency: {
    name: 'United States Dollar',
    currencyCode: 'USD',
    symbol: '',
    exchangeRateMode: ExchangeRate.Current,
    status: Status.Loading,
  },
  defaultCurrency: {
    name: 'United States Dollar',
    currencyCode: 'USD',
    symbol: '',
    exchangeRateMode: ExchangeRate.Current,
    status: Status.Loading,
  },
  selectedCurrency: {
    name: 'United States Dollar',
    currencyCode: 'USD',
    symbol: '',
    exchangeRateMode: ExchangeRate.Current,
    status: Status.Loading,
  },
  currencySettings: {
    currentDefaultCurrency: 'USD',
    currentExchangeRate: ExchangeRate.Current,
    settingsStatus: Status.Loading,
  },
  setCurrencySettings: () => {},
  validateCurrencyToggle: (): Promise<void> => Promise.resolve(),
  toggleCurrency: () => {},

  projectListData: { status: Status.Idle, data: {}, filtered: {} },
  getProjectList: (): Promise<void> => Promise.resolve(),

  groupStatusData: { status: Status.Idle, data: {}, error: '' },
  getGroupStatuses: (): Promise<void> => Promise.resolve(),

  getGettingStartedState: (): Promise<void> => Promise.resolve(),
  gettingStartedStates: { status: Status.Loading, silent: false, data: null },

  guideOpen: false,
  setGuideOpen: () => {},

  resendInviteStatus: Status.Idle,
  setResendInviteStatus: () => {},

  inviteUsersSubmitting: false,
  setInviteUsersSubmitting: () => {},

  errorToastText: undefined,
  setErrorToastText: (): void => {},

  errorToastHeader: '',
  setErrorToastHeader: (): void => {},

  hideErrorToast: (): void => {},

  successToastText: undefined,
  setSuccessToastText: (): void => {},

  successToastHeader: '',
  setSuccessToastHeader: (): void => {},

  hideSuccessToast: (): void => {},

  onLogin: (gf?: GlobalFilters): Promise<void> => Promise.resolve(),
});

export default AppContext;
