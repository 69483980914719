import { CancelTokenSource } from 'axios';

import ApiBase from 'api/common/ApiBase';
import QueryObjectBuilder from 'api/common/QueryObjectBuilder';
import ObjectResponse from 'api/common/responses/ObjectResponse';
import FinancialMetric from 'api/dashboard/types/financial/FinancialMetric';
import KeyMetricsDetails from 'api/finances/types/KeyMetricsDetails';
import { FinanceApiTags } from 'constants/request-tags/RequestTags';
import FinancialMetricsChartFilter from 'modules/private/finances/components/financial-metrics-chart-container/FinancialMetricsChartFilter';
import FinancialMetricsChartTypes from 'modules/private/finances/components/financial-metrics-chart-container/FinancialMetricsChartTypes';
import GlobalFilters from 'shared/components/header-toolbar/GlobalFilters';

class FinancesApi extends ApiBase {
  async GetKeyMetrics(
    globalFilters: GlobalFilters,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<KeyMetricsDetails>> {
    const action = 'finances/details';

    const globalFilterQuery =
      QueryObjectBuilder.createGlobalFiltersQuery(globalFilters);

    const financialMetricsData = this.GetAsync<
      ObjectResponse<KeyMetricsDetails>
    >({
      action,
      tag: FinanceApiTags.GetKeyMetrics,
      cancelSource: cancelToken,
      queryParams: globalFilterQuery,
    });

    return financialMetricsData;
  }

  async GetFinancesChartData(
    chartType: FinancialMetricsChartTypes,
    globalFilters: GlobalFilters,
    filters: FinancialMetricsChartFilter,
    cancelToken: CancelTokenSource
  ): Promise<ObjectResponse<FinancialMetric>> {
    const action = 'finances/metrics';

    const globalFilterQuery =
      QueryObjectBuilder.createGlobalFiltersQuery(globalFilters);
    const financialFilterQuery =
      QueryObjectBuilder.createFinancialFiltersQuery(filters);

    const queryParams = {
      ...globalFilterQuery,
      ...financialFilterQuery,
      type: chartType,
    };

    const financialMetricsData = this.GetAsync<ObjectResponse<FinancialMetric>>(
      {
        action,
        tag: FinanceApiTags.GetFinancesChartData,
        cancelSource: cancelToken,
        queryParams,
      }
    );

    return financialMetricsData;
  }
}

const FinancesApiInstance = new FinancesApi();

export default FinancesApiInstance;
