import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import App from 'App';
import AppProvider from 'context/AppProvider';
import NationalLevelProvider from 'context/national-level-context/NationalLevelProvider';
import history from 'router-history';
import * as serviceWorker from 'serviceWorker';
import AppErrorFallback from 'shared/components/app-error-fallback/AppErrorFallback';

import 'index.scss';
import 'react-block-ui/style.css';

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={AppErrorFallback}>
    <Router history={history}>
      <AppProvider>
        <NationalLevelProvider>
          <App />
        </NationalLevelProvider>
      </AppProvider>
    </Router>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
