import { memo, useEffect, useRef } from 'react';

import BlockUi from 'react-block-ui';
import * as intl from 'react-intl-universal';
import ReactTooltip from 'react-tooltip';
import { Button, Col, Row } from 'reactstrap';

import EllipsisTooltip from 'shared/components/ellipsis-tooltip/EllipsisTooltip';

import MonitoredPartner from '../MonitoredPartner';
import UserProfilePartnerItemProps from './UserProfilePartnerItemProps';

const UserProfilePartnerItem: React.FC<UserProfilePartnerItemProps> = (
  props: UserProfilePartnerItemProps
) => {
  const {
    data: { list, isItemLoaded, onViewData },
    index,
    style,
  } = props;

  const partner = list[index];
  const loaded = isItemLoaded(index);

  const rowRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    requestAnimationFrame(() => {
      ReactTooltip.rebuild();
    });
  }, []);

  const currentPartner: MonitoredPartner = partner ?? new MonitoredPartner();

  return (
    <div
      style={{
        ...style,
        top: style.top ?? 0,
        height: Number(style.height ?? 0) - 56,
      }}
      ref={rowRef}
    >
      <BlockUi blocking={!loaded} tag="div">
        <Row className="m-0  align-items-center mt-3">
          <Col className="p-0 mr-2">
            <Row
              className="d-flex align-items-start m-0"
              style={{ display: 'flex', flexFlow: 'row wrap' }}
            >
              <Col xs="8" className="d-flex justify-content-start p-0">
                <EllipsisTooltip
                  tag="div"
                  data-place="bottom"
                  data-for="insTooltip"
                  data-tip={String(currentPartner.name)}
                  data-class="overflow-wrap bring-it-up"
                  className="text-16-semibold truncate shorten-max"
                >
                  {String(currentPartner.name)}&nbsp;
                </EllipsisTooltip>
                <span className="text-16-semibold">
                  {`(Partner ID: ${String(currentPartner.partnerId)})`}
                </span>
              </Col>
              <Col xs="4" className="d-flex justify-content-end p-0 pr-4">
                <Button
                  size="sm"
                  color="secondary"
                  onClick={() => onViewData(currentPartner.id)}
                >
                  {intl.get('BTN_USER_PROFILE_PARTNER_VIEW_DATA')}
                </Button>
              </Col>
            </Row>
            <Row className="d-flex justify-content-start m-0 mt-1">
              <EllipsisTooltip
                tag="div"
                data-place="bottom"
                data-for="insTooltip"
                data-tip={intl.get('LBL_USER_PROFILE_PARTNER_GROUP_COUNT', {
                  groupCount: currentPartner.totalGroupCount,
                })}
                data-class="overflow-wrap bring-it-up"
                className="text-12-bold truncate shorten text-uppercase text-gray"
              >
                {intl.get('LBL_USER_PROFILE_PARTNER_GROUP_COUNT', {
                  groupCount: currentPartner.totalGroupCount,
                })}
              </EllipsisTooltip>
            </Row>
          </Col>
        </Row>
      </BlockUi>
    </div>
  );
};

/** suggested by react-window to prevent unnecessary re-renders
 *  https://react-window.now.sh/#/api/FixedSizeList */
export default memo(UserProfilePartnerItem);
