const ActionKeysGA = {
  AddExistingUsers: 'AddExistingUsers',
  AddExistingFacilitators: 'AddExistingFacilitators',
  InviteNewUsers: 'InviteNewUsers',
  InviteNewFacilitators: 'InviteNewFacilitators',
  EditUsers: 'EditUsers',
  EditFacilitators: 'EditFacilitators',
  RemoveUsers: 'RemoveUsers',
  RemoveFacilitators: 'RemoveFacilitators',
  AssignToProject: 'AssignToProject',
  AssignToFacilitator: 'AssignToFacilitator',
  AssignGroupStatus: 'AssignGroupStatus',

  AddNewPermission: 'AddNewPermission',
  CreatePermission: 'CreatePermission',
  EditPermission: 'EditPermission',

  Continue: 'Continue',

  CreateAccount: 'CreateAccount',
  CreateProfile: 'CreateProfile',
  Login: 'Login',
  Logout: 'Logout',
  ForgotPassword: 'ForgotPassword',
  SetNewPassword: 'SetNewPassword',
  RequestPartnerId: 'RequestPartnerId',
  ForgotPartnerId: 'ForgotPartnerId',

  ChangeNotifications: 'ChangeNotifications',
  ChangeGlobalFilter: 'ChangeGlobalFilter',
  ChangeChartFilter: 'ChangeChartFilter',
  ChangeChartType: 'ChangeChartType',
  ChangeChartTab: 'ChangeChartTab',

  CreateJobRole: 'CreateJobRole',
  CreateProject: 'CreateProject',
  EditProject: 'EditProject',

  Read: 'Read',
  ViewProject: 'ViewProject',
  Redirect: 'Redirect',
  ResendEmail: 'ResendEmail',

  ReorderWidget: 'ReorderWidget',
  CancelWidgetSetup: 'CancelWidgetSetup',
  SaveWidgetSetup: 'SaveWidgetSetup',

  SaveColumnPreference: 'SaveColumnPreference',
  SearchList: 'SearchList',
  SortList: 'SortList',
  SelectRows: 'SelectRows',
  UnselectAllRows: 'UnselectAllRows',
  SelectAllRows: 'SelectAllRows',

  DownloadExcel: 'DownloadExcel',

  View: 'View',
  Verify: 'Verify',

  ModalView: 'ModalView',
  SendException: 'SendException',
};
export default ActionKeysGA;
