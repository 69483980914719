/* eslint-disable react/jsx-props-no-spreading */
import { useState, FC as ReactFC, useEffect } from 'react';

import queryString from 'query-string';
import * as intl from 'react-intl-universal';
import {
  Route,
  RouteChildrenProps,
  Switch,
  useHistory,
  useLocation,
  withRouter,
} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';

import AuthApiInstance from 'api/auth/AuthApi';
import ModulePaths from 'constants/ModulePaths';
import {
  defaultGlobalFilters,
  getGlobalFiltersQuery,
} from 'helpers/GlobalFilterUtils';
import { MessagingTab } from 'modules/private/messaging/containers/messaging-view/MessagingViewState';
import AuthStorageService from 'services/storage-services/AuthStorageService';
import HeaderToolbarProps from 'shared/components/header-toolbar/HeaderToolbarProps';
import ImageComponent from 'shared/components/image/ImageComponent';
import blankAvatar from 'shared/static/img/project-placeholders/blank_avatar.svg';

import {
  UserProfileViewRouteParams,
  UserProfileViewLocationState,
} from '../../modules/users/containers/user-profile-view/UserProfileViewProps';
import FilterToolbar from './filter-toolbar/FilterToolbar';
import GroupDetailsToolbar from './group-details-toolbar/GroupDetailsToolbar';
import InviteUsersToolbar from './invite-users-toolbar/InviteUsersToolbar';
import MessagingToolbar from './messaging-toolbar/MessagingToolbar';
import PartnerModal from './partner-modal/PartnerModal';
import ProjectUsersToolbar from './project-users-toolbar/ProjectUsersToolbar';
import ProjectsToolbar from './projects-toolbar/ProjectsToolbar';
import SettingsToolbar from './settings-toolbar/SettingsToolbar';
import SurveysToolbar from './surveys-toolbar/SurveysToolbar';
import UserProfileToolbar from './user-profile-toolbar/UserProfileToolbar';

const HeaderToolbar: ReactFC<HeaderToolbarProps> = (props) => {
  const { context, nationalLevelContext } = props;

  const {
    isNationalLevelUser,
    setGlobalFilters,
    userInfoData,
    userProfileSetupInProgress,
  } = context;
  const { monitoredPartners, monitoredOrganizationId, onPartnerSwitch } =
    nationalLevelContext;
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [partnerModalOpen, setPartnerModalOpen] = useState<boolean>(false);
  const history = useHistory();
  const routeLocation = useLocation();

  useEffect(() => {
    requestAnimationFrame(() => {
      ReactTooltip.rebuild();
    });
  }, [userProfileSetupInProgress]);

  /**
   * Handle click event on logout button
   */
  const handleLogoutClick = async (): Promise<void> => {
    setGlobalFilters(defaultGlobalFilters);
    try {
      await AuthApiInstance.LogoutAsync();
    } catch (error) {
      // console.log(error);
    } finally {
      AuthStorageService.Logout();
    }
  };

  /**
   * Handle click event on logout button
   */
  const handleUserProfileNavigation = (): void => {
    history.push({
      pathname: `${ModulePaths.SettingsPath}${
        ModulePaths.SettingsUsersPath
      }/${String(userInfoData.data?.userId)}`,
      state: {
        from: routeLocation.pathname + routeLocation.search,
        userId: '96672643-51f1-48ad-a1d4-060f391d1d8b',
      },
      search: getGlobalFiltersQuery(routeLocation.search),
    });
  };

  const dataTip = userProfileSetupInProgress
    ? intl.get('ERR_SAVE_CHANGES')
    : '';

  /**
   * Toggle partner modal
   */
  const togglePartnerModal = (): void => {
    setPartnerModalOpen((isOpen) => !isOpen);
  };

  /**
   * Toggle logout dropdown
   */
  const toggleLogoutMenu = (): void =>
    setDropdownOpen((prevState) => !prevState);

  const userDropDown = (
    <Dropdown isOpen={dropdownOpen} toggle={toggleLogoutMenu}>
      <DropdownToggle
        className="header-user"
        tag="button"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <ImageComponent
          className="w-100"
          ignoreBlur
          src={userInfoData.data?.imageURL}
          alt="profile"
          fallbackSrc={blankAvatar}
        />
      </DropdownToggle>
      <DropdownMenu
        className={`insight-btn-dropdown user-dropdown-menu ${
          isNationalLevelUser ? 'partner-list-visible' : ''
        }`}
      >
        <DropdownItem
          className={`profile-dropdown-btn ${
            userProfileSetupInProgress ? 'disabled' : ''
          }`}
          onClick={handleUserProfileNavigation}
          data-tip={dataTip}
          disabled={userProfileSetupInProgress}
        >
          <i className="icon-user col-2 p-0" />
          <span className="col-10 pl-2">{intl.get('BTN_MY_PROFILE')}</span>
        </DropdownItem>
        {isNationalLevelUser && (
          <DropdownItem
            className="partner-dropdown-btn"
            onClick={togglePartnerModal}
          >
            <i className="icon-partner col-2 p-0" />
            <span className="col-10 pl-2">
              {intl.get('BTN_SWITCH_PARTNER_ACCOUNTS')}
            </span>
          </DropdownItem>
        )}
        <DropdownItem
          className="logout-dropdown-btn"
          onClick={handleLogoutClick}
        >
          <i className="icon-logout col-2 p-0" />
          <span className="col-10 pl-2">{intl.get('BTN_LOGOUT')}</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );

  /**
   * Render the filter toolbar
   *
   * @param routeChildProps Route props
   * @param type Type of page
   * @param exportDisabled Whether to disable the export button
   * @returns {JSX.Element} JSX snippet containing the toolbar
   */
  const renderFilterToolbar = (
    routeChildProps: RouteChildrenProps,
    type: string,
    exportDisabled?: boolean
  ): JSX.Element => (
    <FilterToolbar
      title={intl.get(type)}
      context={context}
      userDropdown={userDropDown}
      exportDisabled={exportDisabled}
      routeProps={routeChildProps}
    />
  );

  /**
   * Render the settings toolbar
   *
   * @param routeChildProps Route props
   * @returns {JSX.Element} JSX snippet containing the toolbar
   */
  const renderSettingsToolbar = (
    childProps: RouteChildrenProps<{ settingsTab: string }>
  ): JSX.Element => {
    const path = childProps.match?.path ?? ModulePaths.SettingsPath;

    return (
      <Switch>
        <Route path={`${path}${ModulePaths.SettingsUsersInvitePath}`}>
          <InviteUsersToolbar
            context={context}
            {...childProps}
            userDropdown={userDropDown}
          />
        </Route>
        <Route exact path={`${path}${ModulePaths.SettingsUserProfilePath}`}>
          {(
            subRouteProps: RouteChildrenProps<
              UserProfileViewRouteParams,
              UserProfileViewLocationState
            >
          ): JSX.Element => (
            <UserProfileToolbar
              context={context}
              {...subRouteProps}
              userDropdown={userDropDown}
            />
          )}
        </Route>
        <Route path={`${path}/`}>
          <SettingsToolbar
            context={context}
            {...childProps}
            userDropdown={userDropDown}
          />
        </Route>
      </Switch>
    );
  };

  /**
   * Render the groups toolbar
   *
   * @param routeChildProps Route props
   * @returns {JSX.Element} JSX snippet containing the toolbar
   */
  const renderGroupsToolbar = (
    routeChildProps: RouteChildrenProps<{ groupNumber: string }>
  ): JSX.Element => {
    const path = routeChildProps.match?.path ?? ModulePaths.GroupsPath;
    return (
      <Switch>
        <Route exact path={`${path}${ModulePaths.GroupDetailsPath}`}>
          {(
            subRouteProps: RouteChildrenProps<{ groupNumber: string }>
          ): JSX.Element => (
            <GroupDetailsToolbar
              context={context}
              {...subRouteProps}
              userDropdown={userDropDown}
            />
          )}
        </Route>
        <Route path={`${path}/`}>
          {renderFilterToolbar(routeChildProps, 'LBL_TITLE_GROUPS')}
        </Route>
      </Switch>
    );
  };

  /**
   * Render the projects toolbar
   *
   * @param childProps Route props
   * @returns {JSX.Element} JSX snippet containing the toolbar
   */
  const renderProjectsToolbar = (
    childProps: RouteChildrenProps
  ): JSX.Element => {
    const path = childProps.match?.path ?? ModulePaths.ProjectsPath;
    return (
      <Switch>
        <Route
          exact
          path={[
            `${path}${ModulePaths.ProjectUsersIdPath}`,
            `${path}${ModulePaths.ProjectFacilitatorsIdPath}`,
          ]}
        >
          {(
            subRouteProps: RouteChildrenProps<{ projectId: string }>
          ): JSX.Element => (
            <ProjectUsersToolbar
              context={context}
              {...subRouteProps}
              userDropdown={userDropDown}
            />
          )}
        </Route>
        <Route path={path}>
          {(subRouteProps): JSX.Element => (
            <ProjectsToolbar
              context={context}
              {...subRouteProps}
              userDropdown={userDropDown}
            />
          )}
        </Route>
      </Switch>
    );
  };

  /**
   * Render the messaging toolbar
   *
   * @returns {JSX.Element} JSX snippet containing the toolbar
   */
  const renderMessagingToolbar = (
    childProps: RouteChildrenProps
  ): JSX.Element => {
    const { match, location } = childProps;
    const path = match?.path ?? ModulePaths.MessagingPath;
    const search = queryString.parse(location.search);
    return (
      <Switch>
        <Route exact path={`${path}`}>
          {(subRouteProps): JSX.Element => (
            <MessagingToolbar
              context={context}
              isComposeTab={
                search.tab === MessagingTab.Compose || search.tab === undefined
              }
              exportDisabled={false}
              {...subRouteProps}
              userDropdown={userDropDown}
            />
          )}
        </Route>
      </Switch>
    );
  };

  /**
   * Render the surveys toolbar
   *
   * @returns {JSX.Element} JSX snippet containing the toolbar
   */
  const renderSurveysToolbar = (
    childProps: RouteChildrenProps
  ): JSX.Element => {
    const { match } = childProps;
    const path = match?.path ?? ModulePaths.SurveysPath;
    return (
      <Switch>
        <Route exact path={`${path}`}>
          {(subRouteProps): JSX.Element => (
            <SurveysToolbar
              {...subRouteProps}
              context={context}
              userDropdown={userDropDown}
            />
          )}
        </Route>
      </Switch>
    );
  };

  return (
    <>
      <Switch>
        <Route path={ModulePaths.DashboardPath}>
          {(routeChildProps): JSX.Element =>
            renderFilterToolbar(routeChildProps, 'LBL_TITLE_DASHBOARD')
          }
        </Route>
        <Route path={ModulePaths.FinancesPath}>
          {(routeChildProps): JSX.Element =>
            renderFilterToolbar(
              routeChildProps,
              'LBL_TITLE_FINANCIAL_METRICS',
              true
            )
          }
        </Route>
        <Route path={ModulePaths.GroupsPath}>
          {(
            routeChildProps: RouteChildrenProps<{ groupNumber: string }>
          ): JSX.Element => renderGroupsToolbar(routeChildProps)}
        </Route>
        <Route path={ModulePaths.FacilitatorsPath}>
          {(routeChildProps): JSX.Element =>
            renderFilterToolbar(routeChildProps, 'LBL_TITLE_FACILITATORS')
          }
        </Route>
        <Route path={ModulePaths.ProjectsPath}>
          {(routeChildProps): JSX.Element =>
            renderProjectsToolbar(routeChildProps)
          }
        </Route>
        <Route path={ModulePaths.SettingsParamPath}>
          {(
            routeChildProps: RouteChildrenProps<{ settingsTab: string }>
          ): JSX.Element => renderSettingsToolbar(routeChildProps)}
        </Route>
        <Route path={ModulePaths.MessagingPath}>
          {(routeChildProps): JSX.Element =>
            renderMessagingToolbar(routeChildProps)
          }
        </Route>
        <Route path={ModulePaths.SurveysParamPath}>
          {(routeChildProps): JSX.Element =>
            renderSurveysToolbar(routeChildProps)
          }
        </Route>
      </Switch>
      <PartnerModal
        monitoredPartners={monitoredPartners}
        monitoredOrgId={monitoredOrganizationId}
        isOpen={partnerModalOpen}
        onToggle={togglePartnerModal}
        onPartnerSwitch={onPartnerSwitch}
      />
    </>
  );
};

export default withRouter(HeaderToolbar);
