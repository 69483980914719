import DefaultPermissionLevel from '../shared/enums/DefaultPermissionLevel';
import { UserProfileFields } from '../shared/modules/users/components/user-profile-left-panel/UserProfileLeftPanelProps';

class UserProfilePermissionHelper {
  private static editableOnNonOwnerOwnProfile = {
    [UserProfileFields.IMAGE]: true,
    [UserProfileFields.NAME]: true,
    [UserProfileFields.JOB_ROLE]: false,
    [UserProfileFields.PERMISSION_LEVEL]: false,
    [UserProfileFields.COUNTRY_DATA_ACCESS]: false,
    [UserProfileFields.EMAIL]: false,
    [UserProfileFields.PHONE_NUMBER]: true,
    [UserProfileFields.DEACTIVATE]: false,
    [UserProfileFields.ADD_TO_PROJECT]: false,
    [UserProfileFields.REMOVE_FROM_PROJECT]: false,
    [UserProfileFields.UNASSIGN_SUPERVISEE]: false,
  };

  private static editableOnOwnerOwnProfile = {
    [UserProfileFields.IMAGE]: true,
    [UserProfileFields.NAME]: true,
    [UserProfileFields.JOB_ROLE]: true,
    [UserProfileFields.PERMISSION_LEVEL]: false,
    [UserProfileFields.COUNTRY_DATA_ACCESS]: false,
    [UserProfileFields.EMAIL]: true,
    [UserProfileFields.PHONE_NUMBER]: true,
    [UserProfileFields.DEACTIVATE]: false,
    [UserProfileFields.ADD_TO_PROJECT]: true,
    [UserProfileFields.REMOVE_FROM_PROJECT]: true,
    [UserProfileFields.UNASSIGN_SUPERVISEE]: true,
  };

  /**
   * Check if user has permission to edit a user profile form field
   *
   * @param fieldName Name of the form field
   * @param canEditProfile Whether permission is granted to edit profiles
   * @param isOwnProfile Whether the profile being edited is the user's own profile
   * @param isOwner Whether the currently logged-in user is the account owner
   * @returns {boolean}
   */
  public static CanEditUserProfileField(
    fieldName: UserProfileFields,
    canEditProfile: boolean,
    isOwnProfile: boolean,
    isOwner: boolean,
    permissionLevel: string,
    canEditUserProfile: boolean,
    canDeactivateUsers: boolean
  ): boolean {
    const defaultPermissionLevels = [
      DefaultPermissionLevel.ACCOUNT_OWNER,
      DefaultPermissionLevel.COUNTRY_LEVEL,
      DefaultPermissionLevel.PROJECT_LEVEL,
      DefaultPermissionLevel.LIMITED_ACCESS,
      DefaultPermissionLevel.NATIONAL_LEVEL,
    ];
    const permissionAsType = permissionLevel as DefaultPermissionLevel;
    if (canEditProfile) {
      if (isOwnProfile) {
        if (isOwner) {
          return this.editableOnOwnerOwnProfile[fieldName];
        }
        return this.editableOnNonOwnerOwnProfile[fieldName];
      }

      if (defaultPermissionLevels.includes(permissionAsType)) {
        /* |===== DEFAULT PERMISSION LEVEL =====| */
        return true;
      }
      if (fieldName === UserProfileFields.DEACTIVATE) {
        /* |===== CUSTOM PERMISSION LEVEL =====| */
        return canDeactivateUsers;
      }
      return canEditUserProfile;
    }
    return false;
  }
}

export default UserProfilePermissionHelper;
