/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { createElement, ReactNode, useContext } from 'react';

import { Redirect, Route } from 'react-router-dom';

import AppContext from 'context/AppContext';
import { getGlobalFiltersQuery } from 'helpers/GlobalFilterUtils';
import PermissionUtil from 'helpers/PermissionUtil';

import ProtectedRouteProps from './ProtectedRouteProps';

const ProtectedRoute = (props: ProtectedRouteProps): JSX.Element => {
  const {
    component,
    children,
    render,
    resource,
    redirectTo,
    explicitProtect,
    ...rest
  } = props;
  const { permissionsData } = useContext(AppContext);

  /**
   * Render the children component
   *
   * @param childProps Route props
   * @returns {ReactNode} JSX snippet containing the child components
   */
  const renderChildren = (childProps): ReactNode =>
    component
      ? createElement(component, childProps)
      : children && typeof children === 'function'
      ? children(childProps)
      : render
      ? render(childProps)
      : null;

  let can = false;
  if (!explicitProtect) {
    if (resource === undefined) {
      can = true;
    } else if (resource instanceof Array) {
      // If there are multiple resources, at least one of the permissions must be granted to proceed
      can = resource
        .map((permission) =>
          PermissionUtil.Can(permissionsData.claims, permission)
        )
        .includes(true);
    } else {
      can = PermissionUtil.Can(permissionsData.claims, resource);
    }
  }

  return (
    <Route {...rest}>
      {(routeProps): ReactNode =>
        can ? (
          renderChildren(routeProps)
        ) : (
          <Redirect
            to={{
              pathname: redirectTo ?? '/',
              state: { from: routeProps.location },
              search: getGlobalFiltersQuery(routeProps.location.search),
            }}
          />
        )
      }
    </Route>
  );
};

export default ProtectedRoute;
