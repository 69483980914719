import { useEffect, useState, FC as ReactFC, useContext } from 'react';

import isEmpty from 'lodash/isEmpty';
import * as intl from 'react-intl-universal';
import { useLocation } from 'react-router-dom';

import AuthApiInstance from 'api/auth/AuthApi';
import GlobalFilters from 'constants/GlobalFilters';
import ModulePaths from 'constants/ModulePaths';
import StorageKeys from 'constants/StorageKeys';
import AppContext from 'context/AppContext';
import { getGlobalFiltersSearch } from 'helpers/GlobalFilterUtils';
import history from 'router-history';
import AuthStorageService from 'services/storage-services/AuthStorageService';
import EventKey from 'shared/enums/EventKey';
import Status from 'shared/enums/Status';
import { EventBus } from 'shared/events/EventBus';

import NationalLevelContext from './NationalLevelContext';
import { MonitoredPartners } from './NationalLevelProviderState';

const NationalLevelProvider: ReactFC = (props) => {
  const { children } = props;

  const {
    isNationalLevelUser,
    prevSelectedOrganization,
    setErrorToastText,
    setSuccessToastHeader,
    setSuccessToastText,
    onLogin,
  } = useContext(AppContext);

  const location = useLocation();

  // prettier-ignore
  const [monitoredPartners, setMonitoredPartners] = useState<MonitoredPartners>({ status: Status.Loading, data: [] });
  // prettier-ignore
  const [monitoredOrganizationId, setMonitoredOrganizationId] = useState<string>('');

  useEffect(() => {
    if (isNationalLevelUser) {
      /* Fetch monitored partners if the logged-in user is a national user */
      getMonitoredPartners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNationalLevelUser]);

  useEffect(() => {
    const persistedId = AuthStorageService.GetItem<string>(
      StorageKeys.MonitoredOrganizatonId
    );
    if (persistedId) {
      /* SCENARIO 01 - Manual browser refresh */
      if (
        prevSelectedOrganization &&
        persistedId !== prevSelectedOrganization
      ) {
        /*
        1. Persisted ID is different to the previously selected organization ID
        2. Org info will be refreshed (currency validation executes) - as currency session storage is outdated
       */
        onPartnerSwitch(prevSelectedOrganization, true);
      } else {
        /*
        1. Persisted ID is equal to the previously selected organization ID
        2. Org info will NOT be refreshed (currency validation does NOT execute) - as currency session storage is valid
       */
        onPartnerSwitch(persistedId, false, false);
      }
    } else if (!isEmpty(monitoredPartners.data)) {
      /* SCENARIO 02 - Freshly-logged in user */
      if (
        prevSelectedOrganization &&
        !isEmpty(
          monitoredPartners.data.filter(
            (partner) => partner.id === prevSelectedOrganization
          )
        )
      ) {
        /*
        1. Previously selected organization is set as the currently selected organization
        2. Org info will be refreshed (currency validation executes) - as currency session storage is empty
       */
        onPartnerSwitch(prevSelectedOrganization, false, false);
      } else {
        /*
        1. Previously selected organization is null -> select first organization from list
        2. Org info will be refreshed (currency validation executes) - as currency session storage is empty
       */
        onPartnerSwitch(monitoredPartners.data[0].id, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitoredPartners]);

  /**
   * Fetch national-level user's monitored partners
   */
  const getMonitoredPartners = async (): Promise<void> => {
    try {
      setMonitoredPartners((state) => ({ ...state, status: Status.Loading }));
      const response = await AuthApiInstance.GetMonitoredPartners();
      setMonitoredPartners({
        status: Status.Success,
        data: response.items,
      });
    } catch (error) {
      setErrorToastText(intl.get('ERR_NATIONAL_PARTNER_FETCH_FAILURE'));
      setMonitoredPartners({
        status: Status.Error,
        data: [],
      });
    }
  };

  /**
   * Handle partner switch event on partner dropdown
   */
  const onPartnerSwitch = (
    orgId: string,
    resetPath = true,
    refreshOrgInfo = true
  ): void => {
    AuthApiInstance.SetMonitoredPartner(orgId)
      .then(() => {
        setMonitoredOrganizationId(orgId);
        AuthStorageService.SetItem<string>(
          StorageKeys.MonitoredOrganizatonId,
          orgId
        );
        if (resetPath) {
          history.replace({
            pathname: ModulePaths.DashboardPath,
            search: getGlobalFiltersSearch(
              GlobalFilters.DefaultGlobalFilters,
              location
            ),
          });
        }
        if (refreshOrgInfo) {
          onLogin();
        } else {
          EventBus.getInstance().dispatch(EventKey.RemountComponentTree);
        }
        displayStatusToast(orgId);
      })
      .catch((error) => {
        setErrorToastText(intl.get('ERR_NATIONAL_PARTNER_SET_FAILURE'));
      });
  };

  /**
   * Displays a toast when partners are switched
   *
   * @param orgId Selected Organization ID
   */
  const displayStatusToast = (orgId: string): void => {
    const switchedPartner = monitoredPartners.data.filter(
      (partner) => partner.id === orgId
    );
    if (!isEmpty(switchedPartner)) {
      setSuccessToastHeader(
        intl.get('LBL_SWITCH_PARTNERS_LOADING_TOAST_HEADER')
      );
      setSuccessToastText(
        intl.get('LBL_SWITCH_PARTNERS_LOADING_TOAST_BODY', {
          partnerName: switchedPartner[0].name,
        })
      );
    }
  };

  return (
    <NationalLevelContext.Provider
      value={{
        monitoredPartners,
        monitoredOrganizationId,
        onPartnerSwitch,
      }}
    >
      {children}
    </NationalLevelContext.Provider>
  );
};

export default NationalLevelProvider;
