import BackupStatus from 'shared/enums/BackupStatus';
import GroupAge from 'shared/enums/GroupAge';

const GlobalFilters = {
  MaxListSelectable: 10,
  InvalidDate: 'Invalid date',
  DefaultGlobalFilters: {
    groupAge: GroupAge.None,
    backupStatus: BackupStatus.None,
    projects: [],
    groupStatuses: [],
    fromDate: '',
    toDate: '',
  },
};

export default GlobalFilters;
